import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../base/auth.service';
import { Constants } from '../constants';
import { environment } from '../../environments/environment';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate = async () => {
    try {
      const isAdmin =
        (await this.authService.userHasRole(Constants.UserRoles.admin)) &&
        environment.features.ADMIN_PANEL;

      if (!isAdmin) {
        this.router.navigate([Constants.Routes.Login]);
      }

      return isAdmin;
    } catch (e) {
      return false;
    }
  };
}
