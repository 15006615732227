import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  Inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  IEmployeeCv,
  ICvWorkExperienceItem,
  ICvWorkExperienceRole,
  IUpdateModel,
} from '@lss/lss-types';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { Constants } from '../../constants';
import { DataService } from '../../base/data.service';
import { Observable } from 'rxjs';
import { AnimationBuilder } from '@angular/animations';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import {
  BaseListCrudComponent,
  ConfirmationService,
  creatingEditableItemDecorator,
  getPeriodFormGroup,
  ListCrudUtilService,
  numberHasValue,
} from '@lss/lss-ui';
import { GlobalService } from '../../global.service';
import { AuthService } from '../../base/auth.service';
import { trimRichEditorValue } from '../../utils';

@Component({
  selector: 'lss-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkComponent extends BaseListCrudComponent<
  IEmployeeCv,
  ICvWorkExperienceItem
> {
  newItem = newExperienceItemControl;
  form: FormArray;
  pending = false;
  hasPermament: boolean;
  candidate = Constants.UserRoles.candidate;
  focusedIndex = undefined;
  state = 'inactive';

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    protected activatedRoute: ActivatedRoute,
    protected confirmationService: ConfirmationService,
    protected cd: ChangeDetectorRef,
    protected animationBuilder: AnimationBuilder,
    protected elementRef: ElementRef,
    protected crudUtilService: ListCrudUtilService,
    protected globalService: GlobalService,
    protected authService: AuthService,
  ) {
    super(
      dataService,
      activatedRoute,
      confirmationService,
      cd,
      animationBuilder,
      crudUtilService,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onDataReady = function (cv: IEmployeeCv): any {
    const experience = cv?.workExperience || [];
    this.form = new FormArray(
      experience.map((e) => newExperienceItemControl(e, this.dataService)),
    );
    if (this.form.controls.length === 0) {
      this.form.push(newExperienceItemControl());
    }

    this.hasPermament = cv?.workExperience.some((exp) => {
      return exp.locked;
    });

    if (
      !this.pending &&
      this.activatedRoute.snapshot.queryParams['highlight-missing-inputs']
    ) {
      this.pending = true;
      window.setTimeout(async () => {
        this.globalService.checkAndFocusInvalidFields(this.form);
        this.pending = false;
      }, 500);
    }
  }.bind(this);

  getRemoveConfirmation(index: number): () => Observable<boolean> {
    return () => {
      const companyName = this.form
        .at(index)
        .get(Constants.WorkExperience.Fields.company)?.value as string;

      return this.modalRemoveConfirmationFactory(
        Constants.WorkExperience.ConfirmRemoveExperienceHeader,
        Constants.WorkExperience.GetConfirmRemoveExperienceBody(companyName),
      );
    };
  }

  getUpdateModel(): IUpdateModel<IEmployeeCv> {
    return getWorkExperienceUpdateModel(this.form);
  }

  async addCompanyToStart(): Promise<void> {
    let addIndex = (await this.authService.userHasRole(
      Constants.UserRoles.candidate,
    ))
      ? 0
      : 1;
    this.form.insert(addIndex, newExperienceItemControl());
    this.focusedIndex = 1;
  }

  canMoveUp(first, index): boolean {
    return !first && index >= (this.hasPermament ? 2 : 1);
  }

  canMoveDown(last, index): boolean {
    return !last && index >= (this.hasPermament ? 1 : 0);
  }

  submitToHR(): void {
    this.submit(true).subscribe(() => this.dataService.submitToHR());
  }
}

export const getWorkExperienceUpdateModel = (
  form: FormArray,
): IUpdateModel<IEmployeeCv> => {
  const items = form.getRawValue().map((e) => {
    const { company, location, roles, period } = e;
    const itemRoles = roles.map((ee) => {
      // tslint:disable-next-line:no-shadowed-variable
      const { period, title, description } = ee;
      const descriptionTrimmed = description
        ? trimRichEditorValue(description)
        : null;

      return {
        [Constants.WorkExperience.Fields.title]: title,
        description: descriptionTrimmed,
        ...period,
      };
    });

    return {
      [Constants.WorkExperience.Fields.company]: company,
      location,
      roles: itemRoles,
      ...period,
    } as ICvWorkExperienceItem;
  });

  return {
    cv: {
      workExperience: items,
    },
  };
};

export function newExperienceItemControl(
  model: ICvWorkExperienceItem = null,
  data: DataService = null,
): FormGroup {
  const { company, location, roles, startDate, endTimestamp, present, locked } =
    model ||
    ({
      roles: [{}] as ICvWorkExperienceRole[],
    } as ICvWorkExperienceItem);

  // TODO fix from the backend side later, need to fix 'present' value for ex-employees and set it to false, also check locked

  let companyLocked = false;
  const is28stone =
    company === Constants.CompanyInfo.ShortName ||
    company === Constants.CompanyInfo.Name;

  if (data && !data.isCandidate) {
    companyLocked = is28stone && !numberHasValue(endTimestamp);
  }

  const formGroup = new FormGroup({
    company: new FormControl({ value: company, disabled: companyLocked }, [
      Validators.required,
    ]),
    location: new FormControl({ value: location, disabled: companyLocked }),
    period: getPeriodFormGroup(
      startDate,
      endTimestamp,
      present,
      companyLocked,
      [Validators.required],
    ),
    roles: new FormArray(
      (roles || ([{}] as ICvWorkExperienceRole[])).map(
        newExperienceRolesControl,
      ),
    ),
  });

  creatingEditableItemDecorator(formGroup, model);
  return formGroup;
}

export function newExperienceRolesControl(
  model: ICvWorkExperienceRole = null,
): FormGroup {
  const { title, description, startDate, endTimestamp, present } =
    model || ({} as ICvWorkExperienceRole);
  const endDate = !endTimestamp && present ? 0 : endTimestamp;
  const formGroup = new FormGroup({
    title: new FormControl(title, [Validators.required]),
    description: new FormControl(description),
    period: getPeriodFormGroup(startDate, endDate, present, false, [
      Validators.required,
    ]),
  });

  creatingEditableItemDecorator(formGroup, model);

  return formGroup;
}
