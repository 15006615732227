import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './base/login/login.guard';
import { SearchGuardComponent } from './search/search-guard.component';
import { ErrorGuard } from './error/error.guard';
import { Constants } from './constants';
import { LoginComponent } from './base/login/login.component';
import { LoginModule } from './base/login/login.module';
import { PageNotFoundComponent } from './base/page-not-found/not-found.component';
import { PageNotFoundModule } from './base/page-not-found/not-found.module';
import { AdminGuard } from './admin/admin.guard';
import { SetPasswordComponent } from './set-password/set-password.component';
import { ExternalHRGuard } from './guards/external-hr-guard';
import { RegisterCandidateGuard } from './register-candidate/register-candidate-guard';
import { TermsOfServiceComponent } from './company-policies/terms-of-service/terms-of-service.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeGuard } from './guards/home-guard';
import { TipsAndTricksComponent } from './base/tips-and-tricks/tips-and-tricks/tips-and-tricks.component';
import { PrivacyPolicyComponent } from './company-policies/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: Constants.Routes.Preview,
    loadChildren: () =>
      import('./preview/preview.module').then((m) => m.PreviewModule),
    data: {
      nextRoute: {
        path: [Constants.Routes.Cv],
        label: 'CV',
      },
      allowCvGenerate: true,
    },
    canActivate: [LoginGuard],
  },
  {
    path: Constants.Routes.CandidateCv,
    loadChildren: () =>
      import('./preview/preview.module').then((m) => m.PreviewModule),
    data: {
      nextRoute: {
        path: [Constants.Routes.Cv],
        label: 'CV',
      },
      allowCvGenerate: true,
    },
    canActivate: [LoginGuard],
  },
  {
    path: Constants.Routes.Search,
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
    data: {
      nextRoute: {
        path: [Constants.Routes.Cv],
        label: 'CV',
      },
      data: {
        title: 'Search',
        subtitle:
          'Clicking the link will download the resume of the matching individual.',
      },
    },
    canActivate: [LoginGuard, SearchGuardComponent],
  },
  {
    path: Constants.Routes.RegisterCandidate,
    loadChildren: () =>
      import('./register-candidate/register-candidate.module').then(
        (m) => m.RegisterCandidateModule,
      ),
    data: {
      data: {
        title: 'Register a new candidate in SkillSet',
      },
      registerRoute: true,
      url: Constants.Routes.RegisterCandidate,
    },
    canActivate: [LoginGuard, RegisterCandidateGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: Constants.Routes.RegisterExternalHr,
    loadChildren: () =>
      import('./register-candidate/register-candidate.module').then(
        (m) => m.RegisterCandidateModule,
      ),
    data: {
      data: {
        title: 'Register an External HR in SkillSet',
      },
      registerRoute: true,
      url: Constants.Routes.RegisterExternalHr,
    },
    canActivate: [LoginGuard, RegisterCandidateGuard],
  },
  {
    path: Constants.Routes.Home,
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: {
      data: {
        title: 'Home page',
      },
    },
    canActivate: [LoginGuard, HomeGuard],
  },
  {
    path: Constants.Routes.ForgotPassword,
    component: ForgotPasswordComponent,
  },
  {
    path: Constants.Routes.SetPasswordCandidate,
    component: SetPasswordComponent,
  },
  {
    path: Constants.Routes.TermsOfService,
    component: TermsOfServiceComponent,
  },
  {
    path: Constants.Routes.PrivacyPolicy,
    component: PrivacyPolicyComponent,
  },
  {
    path: Constants.Routes.TipsAndTricks,
    component: TipsAndTricksComponent,
  },
  {
    path: Constants.Routes.Cv,
    loadChildren: () => import('./cv/cv.module').then((m) => m.CvModule),
    data: {
      nextRoute: { path: [Constants.Routes.Search], label: 'Search' },
      allowCvGenerate: true,
    },
    canActivate: [LoginGuard, ExternalHRGuard],
  },
  {
    path: Constants.Routes.Admin,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    data: {
      nextRoute: { path: [Constants.Routes.Search], label: 'Search' },
      allowCvGenerate: true,
    },
    canActivate: [LoginGuard, AdminGuard],
  },
  {
    path: Constants.Routes.Error,
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
    canActivate: [ErrorGuard],
  },
  {
    path: Constants.Routes.Login,
    component: LoginComponent,
  },
  {
    path: '',
    redirectTo: Constants.Routes.Login,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: Constants.Routes.NotFound,
    pathMatch: 'full',
  },
  {
    path: Constants.Routes.NotFound,
    component: PageNotFoundComponent,
    canActivate: [LoginGuard],
  },
  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 65],
    }),
    LoginModule,
    PageNotFoundModule,
  ],
  exports: [RouterModule],
  providers: [
    LoginGuard,
    SearchGuardComponent,
    AdminGuard,
    ErrorGuard,
    RegisterCandidateGuard,
    ExternalHRGuard,
    HomeGuard,
  ],
})
export class AppRoutingModule {}
