<div
  style="padding: 10px 0"
  (mouseleave)="leaveDialog()"
  (mouseenter)="openDialog()"
>
  <p style="margin: 0">
    Learn more here:
    <a
      style="color: #de582a; text-decoration: none"
      href="https://en.wikipedia.org/wiki/ILR_scale#ILR_scale"
      target="_blank"
    >
      Wikipedia ILR scale
    </a>
  </p>
</div>
