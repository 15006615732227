import { Pipe, PipeTransform } from '@angular/core';
import { AvailableValidators, ValidationRule } from '@lss/lss-types';

@Pipe({
  name: 'validatorValue',
})
export class ValidatorValuePipe implements PipeTransform {
  transform(
    validationRules: ValidationRule[],
    validatorName: keyof AvailableValidators
  ): any {
    try {
      return validationRules?.find((rule) => rule[validatorName])[
        validatorName
      ];
    } catch (err) {
      return undefined;
    }
  }
}
