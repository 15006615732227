import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ISimpleConfirmData, NavigationCommand } from '@lss/lss-types';
import { first, map } from 'rxjs/operators';
import { ComponentType } from '@angular/cdk/portal';
import { BaseConfirmComponent } from './base-confirm.component';
import { Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../../../../lss/src/app/base/auth.service';
import { Constants } from 'src/lss/src/app/constants';
import { SimpleConfirmComponent } from './simple-confirm/simple-confirm.component';
import { isLoggingOut } from '@lss/lss-ui';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(
    private modal: MatDialog,
    private router: Router,
    public authService: AuthService,
  ) {}

  static simpleConfirmationDataFactory(
    header: string,
    body?: string | BehaviorSubject<string>,
    confirmButtonText?: string,
    declineButtonText?: string,
  ): ISimpleConfirmData {
    return {
      header,
      body,
      confirmButtonText,
      declineButtonText,
      resultFilter: (e: boolean) => e === true,
    };
  }

  public confirm$<TData extends ISimpleConfirmData, TResult>(
    component: ComponentType<BaseConfirmComponent>,
    data: TData,
    width = 'auto',
    id?: string,
  ): Observable<TResult> {
    return this.modal
      .open(component, {
        disableClose: true,
        data,
        width,
        id,
      })
      .afterClosed()
      .pipe(first());
  }

  public handleNavCommand(res, cb?): Observable<any> {
    switch (res) {
      case NavigationCommand.StopNavigation:
        return of(false);
      case NavigationCommand.ResetAndNavigate:
        return of(true);
      case NavigationCommand.SaveAndNavigate:
        return cb().pipe(map(() => true));
    }
  }

  showNavigationConfirmModalForTable(
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> {
    let data = Constants.Routes.ConfirmNavigationTable;

    if (
      nextState &&
      isLoggingOut(nextState) &&
      this.authService.isBasicLogout.getValue()
    ) {
      data = Constants.Routes.ConfirmLogoutTable;
    }

    // Reversing buttons because by Figma design "Stay on page"
    // button should be primary but "Stay on page" is considered as decline.
    // NOTE: Assigning them to separate variables because otherwise we would
    // mutate existing data object and we want to avoid it. Due the structure
    // of that data object we can't use `structuredClone` so updating values like this
    const tmp = data.confirmButtonText;
    const confirmButtonText = data.declineButtonText;
    const declineButtonText = tmp;

    return (
      this.confirm$(
        SimpleConfirmComponent,
        { ...data, confirmButtonText, declineButtonText },
        '535px',
      )
        // Since we swapped our buttons we need to respect the actions so
        // therefore reversing result as well
        .pipe(map((result) => !result))
    );
  }

  closeDialogById(id: string) {
    this.modal.getDialogById(id)?.close(undefined);
  }
}
