import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';
import { Observable, of, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  version$ = combineLatest([
    this.getClientVersion$(),
    this.getApiVersion$(),
  ]).pipe(
    map(([clientVersion, apiVersion]: [string, string]) => {
      return {
        clientVersion,
        apiVersion,
      };
    })
  );

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    private httpClient: HttpClient
  ) {}

  getClientVersion$(): Observable<string> {
    return environment.production
      ? this.httpClient.get('./version.json', { responseType: 'text' }).pipe(
          map((e) => e?.trim()),
          first()
        )
      : of('local');
  }

  getApiVersion$(): Observable<string> {
    return this.dataService.getVersion();
  }
}
