import {
  Component,
  Input,
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ProgressService } from '../progress.service';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { first, takeUntil, tap, debounceTime } from 'rxjs/operators';
import * as deepEqual from 'deep-equal';
import { UiConstants } from 'src/lss-ui/src/lib/ui-constants';
import { IEmployeeCv } from '@lss/lss-types';
import { Constants } from 'src/lss/src/app/constants';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { FormcardProficiencyModal } from './formcard.proficiency.modal';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'lss-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
})
export class FormCardComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() desiredSkillChanged?: boolean;
  @Input() formSubmit: () => Observable<any>;
  @Input() _form: FormGroup | FormArray;
  @Input()
  set form(value: FormGroup | FormArray) {
    this.onCreateGroupFormValueChange(value);
    this._form = value;
  }
  get form(): FormGroup | FormArray {
    return this._form;
  }

  @Input() _additionalInfo: FormGroup;
  @Input()
  set additionalInfo(value: FormGroup) {
    this._additionalInfo = value;
  }
  get additionalInfo(): FormGroup {
    return this._additionalInfo;
  }
  @Input() nesting?: string = '';
  @Input() submitText?: string;
  @Input() isCandidate = false;
  @Input() cvSaved: boolean;
  @Input() cv: IEmployeeCv;

  @Output() submitToHR = new EventEmitter<void>();
  @Output() formHasChanged = new EventEmitter<boolean>();

  destroyed$ = new Subject();
  loading$: Observable<boolean>;
  nextRoute: string;
  componentRoute: string;
  constantRoute: string;
  storedSubmitDisabled: boolean;

  constructor(
    progressService: ProgressService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    this.loading$ = progressService.loading$;
    this.nextRoute = this.activatedRoute.snapshot.data?.nextRoute;
  }

  private formFieldsEqual(current, original): boolean {
    let currentValue = current;
    if (typeof current !== 'string') {
      currentValue = Object.assign({}, current);
      delete currentValue.animationState;
    }

    return deepEqual(currentValue, original);
  }

  onCreateGroupFormValueChange(form) {
    const initialValue = form.value;

    form.valueChanges.pipe(debounceTime(1)).subscribe(() => {
      const filteredKeys = Object.keys(initialValue)
        .concat(Object.keys(form.value))
        .filter((value, index, self) => self.indexOf(value) === index);
      const hasChange = filteredKeys.some(
        (key) => !this.formFieldsEqual(form.value[key], initialValue[key]),
      );

      this.formHasChanged.emit(hasChange);

      if (!hasChange && !this.desiredSkillChanged) {
        form.markAsPristine();
        this.cd.markForCheck();
      }
    });
  }

  ngOnInit(): void {
    this.submitText = this.submitText ?? 'Save';
    this.componentRoute = this.router.routerState.snapshot.url;
    this.constantRoute = '/' + Constants.Routes.CvTechnicalSkills;
    this.storedSubmitDisabled = JSON.parse(
      localStorage.getItem(Constants.StorageKeys.isSubmitDisabled),
    );
  }

  dialogRef: MatDialogRef<FormcardProficiencyModal, any>;

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  submit(): void {
    this.formSubmit();
    localStorage.removeItem(Constants.StorageKeys.isSubmitDisabled);
    this.storedSubmitDisabled = null;
  }

  openDialog(): void {
    const dialogWindowMarginFromIcon = 65;
    const dialogWidth = 260;
    const iconElement = document.getElementById('technicalInfo');
    const iconWidth = iconElement.offsetWidth;
    const dialogWindowMarginTop =
      iconElement.getBoundingClientRect().top -
      dialogWindowMarginFromIcon +
      'px';
    const dialogWindowMarginLeft =
      iconElement.getBoundingClientRect().left -
      dialogWidth / 20 +
      iconWidth / 10 +
      'px';

    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(FormcardProficiencyModal, {
        width: dialogWidth + 'px',
        position: {
          top: dialogWindowMarginTop,
          left: dialogWindowMarginLeft,
        },
        hasBackdrop: false,
        backdropClass: 'openedDialog',
        autoFocus: false,
        restoreFocus: false,
        scrollStrategy: new NoopScrollStrategy(),
      });
    }

    this.dialogRef.disableClose = true;
  }

  leaveDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.disableClose = false;
      const interval = setInterval(() => {
        if (!this.dialogRef) {
          clearInterval(interval);
        } else if (!this.dialogRef.disableClose) {
          clearInterval(interval);
          this.dialogRef.close();
          this.dialogRef = undefined;
        }
      }, 1000);
    }
  }

  submitFormToHR(): void {
    this.submitToHR.emit();
    localStorage.removeItem(Constants.StorageKeys.isSubmitDisabled);
    this.storedSubmitDisabled = null;
  }

  submitAndContinue(): void {
    this.formSubmit()
      .pipe(
        first(),
        tap(() => {
          this.ngZone.run(() => {
            this.router.navigate([this.nextRoute], {
              relativeTo: this.activatedRoute.parent.parent,
            });
          });
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  isButtonDisabled(): boolean {
    return this.form?.pristine || this.form?.invalid;
  }

  isSubmitButtonDisabled(): boolean {
    let isDisabled =
      (this.form?.pristine && !this.cvSaved) ||
      (this.form?.dirty && this.form?.invalid);

    if (this.storedSubmitDisabled !== null && !this.form?.dirty) {
      isDisabled = this.storedSubmitDisabled;
    }

    localStorage.setItem(
      Constants.StorageKeys.isSubmitDisabled,
      JSON.stringify(isDisabled),
    );

    return (
      isDisabled ||
      (this.title === UiConstants.TechnicalSkills.Title
        ? this.form?.getRawValue().length === 0
        : this.cv.technicalSkills.length === 0)
    );
  }
}
