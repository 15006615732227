import { BehaviorSubject } from 'rxjs';
import { IErrorPageModel } from '@lss/lss-types';
import { InjectionToken } from '@angular/core';

export const ERROR_SERVICE_TOKEN = new InjectionToken<string>('DataService');

export interface IErrorService {
  error$: BehaviorSubject<IErrorPageModel>;

  navigateToErrorPage(error: IErrorPageModel): void;
}
