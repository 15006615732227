import { NgModule } from '@angular/core';
import { DatePickerComponent } from './date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { DatePickerDateAdapter } from './date-picker.adapter';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    CommonModule,
  ],
  exports: [DatePickerComponent],
  providers: [{ provide: DateAdapter, useClass: DatePickerDateAdapter }],
})
export class DatePickerModule {}
