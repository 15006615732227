import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { RegisterCandidateComponent } from './register-candidate.component';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfirmationService, isLoggingOut } from '@lss/lss-ui';
import { Constants } from '../constants';
import { StayConfirmComponent } from 'src/lss-ui/src/lib/form/confirmation/stay-confirm/stay-confirm.component';
@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuardService
  implements CanDeactivate<RegisterCandidateComponent>
{
  constructor(protected confirmationService: ConfirmationService) {}

  canDeactivate(
    component: RegisterCandidateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (component.form.dirty && !isLoggingOut(nextState)) {
      return this.confirmationService.confirm$(
        StayConfirmComponent,
        Constants.Routes
          .ConfirmNavigationIncompleteDataRegisterNewCandidateOrHR,
        '535px',
      );
    }

    if (component.form.dirty && isLoggingOut(nextState)) {
      return this.confirmationService.confirm$(
        StayConfirmComponent,
        Constants.Routes.ConfirmLogoutRegisterCandidateOrHR,
        '535px',
      );
    } else {
      return of(true);
    }
  }
}
