<div *ngIf="currentStatus === ImportStatus.default">
  <div class="input-block">
    <h1>Import your LinkedIn data?</h1>
    <lss-text
      label="Your LinkedIn account link"
      [formControl]="linkedInUrl"
      placeholder="https://www.linkedin.com/in/<your_profile_name>/"
    ></lss-text>
  </div>

  <div class="info-text">
    <p>Only following sections will be imported from your LinkedIn account:</p>
    <ul>
      <li>Summary of your qualifications and experience</li>
      <li>Work Experience</li>
      <li>Education</li>
      <li>Certificates</li>
    </ul>
    <div class="warning-message">
      <lss-icon mat-icon>warning</lss-icon>
      <span>Data provided previously will be overwritten</span>
    </div>
  </div>
</div>

<div *ngIf="currentStatus === ImportStatus.uploading" class="spinner-container">
  <mat-spinner></mat-spinner>
  <p>Please wait, it might take a few seconds...</p>
</div>

<div
  *ngIf="currentStatus === ImportStatus.importSuccessful"
  class="spinner-container"
>
  <lss-icon mat-icon class="success" *ngIf="missingDataPageText === null"
    >done</lss-icon
  >
  <img
    src="assets/svg/success-with-warning.svg"
    *ngIf="missingDataPageText !== null"
  />
  <p>Data imported successfully!</p>
  <p class="missing-data-text" *ngIf="missingDataPageText !== null">
    {{ missingDataPageText }}
  </p>
</div>

<div
  *ngIf="currentStatus === ImportStatus.importFailed"
  class="spinner-container"
>
  <lss-icon mat-icon class="failed">close</lss-icon>
  <p>Unfortunately we couldn't import your data. Please, try again.</p>
</div>

<div
  *ngIf="currentStatus === ImportStatus.noInfoFound"
  class="spinner-container"
>
  <lss-icon mat-icon class="failed">close</lss-icon>
  <p>We couldn’t find any data to import for a specified LinkedIn account</p>
</div>

<div
  *ngIf="currentStatus === ImportStatus.noAccountFound"
  class="spinner-container"
>
  <lss-icon mat-icon class="failed">close</lss-icon>
  <p>Specified LinkedIn account doesn’t exist</p>
</div>

<div class="modal-buttons">
  <lss-button
    [color]="'secondary'"
    id="cancelBtn"
    (click)="handleDialogCancelAction()"
  >
    <span>{{ cancelButtonText }}</span>
  </lss-button>
  <lss-button
    *ngIf="currentStatus === ImportStatus.default"
    id="importBtn"
    (action)="handleDialogImportAction()"
    [disabled]="!isFormValid()"
  >
    <span>Import</span>
  </lss-button>
  <lss-button
    *ngIf="currentStatus === ImportStatus.importFailed"
    id="retryBtn"
    (click)="handleDialogImportAction()"
  >
    <span>Retry</span>
  </lss-button>
</div>
