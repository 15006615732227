import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedActionsComponent } from './logged-actions.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {
  ButtonModule,
  ButtonToggleModule,
  IconModule,
  TextModule,
} from '@lss/lss-ui';
import { SearchboxComponent } from '../searchbox/searchbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [LoggedActionsComponent, SearchboxComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    ButtonModule,
    ButtonToggleModule,
    IconModule,
    TextModule,
    MatIconModule
  ],
  exports: [LoggedActionsComponent, SearchboxComponent],
})
export class LoggedActionsModule {}
