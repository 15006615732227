import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  IEmployeeCv,
  IUpdateModel,
  ILookupData,
  ILookupValue,
  ILanguageProficiency,
} from '@lss/lss-types';
import {
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TextComponent } from '../../../../../lss-ui/src/lib/form/text/text.component';
import { DataService } from '../../base/data.service';
import { takeUntil, map, startWith, shareReplay } from 'rxjs/operators';
import { LanguageProficiencyModal } from './language.proficiency.modal';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AnimationBuilder } from '@angular/animations';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import {
  BaseListCrudComponent,
  ConfirmationService,
  creatingEditableItemDecorator,
  ListCrudUtilService,
} from '@lss/lss-ui';
import { GlobalService } from '../../global.service';
import { AuthService } from '../../base/auth.service';
import { Constants } from '../../constants';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'lss-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagesComponent
  extends BaseListCrudComponent<IEmployeeCv, ILanguageProficiency>
  implements AfterViewInit
{
  @Input() focusElement = false;
  @ViewChild('focusField') focusField: TextComponent;

  candidate = Constants.UserRoles.candidate;
  form: FormArray;
  languageProficiencyOptions: ILookupValue[];
  languageOptions: ILookupValue[];
  dialogRef: MatDialogRef<LanguageProficiencyModal, any>;
  pending = false;
  focusedIndex = undefined;
  alreadySelectedLanguages$: Observable<any>;
  newItem = () => newLanguageItemControl(this.languageOptions);

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    protected activatedRoute: ActivatedRoute,
    protected confirmationService: ConfirmationService,
    public dialog: MatDialog,
    protected cd: ChangeDetectorRef,
    protected animationBuilder: AnimationBuilder,
    protected crudUtilService: ListCrudUtilService,
    protected globalService: GlobalService,
    protected authService: AuthService,
  ) {
    super(
      dataService,
      activatedRoute,
      confirmationService,
      cd,
      animationBuilder,
      crudUtilService,
    );
  }

  ngAfterViewInit(): void {
    if (this.focusElement && this.focusField) {
      this.focusField.focusInput();
    }
  }

  onDataReady = function (cv: IEmployeeCv): any {
    const languageProficiencies = cv?.languageProficiencies || [];
    const lookupData = this.dataService.lookupData$.getValue() as ILookupData;

    this.languageOptions = this.dataService.getLanguageOptions();
    this.form = new FormArray(
      languageProficiencies.map((e) =>
        newLanguageItemControl(this.languageOptions, e, lookupData),
      ),
      [this.sameLanguageValidator()],
    );
    this.languageProficiencyOptions = lookupData.languageProficiencies;

    this.alreadySelectedLanguages$ = this.form?.valueChanges.pipe(
      startWith(this.form.getRawValue()),
      map((e) =>
        Object.values(e)
          .map((ee) => ee.language)
          .filter((ee) => !!ee),
      ),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
      takeUntil(this.destroyed$),
    );

    if (
      !this.pending &&
      this.activatedRoute.snapshot.queryParams['highlight-missing-inputs']
    ) {
      this.pending = true;
      window.setTimeout(async () => {
        this.globalService.checkAndFocusInvalidFields(this.form);
        this.pending = false;
      }, 500);
    }
  }.bind(this);

  getUpdateModel(): IUpdateModel<IEmployeeCv> {
    return getLanguagesUpdateModel(this.form, this.languageOptions);
  }

  openDialog(): void {
    const dialogWindowMarginFromIcon = 56;
    const dialogWidth = 260;
    const headerHeight = 64;
    const iconElement = document.getElementById('languageInfo');
    const iconWidth = iconElement.offsetWidth;
    const boundingRect = iconElement.getBoundingClientRect();
    const moveToBottomOfElement = boundingRect.top * 0.5 <= headerHeight;
    const dialogWindowMarginTop = moveToBottomOfElement
      ? boundingRect.bottom + 'px'
      : boundingRect.top - dialogWindowMarginFromIcon + 'px';
    const dialogWindowMarginLeft =
      boundingRect.left - dialogWidth / 2 + iconWidth / 2 + 'px';

    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(LanguageProficiencyModal, {
        width: dialogWidth + 'px',
        position: {
          top: dialogWindowMarginTop,
          left: dialogWindowMarginLeft,
        },
        hasBackdrop: false,
        backdropClass: 'openedDialog',
        autoFocus: false,
        restoreFocus: false,
        scrollStrategy: new NoopScrollStrategy(),
      });
    }

    this.dialogRef.disableClose = true;
  }

  leaveDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.disableClose = false;
      let interval = setInterval(() => {
        if (!this.dialogRef) {
          clearInterval(interval);
        } else if (!this.dialogRef.disableClose) {
          clearInterval(interval);
          this.dialogRef.close();
          this.dialogRef = undefined;
        }
      }, 1000);
    }
  }

  submitToHR(): void {
    this.submit(true).subscribe(() => this.dataService.submitToHR());
  }

  hasDuplicates(arr: string[]): boolean {
    return arr.length !== new Set(arr).size;
  }

  sameLanguageValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const names: string[] = this.form?.controls?.map(
        (formControl: FormControl) =>
          formControl?.get('language')?.value?.displayName ??
          formControl?.get('language')?.value,
      );

      if (names) {
        return this.hasDuplicates(names)
          ? { sameLanguage: { value: control.value } }
          : null;
      }

      return null;
    };
  }

  addLanguage(): void {
    super.insertAtStart();
  }
}

export const getLanguagesUpdateModel = (
  form: FormArray,
  languageOptions: ILookupValue[],
): IUpdateModel<IEmployeeCv> => {
  const items = form.getRawValue().map((e) => {
    const { language, proficiency } = e;
    const languageOption = ensureOption(language, languageOptions);
    return {
      language: languageOption.key,
      proficiency: proficiency.key,
    } as ILanguageProficiency;
  });
  return {
    cv: {
      languageProficiencies: items,
    },
  } as IUpdateModel<IEmployeeCv>;
};

export function newLanguageItemControl(
  languageOptions: ILookupValue[],
  languageProficiencyItem: ILanguageProficiency = null,
  lookupData: ILookupData = null,
): FormGroup {
  const { language, proficiency } = languageProficiencyItem || {};

  if (languageProficiencyItem && !lookupData) {
    throw new Error('Form construction failed: no lookupData provided');
  }

  const languageOption = languageOptions.find((e) => e.key === language);
  const languageProficiencyOption = lookupData?.languageProficiencies.find(
    (e) => e.key === proficiency,
  );

  const formGroup = new FormGroup({
    language: new FormControl(languageOption, [
      Validators.required,
      unknownLanguageValidator(languageOptions),
    ]),
    proficiency: new FormControl(languageProficiencyOption, [
      Validators.required,
    ]),
  });

  creatingEditableItemDecorator(formGroup, languageProficiencyItem);

  return formGroup;
}

export function ensureOption(
  option: string | ILookupValue,
  options: ILookupValue[],
): ILookupValue {
  if (option && typeof option === 'string') {
    const existingOption = options.find((e) => e.displayName === option);
    if (!existingOption) {
      throw new Error(
        `Option option ${option} could not be found among option list`,
      );
    }

    return existingOption;
  }

  return option as ILookupValue;
}

export function unknownLanguageValidator(
  languageOptions: ILookupValue[],
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string | ILookupValue = control.value;

    if (typeof value === 'string') {
      const option = languageOptions.find((e) => e.displayName === value);
      return option ? null : { unknownLanguage: { value: control.value } };
    }

    return null;
  };
}
