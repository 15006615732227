import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ButtonModule } from '../form/button/button.module';
import { TextModule } from '../form/text/text.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CellRenderComponent } from './cell-render/cell-render.component';
import { CellEditComponent } from './cell-edit/cell-edit.component';
import { NumberModule } from '../form/number/number.module';
import { RangePickerModule } from '../form/calendar/range-picker.module';
import { AutocompleteModule } from '../form/autocomplete/autocomplete.module';
import { CellEditAutocompleteComponent } from './cell-edit-autocomplete/cell-edit-autocomplete.component';
import { ValidatorValuePipe } from './pipes/validator-value.pipe';
import { IconModule } from '../form/icon/icon.module';
import { TooltipAnimatedModule } from '../form/tooltip-animated/tooltip-animated.module';
import { CellFilterComponent } from './cell-filter/cell-filter.component';
import { SelectModule } from '../form/select/select.module';
import { DatePickerModule } from '../form/datepicker/date-picker.module';
import { ColumnWidthDirective } from './directives/column-width.directive';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SelectAutocompleteModule } from '../form/select-autocomplete/select.autocomplete.module';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatCardModule,
    ButtonModule,
    TextModule,
    NumberModule,
    RangePickerModule,
    ReactiveFormsModule,
    AutocompleteModule,
    CellEditAutocompleteComponent,
    IconModule,
    TooltipAnimatedModule,
    SelectModule,
    DatePickerModule,
    MatRadioModule,
    SelectAutocompleteModule,
  ],
  declarations: [
    TableComponent,
    CellRenderComponent,
    CellEditComponent,
    CellFilterComponent,
    ValidatorValuePipe,
    ColumnWidthDirective,
  ],
  exports: [TableComponent],
})
export class TableModule {}
