<mat-card *ngIf="!(hasUser$ | async) && form">
  <div>
    <img
      class="logo"
      alt="28 stone"
      aria-label="28 stone logo"
      src="assets/svg/28stone-logo.svg"
    />
  </div>

  <div class="error-container" *ngIf="!!errorText && !isLoginInProgress">
    <span>
      <img
        class="error-icon"
        aria-label="exclamation-mark"
        src="assets/svg/exclamation-icon.svg"
      />
    </span>
    <span>
      <mat-error class="error-text">{{ errorText }}</mat-error>
    </span>
  </div>

  <div class="login-container" *ngIf="!isLoginInProgress">
    <h1 class="title">Sign In to SkillSet</h1>
    <lss-button
      class="login-button"
      [color]="'basic'"
      (action)="authenticateWithGoogle()"
    >
      <img aria-label="google icon" src="assets/svg/google-icon.svg" />
      <span>Sign In with Google</span>
    </lss-button>

    <form [formGroup]="form" id="login-form" autocomplete="off">
      <h2 class="login-with-unpw-title">
        <span>OR SIGN IN WITH EMAIL AND PASSWORD</span>
      </h2>

      <div class="login-password-container">
        <div class="login-password">
          <lss-text
            [formControl]="form.controls.email"
            [errorStateMatcher]="matcher"
            label="Email Address"
            placeholder="Ex. pat@example.com"
            errorMessage="Not a valid email"
          ></lss-text>
          <lss-text
            [type]="'password'"
            [formControl]="form.controls.password"
            label="Password"
          ></lss-text>
        </div>

        <div class="button-link-container">
          <span class="forgot-password">
            <a href="/forgotpassword">Forgot Password?</a>
          </span>
          <lss-button
            id="sign-in"
            [disabled]="!canSignIn"
            (action)="
              authenticate(
                form.controls.email.value,
                form.controls.password.value
              )
            "
            >Sign In
          </lss-button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isLoginInProgress" class="spinner-container">
    <h1>Loading user data...</h1>
    <mat-spinner></mat-spinner>
  </div>
</mat-card>

<div class="cookies" *ngIf="!(hasUser$ | async) && form" hidden>
  We use cookies to provide you with the best possible user experience. You
  agree to our use of cookies policy
  <button id="cookie-btn">Accept</button>
  <button id="cookie-btn">Reject</button>
</div>
