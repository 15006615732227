import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IGroupableLookupValue,
  ILookupValue,
  ModalDataTechnicalSkills,
} from '@lss/lss-types';

const MAX_NEW_SKILL_NAME_LENGTH = 160;

@Component({
  selector: 'lss-technical-skills-modal',
  templateUrl: './technical.skills.modal.html',
  styleUrls: ['technical.skills.modal.scss'],
})
export class TechnicalSkillsModalComponent {
  private skillGroups$ = new BehaviorSubject<IGroupableLookupValue[]>([]);
  private alreadyAddedSkillsList: ILookupValue[] = [];
  private searchTerm = '';
  private searchString$ = new BehaviorSubject('');
  private newSkillArray: ILookupValue[] = [];
  skillList: ILookupValue[] = [];
  deletedSkillList: ILookupValue[] = [];
  newSkillName = '';
  skillGroupsForShow: { [key: string]: boolean } = {};
  filteredSkillGroups$: Observable<IGroupableLookupValue[]>;
  alreadyExistingSkill: { [key: string]: string } = {};

  constructor(
    public dialogRef: MatDialogRef<TechnicalSkillsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalDataTechnicalSkills,
  ) {
    this.alreadyAddedSkillsList = data.alreadyAddedSkills.map(
      ({ skill }) => skill,
    );
    this.skillGroups$.next(data.skillGroups);
    this.filteredSkillGroups$ = combineLatest([
      this.skillGroups$,
      this.searchString$,
    ]).pipe(
      map(([groups, searchString]) => {
        searchString = searchString.toLowerCase();
        return groups.reduce((prev, current) => {
          let result = [];
          const foundOptions = current.options.filter((skill) =>
            skill.displayName.toLowerCase().includes(searchString),
          );
          if (foundOptions.length > 0) {
            result = [{ groupName: current.groupName, options: foundOptions }];
          }
          return [...prev, ...result];
        }, []);
      }),
    );
  }

  isListHasSkillDisplayName(
    list: ILookupValue[],
    displayName: string,
  ): boolean {
    return list.some(
      (item) =>
        item &&
        item.displayName.toLowerCase().trim() ===
          displayName.toLowerCase().trim(),
    );
  }

  handleToggle($event: MatButtonToggleChange, skill: ILookupValue): void {
    if (
      !this.isListHasSkillDisplayName(
        this.alreadyAddedSkillsList,
        skill.displayName,
      )
    ) {
      if (this.isListHasSkillDisplayName(this.skillList, skill.displayName)) {
        this.skillList = this.skillList.filter(
          ({ displayName }) =>
            displayName.toLowerCase().trim() !==
            skill.displayName.toLowerCase().trim(),
        );
      } else {
        this.skillList.push(skill);
      }
    } else {
      if (
        this.isListHasSkillDisplayName(this.deletedSkillList, skill.displayName)
      ) {
        this.deletedSkillList = this.deletedSkillList.filter(
          ({ displayName }) =>
            displayName.toLowerCase().trim() !==
            skill.displayName.toLowerCase().trim(),
        );
      } else {
        this.deletedSkillList.push(skill);
      }
    }
  }

  isValidForm(): boolean {
    return this.skillList.length === 0 && this.deletedSkillList.length === 0;
  }

  handleDialogDoneClick(): void {
    if (!this.isValidForm()) {
      this.dialogRef.close({
        skillList: this.skillList,
        action: 'done',
        deletedSkillList: this.deletedSkillList,
        newSkills: this.newSkillArray,
      });
    }
  }

  handleDialogCloseClick(): void {
    this.dialogRef.close({ action: 'close' });
  }

  isChecked(item: ILookupValue): boolean {
    return (
      this.isListHasSkillDisplayName(
        this.alreadyAddedSkillsList,
        item.displayName,
      ) ||
      this.isListHasSkillDisplayName(this.newSkillArray, item.displayName) ||
      this.isListHasSkillDisplayName(this.skillList, item.displayName)
    );
  }

  handleInput($event: InputEvent): void {
    this.searchTerm = ($event.target as HTMLInputElement).value;
    this.searchString$.next(this.searchTerm);
  }

  handleOpenNewFieldClick(groupName: string): void {
    if (this.skillGroupsForShow[groupName]) {
      this.skillGroupsForShow[groupName] = !this.skillGroupsForShow[groupName];
    } else {
      Object.keys(this.skillGroupsForShow).forEach(
        (group) => (this.skillGroupsForShow[group] = false),
      );
      this.skillGroupsForShow[groupName] = !this.skillGroupsForShow[groupName];
    }
    this.newSkillName = '';
  }

  handleAddNewSkillClick(groupName: string): void {
    if (this.isNewSkillNameInvalid()) {
      return;
    }

    const newSkill: ILookupValue = {
      key: this.newSkillName,
      displayName: this.newSkillName,
      group: groupName.toLowerCase().trim(),
    };

    this.skillGroups$.next(
      this.skillGroups$.value.map((group) => {
        if (
          group.groupName.toLowerCase().trim() ===
          groupName.toLowerCase().trim()
        ) {
          group.options.push(newSkill);
          this.newSkillArray.push(newSkill);
          this.skillList.push(newSkill);
          this.newSkillName = '';
          this.skillGroupsForShow[group.groupName] = false;
        }
        return group;
      }),
    );
  }

  isErrorIconVisible = (): boolean => {
    // NOTICE we don't want to show error icon when input is empty
    return this.newSkillName !== '' && this.isNewSkillNameInvalid();
  };

  isNewSkillNameInvalid = (): boolean => {
    return (
      this.doesSkillAlreadyExists() ||
      this.newSkillName === '' ||
      this.newSkillName.length >= MAX_NEW_SKILL_NAME_LENGTH
    );
  };

  getValidationMessage = (): string | undefined => {
    if (this.newSkillName === '') {
      return 'New skill name cannot be empty';
    }

    if (this.newSkillName.length >= MAX_NEW_SKILL_NAME_LENGTH) {
      return `New skill name length must have maximum ${MAX_NEW_SKILL_NAME_LENGTH} characters`;
    }

    if (this.doesSkillAlreadyExists()) {
      return `"${
        this.alreadyExistingSkill.displayName
      }" skill already exists in "${this.alreadyExistingSkill.groupName.toUpperCase()}" category`;
    }
  };

  handleInputSkillName($event: InputEvent): void {
    this.newSkillName = ($event.target as HTMLInputElement).value;
    this.updateExistingSkillObject();
  }

  doesSkillAlreadyExists(): boolean {
    const newSkillName = this.newSkillName;

    return this.skillGroups$.value.some((group) =>
      group.options.some(
        (skill) =>
          skill.displayName.toLowerCase().trim() ===
          newSkillName.toLowerCase().trim(),
      ),
    );
  }

  updateExistingSkillObject(): void {
    const newSkillName = this.newSkillName;

    this.skillGroups$.value.forEach((group) =>
      group.options.find((skill) => {
        if (
          skill.displayName.toLowerCase().trim() ===
          newSkillName.toLowerCase().trim()
        ) {
          this.alreadyExistingSkill['displayName'] = skill.displayName;
          this.alreadyExistingSkill['groupName'] = skill.group;
        }
      }),
    );
  }
}
