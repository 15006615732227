import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  HostBinding,
  ElementRef,
} from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  state,
  AnimationBuilder,
} from '@angular/animations';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { animateRemove } from '../animation/itemAnimation';
import { UiConstants } from '../../ui-constants';

@Component({
  selector: 'lss-editable-item',
  templateUrl: './editable-item.component.html',
  styleUrls: ['./editable-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeAnimation', [
      state(
        UiConstants.Animation.State.Creating,
        style({ opacity: 0, height: '0px' }),
      ),
      state(
        UiConstants.Animation.State.Ready,
        style({ opacity: 1, height: '*' }),
      ),
      transition(
        `${UiConstants.Animation.State.Creating} => ${UiConstants.Animation.State.Ready}`,
        [animate(UiConstants.Animation.Duration.fadeInDuration)],
      ),
    ]),
  ],
})
export class EditableItemComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private cd: ChangeDetectorRef,
    private animationBuilder: AnimationBuilder,
    private elementRef: ElementRef,
  ) {}

  layoutInfo: any;
  destroyed$ = new Subject();

  @Output() remove = new EventEmitter();
  @HostBinding('class.invalid') @Input() invalid = false;
  @Input() state = UiConstants.Animation.State.Ready;
  @Input() nesting = 0;
  @Input() item: FormGroup;
  @Input() getRemoveConfirmation$ = () => new BehaviorSubject(true);

  ngOnInit(): void {
    this.layoutInfo = this.getLayoutInfo(this.nesting);
  }

  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      this.state = UiConstants.Animation.State.Ready;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getLayoutInfo(nesting: number | string): any {
    if (nesting === 0) {
      return {
        addColor: UiConstants.Button.Color.BasicPrimary,
        removeColor: UiConstants.Button.Color.LinkPrimary,
      };
    }

    if (nesting === 1) {
      return {
        addColor: UiConstants.Button.Color.Basic,
        removeColor: UiConstants.Button.Color.Icon,
      };
    }

    if (nesting === 'table') {
      return {
        addColor: UiConstants.Button.Color.Basic,
        removeColor: UiConstants.Button.Color.Icon,
      };
    }
  }

  onRemove(): void {
    const confirmation$ =
      this.getRemoveConfirmation$() || new BehaviorSubject(true);

    confirmation$
      .pipe(
        filter((x) => x),
        switchMap(() =>
          animateRemove(this.animationBuilder, this.elementRef.nativeElement),
        ),
        tap(() => this.remove.emit()),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
