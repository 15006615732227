<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<mat-toolbar color="primary">
  <a class="lss-logo" (click)="navigateToRoute(urlRoute)">
    <span>
      <img src="assets/svg/skill-set-logo.svg" />
    </span>
  </a>
  <ng-content></ng-content>
</mat-toolbar>
<div id="lss-toolbar-custom-overlay" class="cdk-overlay-container"></div>
