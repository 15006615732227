import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { ProgressService } from '../form/progress.service';

@Component({
  selector: 'lss-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements AfterViewInit {
  @Input() urlRoute: string;

  destroyed$ = new Subject();
  nextRoute: any;
  loading: boolean;

  constructor(
    private router: Router,
    private progressService: ProgressService,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.progressService.loading$
      .pipe(
        delay(0),
        tap((e) => {
          this.loading = e;
          this.cd.markForCheck();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  navigateToRoute(route: string) {
    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([route]);
  }
}
