import {
  INavigationConfirmData,
  NotificationMode,
  CognitoGroupsKey,
  NavigationCommand,
  RoleId,
} from '@lss/lss-types';

export const Constants = {
  Login: {
    LogoutConfirmationBody:
      'Any information that you haven’t saved will be lost permanently. Are you sure you want to logout?',
    CognitoGroupsKey,
    IdentityProviderName: 'Google',
    ReturnUrlSessionKey: 'returnUrl',
  },
  WorkExperience: {
    ConfirmRemoveExperienceHeader: 'Remove Work Experience',
    GetConfirmRemoveExperienceBody: (companyName: string) =>
      `Are you sure you want to remove your experience at ${
        companyName ? companyName : 'this company'
      }?`,
    ConfirmRemoveRoleHeader: 'Remove Role',
    GetConfirmRemoveRoleBody: (companyName: string, roleName: string) =>
      `Are you sure you want to remove your ${
        roleName ? roleName : ''
      } role at ${companyName ? companyName : 'this company'}?`,
    Fields: {
      company: 'company',
      roles: 'roles',
      title: 'title',
    },
    permanentControlName: 'permanent',
  },
  Date: {
    WorkFormat: 'M/yyyy',
    FullDateFormat: 'dd/MM/yyyy',
    FullDateTimeFormat: 'dd/MM/yyyy HH:mm',
    DatePickerParseFormat: 'DD/MM/YYYY', // moment
  },
  Links: {
    HowTo:
      'https://docs.google.com/document/d/1JwDR-2SWP0ARr7lF-vVjEaUeFuEC91oCnDTuKXXqbNA/edit?usp=sharing',
  },
  Authentication: {
    Token: 'authenticated_',
    NewPasswordRequiredChallenge: 'NEW_PASSWORD_REQUIRED',
    UserNotFoundException: 'UserNotFoundException',
    UserNotAuthorizedException: 'NotAuthorizedException',
    UserNotFoundMessage:
      'Entered Email Address and Password do not match any existing account. Please try again.',
    TestUserPassword: 'Test_1234',
  },
  Education: {
    ConfirmRemoveHeader: 'Remove Institution?',
    ConfirmRemoveBody: (Institution: string) =>
      Institution
        ? `Are you sure you want to remove your education information at ${Institution}?`
        : 'Are you sure you want to remove your education information?',
    ConfirmRemoveDegreeHeader: 'Remove Degree?',
    ConfirmRemoveDegreeBody:
      'Are you sure you want to remove your degree at this institution?',
  },
  AdminCategoryManagement: {
    ConfirmRemoveHeader: 'Please confirm that you want to delete the Category',
  },
  AdminUserManagement: {
    ConfirmRemoveHeader: 'Please confirm that you want to delete the user',
    ConfirmUserRoleChangeHeader: 'Please confirm role change',
    ConfirmUserRoleChangeBody: 'The user rights will be updated',
  },
  ApplicationUpdate: {
    Header: 'SkillSet update',
    Body: 'A new version is available. Do you want to refresh now?\r\nPlease save all changes before refresh.',
    OKButton: 'Yes, refresh now',
    CancelButton: 'Later',
  },
  Skills: {
    ConfirmRemoveSkill: 'Please confirm that you want to delete the skill',
    ConfirmRemoveSkillBody: (skill: string) =>
      `The skill <b>${skill}</b> will be deleted from the Skills list and users CVs`,
    RemoveSkillBtnCancel: 'Cancel',
    RemoveSkillBtnConfirm: 'Confirm',
    ConfirmSkillsMerge: 'Please confirm that you want to merge Skills',
  },
  Search: {
    SearchTerm: 'searchTerm',
    ExactMatch: 'exactMatch',
  },
  Endpoints: {
    Version: '/version',
    Cv: '/cv',
    EmployeeCv: '/cv/employee',
    CandidateCv: '/cv/candidate',
    Lookup: '/lookup',
    Search: '/search',
    Docx: '/docx',
    Administrate: '/administrate',
    CandidateData: '/candidateData',
    LinkedInRequest: '/linkedin-request',
    LinkedInStatus: '/linkedin-status',
    LinkedInUpdate: '/linkedin-update',
    ConfigurationRequest: '/configuration-request?key=LinkedIn',
    ConfigurationUpdate: '/configuration-update',
    Register: '/register',
    RegisterExternalHr: '/register-externalhr',
    CognitoSync: '/cognito-sync',
    GetCandidateData: '/set-password',
    SavePassword: '/save-password',
    ForgotPassword: '/forgotpassword',
    CandidatePreview: '/getCv/candidate',
    SkillCategories: '/categories',
    Skills: '/skills',
    SkillsTimesUsed: (categoryKey, skillKey) =>
      `/categories/${categoryKey}/skills/${skillKey}/times-used-in-cv`,
    ProfilePicture: (bambooId, pictureSize) =>
      `/picture/${bambooId}/${pictureSize}`,
    Users: '/users',
    Roles: '/roles',
    User: '/user',
    History: '/history',
    ExEmployee: (email) => `/ex-employees/${email}`,
    CandidateMerge: (email) => `/candidates/merge/${email}`,
  },
  HttpHeaders: {
    Authorization: 'Authorization',
  },
  HttpMethods: {
    Get: 'get',
    Post: 'post',
    Put: 'put',
    Delete: 'del',
  },
  Window: {
    token: 'WINDOW_TOKEN',
    window: window || { history: { state: {}, back: () => {} } },
  },
  StatusCodeDescriptions: {
    E500: "We've encountered an error",
    E404: "We didn't find anything",
    E401: "We don't know you",
    E400: "We didn't understand your request",
  },
  Routes: {
    Home: 'home',
    Login: 'login',
    Cv: 'cv',
    CvSummary: 'cv/summary',
    CvWorkExperience: 'cv/work',
    CvEducation: 'cv/education',
    CvTechnicalSkills: 'cv/technical',
    CvLanguages: 'cv/languages',
    CvCertification: 'cv/certification',
    Admin: 'admin',
    AdminUserManagement: 'users',
    AdminSkillManagement: 'skills',
    AdminCategoryManagement: 'categories',
    AdminEventsActions: 'events',
    AdminHistory: 'history',
    AdminEmailTemplatesManagement: 'email-templates',
    Error: 'error',
    Search: 'search',
    Preview: 'preview/:email',
    Form: 'form',
    NotFound: 'not-found',
    RegisterCandidate: 'candidate/register',
    RegisterExternalHr: 'externalhr/register',
    SetPasswordCandidate: 'set-password',
    TermsOfService: 'terms-of-service',
    PrivacyPolicy: 'privacy-policy',
    ForgotPassword: 'forgotpassword',
    TipsAndTricks: 'tips-and-tricks',
    CandidateCv: 'candidate-cv',
    ConfirmNavigation: Object.seal({
      header: 'Unsaved Changes',
      body: 'You have started writing or editing information.\r\nAre you sure you want to leave this page?',
      confirmButtonText: 'Leave this page',
      declineButtonText: 'Stay on this page',
      saveAndConfirmButtonText: 'Save and leave',
      resultFilter: (e: NavigationCommand) =>
        e !== NavigationCommand.StopNavigation,
    }) as INavigationConfirmData,
    ConfirmNavigationTable: Object.seal({
      header: 'Unsaved Changes',
      body: 'You have started creating new or editing a record.\r\nIf you will not save your changes first, all entered data will be lost.\r\nAre you sure you want to leave this page?',
      confirmButtonText: 'Leave this page',
      declineButtonText: 'Stay on this page',
      saveAndConfirmButtonText: '',
      resultFilter: (e: NavigationCommand) =>
        e !== NavigationCommand.StopNavigation,
    }) as INavigationConfirmData,
    ConfirmNavigationIncompleteData: Object.seal({
      header: 'Incomplete data',
      body: 'You have incomplete data.\r\nAre you sure you want to leave this page?',
      confirmButtonText: 'Leave this page',
      declineButtonText: 'Stay on this page',
      resultFilter: (e: NavigationCommand) =>
        e !== NavigationCommand.StopNavigation,
    }) as INavigationConfirmData,
    ConfirmLogout: Object.seal({
      header: 'Unsaved Changes',
      body: 'You have started writing or editing information.\r\nAre you sure you want to leave this page?',
      confirmButtonText: 'Log out',
      declineButtonText: 'Stay on this page',
      saveAndConfirmButtonText: 'Save and log out',
      resultFilter: (e: NavigationCommand) =>
        e !== NavigationCommand.StopNavigation,
    }) as INavigationConfirmData,
    ConfirmLogoutTable: Object.seal({
      header: 'Unsaved Changes',
      body: 'You have started creating new or editing a record.\r\nIf you will not save your changes first, all entered data will be lost.\r\nAre you sure you want to leave this page?',
      confirmButtonText: 'Log out',
      declineButtonText: 'Stay on this page',
      saveAndConfirmButtonText: '',
      resultFilter: (e: NavigationCommand) =>
        e !== NavigationCommand.StopNavigation,
    }) as INavigationConfirmData,
    ConfirmLogoutRegisterCandidateOrHR: Object.seal({
      header: 'Action incomplete',
      body: 'You have started registration of the user.\r\nThe user will not be created until you click on the "Generate link".\r\nAre you sure you want to leave this page?',
      confirmButtonText: 'Stay on this page',
      declineButtonText: 'Log out',
      saveAndConfirmButtonText: '',
      resultFilter: (e: NavigationCommand) =>
        e !== NavigationCommand.StopNavigation,
    }) as INavigationConfirmData,
    ConfirmNavigationIncompleteDataRegisterNewCandidateOrHR: Object.seal({
      header: 'Action incomplete',
      body: 'You have started registration of the user.\r\nThe user will not be created until you click on the "Generate link".\r\nAre you sure you want to leave this page?',
      confirmButtonText: 'Stay on this page',
      declineButtonText: 'Leave this page',
      resultFilter: (e: NavigationCommand) =>
        e !== NavigationCommand.StopNavigation,
    }) as INavigationConfirmData,
  },
  Notifications: {
    SaveCvError: Object.seal({
      title: 'Error Saving your Data',
      body: 'The information you entered could not be saved. Please try again or contact HR for assistance.',
      mode: NotificationMode.Error,
    }),
    GetEmployeeError: Object.seal({
      title: 'Employee Not Found',
      body: 'Employee not found. Please try again or contact HR for assistance.',
      mode: NotificationMode.Error,
    }),
    GetEmployeeExternalHrError: Object.seal({
      title: 'Forbidden',
      body: 'You do not have access to 28Stone employee profile.',
      mode: NotificationMode.Error,
    }),
    SaveCvSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'Your information has been saved.',
      mode: NotificationMode.Success,
    }),
    GenerateCvError: (body) => ({
      title: 'Error Generating the CV',
      body,
      mode: NotificationMode.Error,
    }),
    PasswordSetSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'Your password was set successfully. You are now signed in.',
      mode: NotificationMode.Success,
    }),
    ApplyLocalCvError: Object.seal({
      title: 'Error Applying Stored Cv',
      body: 'Applying locally stored cv failed: key was not provided.',
      mode: NotificationMode.Error,
    }),
    SendToHRReminderSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'When you have completed your profile, don’t forget to submit it to HR.',
      mode: NotificationMode.Success,
    }),
    MissingMandatoryFieldsWarning: Object.seal({
      title: 'Profile cannot be submitted',
      body: 'Some mandatory fields are missing. Please review the info you have entered, fill out the missing info, and try again.',
      mode: NotificationMode.Warning,
    }),
    SendSubbmitionSuccess: Object.seal({
      title: 'Profile submitted',
      body: 'Your profile has been sent to HR',
      mode: NotificationMode.Success,
    }),
    CopyLinkToClipboardSuccess: Object.seal({
      title: 'Link copied to clipboard',
      body: 'Now you can Paste and Share it',
      mode: NotificationMode.Success,
    }),
    SkillCreationSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'The skill has been updated successfully',
      mode: NotificationMode.Success,
    }),
    CreateCategorySuccess: Object.seal({
      title: 'Changes Saved',
      body: 'New category created successfully',
      mode: NotificationMode.Success,
    }),
    CreateCategoryError: Object.seal({
      title: 'Failed!',
      body: 'Operation failed, please contact database administrator',
      mode: NotificationMode.Error,
    }),
    CreateCategoryExistsError: Object.seal({
      title: 'Category can’t be added',
      body: 'Specified category name already exists in the system',
      mode: NotificationMode.Error,
    }),
    DeleteCategorySuccess: Object.seal({
      title: 'Changes Saved',
      body: 'The category has been deleted successfully',
      mode: NotificationMode.Success,
    }),
    DeleteCategoryError: Object.seal({
      title: 'Failed!',
      body: 'Operation failed, please contact database administrator',
      mode: NotificationMode.Error,
    }),
    UpdateCategorySuccess: Object.seal({
      title: 'Changes Saved',
      body: 'The category has been updated successfully',
      mode: NotificationMode.Success,
    }),
    UpdateCategoryError: Object.seal({
      title: 'Failed!',
      body: 'Operation failed, please contact database administrator',
      mode: NotificationMode.Error,
    }),
    UpdateCategoryExistsError: Object.seal({
      title: 'Category can’t be updated',
      body: 'Specified category name already exists in the system',
      mode: NotificationMode.Error,
    }),
    DeleteSkillSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'The skill has been deleted successfully',
      mode: NotificationMode.Success,
    }),
    DeleteSkillError: Object.seal({
      title: 'Failed!',
      body: 'Unable to delete skill, please contact database administrator',
      mode: NotificationMode.Error,
    }),
    MergeSkillSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'The skills have been merged successfully',
      mode: NotificationMode.Success,
    }),
    MergeSkillError: Object.seal({
      title: 'Failed!',
      body: 'Unable to merge skills, please contact database administrator',
      mode: NotificationMode.Error,
    }),
    CreateSkillSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'New skill created successfully',
      mode: NotificationMode.Success,
    }),
    CreateSkillError: Object.seal({
      title: 'Skill can’t be added',
      body: 'Unable to create skill, please contact database administrator',
      mode: NotificationMode.Error,
    }),
    CreateSkillDuplicateError: Object.seal({
      title: 'Skill can’t be added',
      body: 'Specified skill name already exists in the system',
      mode: NotificationMode.Error,
    }),
    UpdateSkillSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'The skill has been updated successfully',
      mode: NotificationMode.Success,
    }),
    UpdateSkillError: Object.seal({
      title: 'Skill can’t be updated',
      body: 'Unable to update skill, please contact database administrator',
      mode: NotificationMode.Error,
    }),
    UpdateSkillDuplicateError: Object.seal({
      title: 'Skill can’t be updated',
      body: 'Specified skill name already exists in the system',
      mode: NotificationMode.Error,
    }),
    CommonError: Object.seal({
      title: 'Failed!',
      body: 'Operation failed, please contact database administrator',
      mode: NotificationMode.Error,
    }),
    EmailNotValidError: Object.seal({
      title: 'Failed!',
      body: "Please enter a valid email address. \r\n Common errors: \r\n  - Missing '@' symbol \r\n  - Invalid domain name (>6 symbols)",
      mode: NotificationMode.Error,
    }),
    DeleteUserSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'The user has been deleted successfully.',
      mode: NotificationMode.Success,
    }),
    UpdateUserSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'User data has been updated successfully.',
      mode: NotificationMode.Success,
    }),
    UpdateUserAndRoleSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'User data and role has been updated successfully.',
      mode: NotificationMode.Success,
    }),
    UpdateUserRoleSuccess: Object.seal({
      title: 'Changes Saved',
      body: 'User role has been updated successfully.',
      mode: NotificationMode.Success,
    }),
    UpdateUserRoleError: Object.seal({
      title: 'Failed!',
      body: 'Role can not be changed.',
      mode: NotificationMode.Error,
    }),
    UpdateUserExistsError: Object.seal({
      title: 'Email can’t be updated',
      body: 'Specified email address already exists in the system.',
      mode: NotificationMode.Error,
    }),
  },
  CompanyInfo: {
    Phone: '+1 908 907 1030',
    Email: 'info@28stone.com',
    Name: '28Stone Consulting, Inc.',
    ShortName: '28Stone Inc.',
    IssuesLikn:
      'https://docs.google.com/spreadsheets/d/1U2-jT48c-djArKYUnNkTHFUZFnpNdDi8fCY-8L4R_Qs/edit#gid=0',
  },
  User: {
    UserObjectKeyFactory: (key) => `_____skillsetuser(${key})`,
  },
  Empty: {
    Object: '{}',
  },
  WarningNotification: {
    title: 'Some fields are missing',
    body: 'Please complete missing fields for degrees, locations, start and end dates.',
  },
  LinkedIn: {
    ProfileUrlPrefix: 'linkedin.com/in/',
  },
  ConfirmSendToHR: {
    title: 'Please confirm sending your profile information to HR',
  },
  AdminCommands: {
    ReindexAll: {
      title: 'New indexes will be created for data base',
      commandId: 'reindexAll',
      command: 'admincommand__reindexall',
    },
    CopyDataFromProd: {
      title: 'Data will be copied from Production environment to QA',
      commandId: 'copyDataFromProd',
      command: 'admincommand__copydatafromprod',
    },
  },
  CvSections: {
    summary: 'summary',
    workExperience: 'work-experience',
    education: 'education',
    technicalSkills: 'technical-skills',
    languages: 'languages',
    certification: 'certification',
  },
  ErrorMessages: {
    sameEmailExists: 'The user with the specified email address already exists',
    candidateExists:
      'Unable to generate link - The user with the specified email address already exists',
    candidateExistsExternalHR:
      'Candidate with the specified email already exists.\n Please contact 28Stone HR.',
    resetPasswordLinkHasExpired: 'Your reset password link has expired',
  },
  Warnings: {
    browserNotChrome:
      'This application runs best on Chrome! If you are trying to access with a different browser, some visual or technical impairments might be experienced with this tool!',
  },
  Status: {
    failed: 'Failed!',
  },
  UserRoles: {
    employee: 'Employee',
    candidate: 'Candidate',
    externalHr: 'ExternalHR',
    powerUser: 'PowerUser',
    admin: 'Admin',
    hr: 'HR',
    exEmployee: 'ExEmployee',
  },
  UserRolesDescription: {
    [RoleId.Employee]: 'Employee',
    [RoleId.Candidate]: 'Candidate',
    [RoleId.ExternalHR]: 'External HR',
    [RoleId.PowerUser]: 'Power User',
    [RoleId.Admin]: 'Admin',
    [RoleId.HR]: 'HR',
    [RoleId.ExEmployee]: 'Ex-employee',
  },

  // todo
  // we can't rely on displayName
  // replace it with roleId based approach
  // also need to double check 'HR' option -> it looks like it should be 'Hr' : { "roleId": 5, "displayName": "Hr" }

  RoleActions: {
    canRegisterExternalHr: ['HR', 'Admin'] as string[],
    canRegisterCandidate: ['HR', 'Admin', 'ExternalHR'] as string[],
    canGenerateCV: ['Employee', 'PowerUser', 'HR', 'Admin'] as string[],
    canSearch: ['PowerUser', 'HR', 'Admin', 'ExternalHR'] as string[],
    canAccessActions: [
      'Employee',
      'PowerUser',
      'HR',
      'Admin',
      'Candidate',
    ] as string[],
    canShareCandidateLink: ['HR', 'Admin'] as string[],
  },
  JobTitles: {
    Employee: 'Employee',
    ExEmployee: 'Ex-employee',
    Candidate: 'Candidate',
  },
  StorageKeys: {
    fullname: 'fullname',
    tokenExpired: 'tokenExpired',
    loggedIn: 'loggedIn',
    isLoginInProgress: 'isLoginInProgress',
    loadState: 'loadState',
    tokenRefreshExecutor: 'tokenRefreshExecutor',
    isSubmitDisabled: 'isSubmitDisabled',
  },
  Words: {
    courses: 'courses',
    certificates: 'certificates',
  },
  DuplicateCandidate: {
    Header: 'Duplicate user',
    Body: 'Candidate user with specified Email Address already exists. Do you want to merge data?',
    OKButton: 'Yes, merge',
    CancelButton: 'Cancel',
  },
} as const;
