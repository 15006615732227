import { ILookupValue, ITechnicalSkill, ILookupData } from '@lss/lss-types';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LssValidators } from '@lss/lss-ui';

export function ensureLookupOrAssignOtherByDisplayName(
  value: string | ILookupValue,
  otherLookups: ILookupValue[],
  existingLookups: ILookupValue[],
  otherGroup: string,
): ILookupValue {
  const displayName =
    value && typeof value === 'string'
      ? value
      : (value as ILookupValue)?.displayName;

  if (displayName) {
    const otherLookupKey = displayName.toLowerCase();
    const existingLookup = existingLookups.find(
      (e) => e.displayName.toLowerCase() === otherLookupKey,
    );
    const selectedLookup =
      existingLookup ??
      ({
        group: otherGroup,
        key: otherLookupKey,
        displayName,
      } as ILookupValue);

    if (!existingLookup) {
      otherLookups.push(selectedLookup);
    }

    return selectedLookup;
  }

  return value as ILookupValue;
}

export const TechnicalSkillsConstants = {
  TechnicalSkills: {
    OtherGroup: 'other',
  },
  Lookup: {
    SkillsKey: 'skills',
  },
};

const minStartYear = 1970;

export function getLastUsedYears(): ILookupValue[] {
  const currentYear = new Date().getFullYear();
  const yearSpan = currentYear - minStartYear + 1;

  return Array(yearSpan)
    .fill(0)
    .map((_, idx) => ({
      key: (currentYear - yearSpan + 1 + idx).toString(),
      displayName: (currentYear - yearSpan + 1 + idx).toString(),
      group: '',
    }))
    .reverse();
}

export function newSkillItemControl(
  technicalSkill: Partial<ITechnicalSkill> = null,
  lookupData: Partial<ILookupData> = null,
): FormGroup {
  const {
    skill,
    lastUsed,
    yearsOfExperience,
    skillProficiency,
    comments,
    willingToUse,
  } = technicalSkill || {};

  if (technicalSkill && !lookupData) {
    throw new Error('Form construction failed: no lookupData provided');
  }

  const skillOption = lookupData?.skills?.find(
    (e) => e.displayName.toLowerCase() === skill.toLowerCase(),
  );
  const skillProficiencyOption = lookupData?.skillProficiencies?.find(
    (e) => e.key === skillProficiency,
  );

  const key = willingToUse ? 'yes' : 'no';

  const willingToUseOption = lookupData?.willingToUse?.find(
    (e) => e.key === key,
  );
  const formGroup = new FormGroup({
    skill: new FormControl(skillOption),
    lastUsed: new FormControl(lastUsed),
    yearsOfExperience: new FormControl(yearsOfExperience),
    skillProficiency: new FormControl(skillProficiencyOption),
    willingToUse: new FormControl(willingToUseOption),
    comments: new FormControl(comments),
  });

  const { minYear, maxYear, maxYearsOfExperience } =
    getTechnicalSkillTimeBounds();

  addTechnicalSkillValidators(
    formGroup,
    minYear,
    maxYear,
    maxYearsOfExperience,
  );

  return formGroup;
}

export function getTechnicalSkillTimeBounds() {
  const currentYear = new Date().getFullYear();

  return {
    maxYearsOfExperience: currentYear - minStartYear,
    minYear: minStartYear,
    maxYear: currentYear,
  };
}

export function addTechnicalSkillValidators(
  technicalSkill: FormGroup,
  minYear,
  maxYear,
  maxYearsOfExp,
) {
  technicalSkill.controls.skill.setValidators([Validators.required]);
  technicalSkill.controls.lastUsed.setValidators([
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(4),
    Validators.min(minYear),
    Validators.max(maxYear),
    LssValidators.isNumber,
  ]);
  technicalSkill.controls.yearsOfExperience.setValidators([
    Validators.required,
    Validators.max(maxYearsOfExp),
    Validators.min(0),
  ]);
  technicalSkill.controls.skillProficiency.setValidators([Validators.required]);
}

export const willingToUseOptions = [
  { key: 'yes', displayName: 'Yes', group: '' },
  { key: 'no', displayName: 'No', group: '' },
];
