<div class="overlay" [class.active]="isActive">
  <div
    class="loading-container"
    [ngClass]="background"
    [class.loader]="mode === 'loader'"
    [class.spinner]="mode === 'spinner'"
  >
    <ng-container *ngIf="mode">
      <div class="image-loading-container">
        <mat-progress-bar
          *ngIf="mode === 'loader'"
          mode="indeterminate"
        ></mat-progress-bar>

        <mat-progress-spinner
          *ngIf="mode === 'spinner'"
          [color]="color"
          mode="indeterminate"
          [diameter]="diameter"
        ></mat-progress-spinner>
      </div>

      <span class="overlayMsg" *ngIf="message">
        {{ message }}
      </span>
    </ng-container>
  </div>

  <ng-content></ng-content>
</div>
