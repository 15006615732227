import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetPasswordComponent } from './set-password.component';
import { ButtonModule, RenderingModule, TextModule, FormCardModule } from '@lss/lss-ui';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SetPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    MatCheckboxModule,
    RenderingModule,
    FormsModule,
    MatCardModule,
    TextModule,
    FormCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
})
export class SetPasswordModule {}
