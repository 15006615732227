import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemePalette } from '@angular/material/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

@Component({
  standalone: true,
  selector: 'lss-overlay-loading',
  templateUrl: './overlay-loading.component.html',
  styleUrls: ['./overlay-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatProgressSpinnerModule, MatProgressBarModule],
})
export class OverlayLoadingComponent implements OnInit {
  @Input() isActive: boolean;
  @Input() color?: ThemePalette;
  @Input() diameter?: number = 40;
  @Input() message? = '';
  @Input() background? = '';
  /**
   * Loading mode
   * `''` aka empty - No loading indicator will be shown. Only overlay will be shown
   * `'loader'` - Progress bar will be shown at the top of overlay
   * `'spinner'` - Circular progress spinner will be shown in the middle of overlay
   *
   * @type {('' | 'loader' | 'spinner')}
   * @memberof OverlayLoadingComponent
   */
  @Input() mode: '' | 'loader' | 'spinner' = 'spinner';

  ngOnInit() {
    if (this.background === '') {
      this.background = 'blackBg';
    }
  }
}
