<mat-form-field
  hideRequiredMarker="hideRequired"
  appearance="standard"
  [class.mat-form-field-disabled]="!!formGroup.disabled"
  [formGroup]="formGroup"
>
  <mat-label *ngIf="!isCourse; else courseLabel"
    >Start{{ required && !formGroup.disabled && endDateOptional ? '*' : '' }}
    and end dates
    {{
      required && !formGroup.disabled && !endDateOptional ? '*' : ''
    }}</mat-label
  >
  <ng-template #courseLabel>
    <mat-label>Start Date - End Date</mat-label>
  </ng-template>

  <input
    matInput
    ngDefaultControl
    #calendarInputElement
    [(ngModel)]="displayValue"
    [ngModelOptions]="{ standalone: true }"
    [required]="required"
    autocomplete="off"
    [errorStateMatcher]="matcher"
    (change)="manualInputOnChange($event)"
    (input)="onInputTyping()"
    (blur)="onFocusOut()"
    (click)="touched()"
    placeholder="MM/YYYY - MM/YYYY"
  />
  <mat-error *ngIf="!hideError">{{ getErrorMessage() }}</mat-error>
  <button
    [disabled]="formGroup.disabled"
    matSuffix
    mat-icon-button
    class="mat-icon-button mat-button-base calendar"
    (click)="open($event)"
  >
    <img src="assets/svg/calendar-icon.svg" />
  </button>
</mat-form-field>
