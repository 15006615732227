import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ISimpleConfirmData } from '@lss/lss-types';
import { ConfirmationService, SimpleConfirmComponent } from '@lss/lss-ui';
import { interval } from 'rxjs';
import { distinctUntilChanged, filter, first } from 'rxjs/operators';
import { Constants } from '../constants';
import { AuthService } from './auth.service';

const MILLIS_IN_SEC = 1000;
const APP_UPDATE_INTERVAL = 600 * MILLIS_IN_SEC; // 10 mins
const AFTER_UPDATE_ON_LOGIN_TIMEOUT = 15 * MILLIS_IN_SEC; // 15 secs

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private newVersionDownloadedDuringLogin: boolean;

  constructor(
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private confirmationService: ConfirmationService,
  ) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type === 'VERSION_READY') {
          // No authenticatedUser means login screen
          // show popup notification after user proceed
          if (!this.authService.authenticatedUser$.getValue()) {
            this.newVersionDownloadedDuringLogin = true;
          } else {
            this.showUpdateNotification();
          }
        }
      });

      const interval$ = interval(APP_UPDATE_INTERVAL);

      interval$.subscribe(() => {
        this.swUpdate
          .checkForUpdate()
          .then((updateFound) => {
            if (updateFound) {
              console.info('A new version is available.');
            }
          })
          .catch((e) => {
            console.error('Application update error!', e);
          });
      });

      let delayTimeOut;

      const authenticatedUser$ = this.authService.authenticatedUser$;
      authenticatedUser$.pipe(distinctUntilChanged()).subscribe((user) => {
        if (delayTimeOut) {
          clearTimeout(delayTimeOut);
        }

        if (user && this.newVersionDownloadedDuringLogin) {
          delayTimeOut = setTimeout(() => {
            this.showUpdateNotification();
            clearTimeout(delayTimeOut);
          }, AFTER_UPDATE_ON_LOGIN_TIMEOUT);
        }
      });
    } else {
      console.warn('No service worker monitoring available.');
    }
  }

  showUpdateNotification() {
    this.newVersionDownloadedDuringLogin = false;

    const data: ISimpleConfirmData = {
      header: Constants.ApplicationUpdate.Header,
      body: Constants.ApplicationUpdate.Body,
      confirmButtonText: Constants.ApplicationUpdate.OKButton,
      declineButtonText: Constants.ApplicationUpdate.CancelButton,
      resultFilter: () => true,
    };

    this.confirmationService
      .confirm$(SimpleConfirmComponent, data, '535px')
      .pipe(
        first(),
        filter((isConfirmed: boolean) => isConfirmed),
      )
      .subscribe(() => {
        document.location.reload();
      });
  }
}
