import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'formcard-proficiency-modal',
  templateUrl: 'formcard.proficiency.modal.html',
})
export class FormcardProficiencyModal {
  constructor(
    public dialogRef: MatDialogRef<FormcardProficiencyModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  leaveDialog() {
    this.dialogRef.disableClose = false;
  }
  openDialog() {
    this.dialogRef.disableClose = true;
  }
}