import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  IEmployeeCv,
  IGroupableLookupValue,
  ILookupData,
  ILookupSkillRequestData,
  ILookupValue,
  ITechnicalSkill,
  IUpdateModel,
} from '@lss/lss-types';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../base/data.service';
import {
  debounceTime,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  animate,
  AnimationBuilder,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import {
  BaseListCrudComponent,
  ConfirmationService,
  LssValidators,
  enterAnimation,
  leaveAnimation,
  tryGetLookupKey,
  tryGetLookupDisplayName,
  ListCrudUtilService,
  isNil,
  getSkillRequestData,
} from '@lss/lss-ui';
import {
  ensureLookupOrAssignOtherByDisplayName,
  TechnicalSkillsConstants,
  newSkillItemControl,
  willingToUseOptions,
} from '@lss/lss-technical-skills';
import { GlobalService } from '../../global.service';
import { AuthService } from '../../base/auth.service';
import { Constants } from '../../constants';
import * as _ from 'lodash';
import { MatChipListbox, MatChip } from '@angular/material/chips';

@Component({
  selector: 'lss-technical',
  templateUrl: './technical.component.html',
  styleUrls: ['./technical.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('itemEnter', [
      transition(':enter', [useAnimation(enterAnimation)]),
      transition(':leave', [useAnimation(leaveAnimation)]),
    ]),
    trigger('blockInitialRenderAnimation', [transition(':enter', [])]),
    trigger('skillAdded', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('300ms ease-in'),
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class TechnicalComponent extends BaseListCrudComponent<
  IEmployeeCv,
  ITechnicalSkill
> {
  @ViewChildren(MatChip) chips: QueryList<MatChip>;
  @ViewChild(MatChipListbox) chipList: MatChipListbox;

  newItem = (model?: ITechnicalSkill) => newSkillItemControl(model);

  willingToUseOptions = willingToUseOptions;
  candidate = Constants.UserRoles.candidate;

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    protected activatedRoute: ActivatedRoute,
    protected confirmationService: ConfirmationService,
    protected cd: ChangeDetectorRef,
    protected animationBuilder: AnimationBuilder,
    public dialog: MatDialog,
    protected crudUtilService: ListCrudUtilService,
    protected globalService: GlobalService,
    protected authService: AuthService,
  ) {
    super(
      dataService,
      activatedRoute,
      confirmationService,
      cd,
      animationBuilder,
      crudUtilService,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  placeholderSkill: FormGroup;
  desiredSkill = new FormControl();

  form: FormArray;

  proficiencyOptions: ILookupValue[];
  skills: ILookupValue[];
  skillGroups: IGroupableLookupValue[] = [];
  alreadySelectedDesiredSkillsProvider$ = new BehaviorSubject([]);

  // to display in chips
  alreadySelectedDesiredSkills$ =
    this.alreadySelectedDesiredSkillsProvider$.pipe(
      debounceTime(0),
      tap(() => this.desiredSkill.setValue(null)),
      takeUntil(this.destroyed$),
    );

  // watch for desired skills
  // selected skills and it's duplicates should be filtered out from autocomplete options list
  desiredSkillsWithDupes$ = this.alreadySelectedDesiredSkillsProvider$.pipe(
    map((selectedSkills) => {
      // return desired skill together with it's duplicates if any
      const result = selectedSkills.reduce((accumulator, currentSkill) => {
        const skillAndDupes = this.skills.filter(
          (skill) => skill.displayName === currentSkill.displayName,
        );
        return [...accumulator, ...skillAndDupes];
      }, []);
      return result;
    }),
    takeUntil(this.destroyed$),
  );

  skillList: ILookupValue[];
  deletedSkillList: ILookupValue[];
  newOtherSkills: ILookupValue[] = [];
  initSkillsToLearnList: ILookupValue<string>[];

  desiredSkillChanged = false;
  techSkillsChanged = false;

  pending = false;

  onDataReady = function (cv: IEmployeeCv): any {
    // TODO: turn array to key: Object dictionary
    const technicalSkills = cv?.technicalSkills || [];

    const lookupData =
      (this.dataService.lookupData$?.getValue() as ILookupData) ||
      ({} as ILookupData);
    lookupData.willingToUse = this.willingToUseOptions;
    this.form = new FormArray(
      technicalSkills.map((e) => newSkillItemControl(e, lookupData)),
    );

    this.proficiencyOptions = lookupData?.skillProficiencies;

    this.skills = lookupData?.skills || [];

    this.skillGroups = [];
    lookupData?.skillGroups?.forEach((skillGroup: IGroupableLookupValue) => {
      if (skillGroup) {
        skillGroup.groupName = skillGroup.groupName?.toUpperCase() || '';
        this.skillGroups.push(skillGroup);
      }
    });

    this.alreadySelectedSkills$ = this.form.valueChanges.pipe(
      startWith(this.form.getRawValue()),
      map((e) =>
        Object.values(e)
          .map((ee) => ee.skill)
          .filter((ee) => !!ee),
      ),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
      takeUntil(this.destroyed$),
    );

    this.initDesiredSkills(cv);

    //Converting lastUsed from object to int so that form values can be compared to check for changes
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((val) => {
      val.map((element) => {
        if (typeof element.lastUsed === 'object' && !isNil(element.lastUsed)) {
          element.lastUsed = parseInt(element.lastUsed.displayName);
        }
      });
    });

    if (
      !this.pending &&
      this.activatedRoute.snapshot.queryParams['highlight-missing-inputs']
    ) {
      this.pending = true;
      window.setTimeout(async () => {
        this.globalService.checkAndFocusInvalidFields(this.form);
        this.pending = false;
      }, 500);
    }
  }.bind(this);

  bothArraysAreSame(first_array_of_objects, second_array_of_objects): boolean {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some((element_2) =>
          _.isEqual(element_1, element_2),
        ),
      )
    );
  }

  initDesiredSkills(cv: IEmployeeCv): void {
    const existingSkills = this.dataService?.lookupData$?.getValue()?.skills;
    const skillsToLearn = cv?.skillsToLearn || [];
    const skillsToLearnList = skillsToLearn.map((skill) =>
      existingSkills.find((ee) => ee.key === skill.key),
    );

    this.initSkillsToLearnList = skillsToLearnList;
    if (skillsToLearnList?.length) {
      this.alreadySelectedDesiredSkillsProvider$.next(skillsToLearnList);
    }
  }

  public addedSkillsExist(): boolean {
    //this.cd.detectChanges();
    return !isNil(this.form);
  }

  processUpdateModel = (model: IUpdateModel<IEmployeeCv>): Observable<any> => {
    if (model.skills?.length) {
      const skills: ILookupSkillRequestData[] = model.skills.map((item) => ({
        displayName: item.displayName,
        categoryKey: item.group,
      }));
      const update$ = this.dataService
        .saveLookups({
          Items: { [TechnicalSkillsConstants.Lookup.SkillsKey]: skills },
          FirstName: model.cv.personalInfo.firstName,
          LastName: model.cv.personalInfo.lastName,
          Email: model.cv.contactInfo.workEmail,
        })
        .pipe(
          tap(() => {
            const lookupData = this.dataService.lookupData$.getValue();
            const existingSkills = lookupData.skills.filter((x) =>
              model.skills.some((y) => y.key === x.key),
            );

            if (existingSkills.length === 0) {
              lookupData.skills = lookupData.skills.concat(model.skills);
            } else if (existingSkills.length !== model.skills.length) {
              const skillsToAdd = model.skills.filter(
                (x) => !existingSkills.some((r) => r.key === x.key),
              );
              lookupData.skills = lookupData.skills.concat(skillsToAdd);
            }

            this.dataService.regroupLookups();
          }),
          takeUntil(this.destroyed$),
        );

      return update$;
    }

    return of(null);
  };

  getUpdateModel(): IUpdateModel<IEmployeeCv> {
    return getTechnicalSkillsUpdateModel(
      this.form,
      this.dataService,
      this.newOtherSkills,
      this.placeholderSkill?.pristine,
      this.placeholderSkill,
      () => {
        // LSS-1632
        // if new skill added in form and selected in desired skill at once
        // combine lookupData with new ones to avoid duplicate skill creation
        const mergedLookupData = [
          ...this.dataService.lookupData$.getValue().skills,
          ...this.newOtherSkills,
        ];
        return this.alreadySelectedDesiredSkillsProvider$
          .getValue()
          .filter((e) => !!e)
          .map((e) =>
            ensureLookupOrAssignOtherByDisplayName(
              e,
              this.newOtherSkills,
              mergedLookupData,
              TechnicalSkillsConstants.TechnicalSkills.OtherGroup,
            ),
          )
          .map(getSkillRequestData);
      },
    );
  }

  handleTechnicalCard() {}

  addDesiredSkill(): void {
    if (!this.desiredSkill?.value) {
      return;
    }

    let newSkillToAdd: ILookupValue;

    if (typeof this.desiredSkill.value === 'string') {
      newSkillToAdd = {
        key: this.desiredSkill.value.toLowerCase(),
        displayName: this.desiredSkill.value,
        group: TechnicalSkillsConstants.TechnicalSkills.OtherGroup,
      };
    } else {
      newSkillToAdd = this.desiredSkill.value;
    }

    const desiredSkills = this.alreadySelectedDesiredSkillsProvider$.getValue();

    if (desiredSkills.find((item) => item.key === newSkillToAdd.key)) {
      this.desiredSkill.setValue(null);
      return;
    }

    this.alreadySelectedDesiredSkillsProvider$.next([
      ...desiredSkills,
      newSkillToAdd,
    ]);

    this.desiredSkillChanged = !this.bothArraysAreSame(
      this.initSkillsToLearnList,
      [...desiredSkills, newSkillToAdd],
    );

    this.desiredSkillChanged || this.techSkillsChanged
      ? this.form.markAsDirty()
      : this.form.markAsPristine();
  }

  removeDesiredSkill(option: ILookupValue): void {
    let desiredSkills = this.alreadySelectedDesiredSkillsProvider$
      .getValue()
      .filter((e) => !!e);
    const chipIndex = desiredSkills.indexOf(option);
    desiredSkills = desiredSkills.filter(
      ({ displayName }) => displayName !== option?.displayName,
    );
    this.alreadySelectedDesiredSkillsProvider$.next([...desiredSkills]);

    this.desiredSkillChanged = !this.bothArraysAreSame(
      this.initSkillsToLearnList,
      [...desiredSkills],
    );
    this.desiredSkillChanged || this.techSkillsChanged
      ? this.form.markAsDirty()
      : this.form.markAsPristine();
    this.focusPreviousChip(chipIndex);
  }

  focusPreviousChip(index: number) {
    const previousIndex = index > 0 ? index - 1 : 0;
    const chipsArray = this.chips.toArray();
    if (chipsArray.length > previousIndex) {
      chipsArray[previousIndex].focus();
    }
  }

  submitToHR(): void {
    this.submit(true).subscribe(() => this.dataService.submitToHR());
  }

  formHasChanged(hasChange: boolean): void {
    this.techSkillsChanged = hasChange;
  }
}

export const getTechnicalSkillsUpdateModel = (
  form: FormArray,
  dataService: DataService,
  newOtherSkills: ILookupValue[],
  placeholderSkillPrestine: boolean,
  placeholderSkill: FormGroup,
  alreadySelectedDesiredSkills: () => any,
) => {
  const existingSkills = dataService.lookupData$.getValue().skills;
  //if placeholder exists and is pristine it should be excluded
  let controls: AbstractControl[];
  if (placeholderSkillPrestine ?? false) {
    controls = form.controls.filter((control) => control !== placeholderSkill);
  } else {
    controls = form.controls;
  }

  const technicalSkills = controls
    .filter((control) => control.value.skill) // if some corrupted data exist (no skill) -> ignore it and save others
    .map((e) => {
      const {
        lastUsed,
        comments,
        yearsOfExperience,
        willingToUse,
        skillProficiency,
      } = e.value;
      const skill = e.value.skill as ILookupValue;
      const lookupSkill = ensureLookupOrAssignOtherByDisplayName(
        skill,
        newOtherSkills,
        existingSkills,
        skill.group,
      );

      return {
        lastUsed: getLastUsedValue(lastUsed),
        comments,
        yearsOfExperience,
        willingToUse: willingToUse?.key === 'yes',
        skill: tryGetLookupDisplayName(lookupSkill),
        skillProficiency: tryGetLookupKey(skillProficiency),
      };
    });

  const skillsToLearn = alreadySelectedDesiredSkills();

  const result: any = {
    cv: {
      technicalSkills,
      skillsToLearn,
    },
  };

  if (newOtherSkills.length) {
    result.skills = newOtherSkills;
  }

  return result;
};

export const getLastUsedValue = (lastUsed) => {
  if (typeof lastUsed === 'string') {
    lastUsed = parseInt(lastUsed);
  }

  if (typeof lastUsed === 'object' && !isNil(lastUsed)) {
    lastUsed = parseInt(lastUsed.displayName);
  }
  return lastUsed;
};

export function addTechnicalSkillValidators(
  technicalSkill: FormGroup,
  minYear,
  maxYear,
  maxYearsOfExp,
) {
  technicalSkill.controls.skill.setValidators([Validators.required]);
  technicalSkill.controls.lastUsed.setValidators([
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(4),
    Validators.min(minYear),
    Validators.max(maxYear),
    LssValidators.isNumber,
  ]);
  technicalSkill.controls.yearsOfExperience.setValidators([
    Validators.required,
    Validators.max(maxYearsOfExp),
    Validators.min(0),
  ]);
  technicalSkill.controls.skillProficiency.setValidators([Validators.required]);
}
