import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { Constants } from 'src/lss/src/app/constants';

@Component({
  standalone: true,
  selector: 'lss-tips',
  template: `
    <div class="tips-container">
      <div class="tips">
        <mat-icon>wb_incandescent</mat-icon>
        <a (click)="readTipsAndTricks()"> Click here to see some tips</a>
      </div>
    </div>
  `,
  styleUrls: ['./tips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule],
})
export class TipsComponent implements OnInit {
  @Input() pageBlock: string | null;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  readTipsAndTricks(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([Constants.Routes.TipsAndTricks], {
        queryParams: { pageBlock: this.pageBlock },
      }),
    );
    window.open(url, '_blank');
  }
}
