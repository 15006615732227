import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'lss-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfServiceComponent implements OnDestroy {
  destroyed$ = new Subject();
  emailAddress: string = 'info@28stone.com';

  constructor() {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
