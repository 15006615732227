import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { NativeDateAdapter } from '@angular/material/core';
import { Constants } from 'src/lss/src/app/constants';
import moment from 'moment';

@Injectable()
export class DatePickerDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }

  format(date: Date, _displayFormat: object): string {
    return formatDate(date, Constants.Date.FullDateFormat, this.locale);
  }

  parse(value: any, _parseFormat?: any): Date {
    return moment(value, Constants.Date.DatePickerParseFormat).toDate();
  }
}
