import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IPeriod } from '@lss/lss-types';
import { isNil } from '../../utils';
import { UiConstants } from '../../ui-constants';

@Component({
  selector: 'lss-range-picker-popup',
  templateUrl: './range-picker-popup.component.html',
  styleUrls: ['./range-picker-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangePickerPopupComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;

  private valueChangeProvider$ = new Subject<IPeriod>();
  destroyed$ = new Subject();
  valueChanges$ = this.valueChangeProvider$.asObservable();

  @Input() data: any;
  @Input() allowPresent = true;
  @Input() endDateOptional: boolean;
  @Input() futureYears = 0;
  @Input() minStartYear: number;
  @ViewChild('startYear') startYear: MatSelect;
  @ViewChild('endYear') endYear: MatSelect;

  months = [...UiConstants.Date.Months];

  yearOptions: Array<number>;
  yearsLength: number;
  maxYear: number;
  minYear: number;

  endYearOptions: Array<number>;
  endYearsLength: number;
  maxEndYear: number;
  minEndYear: number;

  constructor(
    protected elementRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();

    this.yearsLength = currentYear - this.minStartYear + 1;
    this.yearOptions = Array(this.yearsLength)
      .fill(0)
      .map((_, idx) => currentYear - this.yearsLength + 1 + idx)
      .reverse();
    this.maxYear = this.yearOptions[0];
    this.minYear = this.yearOptions[this.yearsLength - 1];

    this.endYearsLength = this.yearsLength + this.futureYears;
    this.endYearOptions = Array(this.endYearsLength)
      .fill(0)
      .map(
        (_, idx) =>
          currentYear + this.futureYears - this.endYearsLength + 1 + idx,
      )
      .reverse();
    this.maxEndYear = this.endYearOptions[0];
    this.minEndYear = this.endYearOptions[this.endYearsLength - 1];

    const month = new Date().getMonth();
    this.formGroup = new FormGroup({
      startYear: new FormControl(this.data?.startYear || this.yearOptions[0]),
      endYear: new FormControl(
        this.data?.endYear || this.endYearOptions[this.futureYears],
      ),
      present: new FormControl(this.data?.present || false),
      endMonth: new FormControl(
        !isNil(this.data?.endMonth)
          ? this.data?.endMonth
          : this.endDateOptional
          ? null
          : month,
      ),
      startMonth: new FormControl(
        !isNil(this.data?.startMonth) ? this.data?.startMonth : month,
      ),
    });

    this.disableEndSelection();

    this.formGroup.valueChanges
      .pipe(
        tap((e) => this.valueChangeProvider$.next(e)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setEndYear(year): void {
    if (year <= this.maxEndYear && year >= this.minEndYear) {
      this.formGroup.controls.endYear.setValue(year);
    }
  }

  setStartYear(year): void {
    if (year <= this.maxYear && year >= this.minYear) {
      this.formGroup.controls.startYear.setValue(year);
    }
  }

  setEndMonth(month): void {
    if (
      this.endDateOptional &&
      this.formGroup.controls.endMonth.value === month
    ) {
      this.formGroup.controls.endMonth.setValue(null);
    } else {
      this.formGroup.controls.endMonth.setValue(month);
    }
  }

  setStartMonth(month): void {
    this.formGroup.controls.startMonth.setValue(month);
  }

  checkMonthDisabled(month: number, type: string): boolean {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    if (this.formGroup.controls.startYear.value > this.maxYear) {
      return true;
    }
    if (
      type === 'endMonth' &&
      this.formGroup.controls.endYear.value > this.maxEndYear
    ) {
      return true;
    }

    if (this.futureYears > 0 && type === 'endMonth') {
      return false;
    }

    if (type === 'endMonth' && this.formGroup.controls.present.value) {
      return true;
    }

    if (
      (type === 'endMonth' &&
        this.formGroup.controls.endYear.value === currentYear) ||
      (type === 'startMonth' &&
        this.formGroup.controls.startYear.value === currentYear)
    ) {
      return currentMonth + 1 <= month;
    }
    return false;
  }

  disableEndSelection() {
    if (this.formGroup.controls.present.value) {
      this.formGroup.controls.endYear.disable();
    } else {
      this.formGroup.controls.endYear.enable();
    }
  }
}
