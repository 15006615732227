<div [id]="id">
  <!-- invisible placeholders to populate width -->
  <!-- renders selectedValues items spans sequentially in row -->
  <!-- one -->
  <!-- one, two -->
  <!-- one, two, three -->
  <!-- etc -->
  <span
    *ngFor="let value of selectedValues; let count = index"
    [ngClass]="placeHolderClass"
    >{{ count | itemsPortionPipe : selectedValues }}</span
  >
  <!-- and in the code you can check when expanded spans width exceeds maximum width of select  -->

  <mat-form-field appearance="outline">
    <mat-select
      #selectInput
      panelClass="autocomplete-search-panel"
      [placeholder]="placeholder"
      [formControl]="formControl"
      [multiple]="multiple"
      [compareWith]="compareFn"
      (openedChange)="onOpenedChange($event)"
    >
      <mat-select-trigger>
        {{ truncatedValue }}
      </mat-select-trigger>

      <div class="autocomplete-search-input-container">
        <input
          #searchInput
          [(ngModel)]="searchValue"
          type="text"
          class="autocomplete-search-input"
          (input)="hideOptions()"
          [placeholder]="selectPlaceholder"
        />
      </div>

      <div class="autocomplete-search-options-container">
        <mat-option
          *ngFor="let option of filtrableOptions | keyvalue : originalOrder"
          [value]="option.value"
          [class.hide]="option.value.hide"
        >
          {{ option.value.displayName }}
        </mat-option>
      </div>
    </mat-select>

    <mat-error *ngIf="!hideError">Field is mandatory</mat-error>
  </mat-form-field>
</div>
