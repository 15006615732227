import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProgressService } from '@lss/lss-ui';
import { Subject, Subscription, throwError, timer } from 'rxjs';
import { AuthService } from '../../base/auth.service';
import { DataService } from '../../base/data.service';
import {
  DATA_SERVICE_TOKEN,
  IEmployeeCv,
  ILookupData,
  ImportStatus,
  IScrapingRespose,
  IUpdateModel,
  LinkedInUpdateResponse,
} from '@lss/lss-types';
import { newSkillItemControl } from '@lss/lss-technical-skills';
import { Constants } from '../../constants';
import { Router } from '@angular/router';
import {
  getWorkExperienceUpdateModel,
  newExperienceItemControl,
} from '../work/work.component';
import {
  getEducationUpdateModel,
  newEducationItemControl,
} from '../education/education.component';
import {
  getLanguagesUpdateModel,
  newLanguageItemControl,
} from '../languages/languages.component';
import {
  getCertificationUpdateModel,
  newCertificationItemControl,
} from '../certification/certification.component';
import { updateValueAndValidityRecursively } from '../../utils';
import { submit_ } from 'src/lss-ui/src/lib/form/base.component';
import { getTechnicalSkillsUpdateModel } from '../technical/technical.component';
import {
  catchError,
  delayWhen,
  map,
  repeat,
  takeWhile,
  finalize,
} from 'rxjs/operators';

@Component({
  selector: 'lss-import-linkedin-modal',
  templateUrl: './import-linkedin-modal.component.html',
  styleUrls: ['./import-linkedin-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportLinkedinModalComponent implements OnInit {
  linkedInUrl = new FormControl('', [Validators.required]);
  ImportStatus = ImportStatus;
  currentStatus = ImportStatus.default;
  cancelButtonText: string = 'Cancel';
  authenticatedUser = null;
  userEmail = null;
  scrapingStatusSubscription: Subscription;
  scrapingRequestSubscription: Subscription;
  updatedCv: IEmployeeCv;
  form: FormArray;
  missingDataPageText: string = null;

  constructor(
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ImportLinkedinModalComponent>,
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    public authService: AuthService,
    public progressService: ProgressService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authenticatedUser = this.authService.authenticatedUser$.getValue();
    this.userEmail = this.authenticatedUser.email;
  }

  getUpdateModel(): IUpdateModel<IEmployeeCv> {
    return { cv: this.updatedCv };
  }

  handleDialogImportAction(): void {
    this.currentStatus = ImportStatus.uploading;
    this.tryScrapeAction();
  }

  tryScrapeAction(): void {
    this.missingDataPageText = null;

    this.scrapingRequestSubscription = this.dataService
      .scrapeLinkedIn({
        Email: this.userEmail,
        LinkedInUrl: this.linkedInUrl.value,
      })
      .subscribe(
        (response) => {
          this.cancelButtonText = 'Close';
          this.progressService.showLoader();

          this.scrapingStatusSubscription = this.dataService
            .getScrapingStatus({ email: this.userEmail })
            .pipe(
              delayWhen(() => timer(6000)),
              repeat(30),
              takeWhile((res: IScrapingRespose) => {
                return res.status !== LinkedInUpdateResponse.OK;
              }, true),
              map((res) => res.data),
              catchError((err) => {
                if (err) {
                  if (err.status === 404) {
                    this.currentStatus = ImportStatus.noAccountFound;
                    this.progressService.hideLoader();
                    this.cd.markForCheck();
                    return throwError(err);
                  }
                  this.currentStatus = ImportStatus.noInfoFound;
                  this.progressService.hideLoader();
                  this.cd.markForCheck();
                  return throwError(err);
                }
              }),
              finalize(() => {
                if (this.currentStatus === ImportStatus.uploading) {
                  this.currentStatus = ImportStatus.importFailed;
                  this.progressService.hideLoader();
                  this.cd.markForCheck();
                }
              }),
            )
            .subscribe((res) => {
              if (res) {
                this.updatedCv = res;
                this.dataService.cv$.next(res);

                this.currentStatus = ImportStatus.importSuccessful;
                this.progressService.hideLoader();
                this.cd.markForCheck();

                this.validateAllTabsAndSaveTheValidOnes(res);
              }
            });
        },
        (err) => {
          this.currentStatus = ImportStatus.importFailed;
          this.cd.markForCheck();
        },
      );
  }

  validateAllTabsAndSaveTheValidOnes(res: any) {
    let tabValid = true;
    let firstInvalidTabPath: string = null;
    let firstInvalidTabTitle: string = null;

    const workExperienceForm = new FormArray(
      (res.workExperience ?? []).map((e) => newExperienceItemControl(e)),
    );
    tabValid = this.validateTab(res, 'workExperience', workExperienceForm);
    if (!tabValid) {
      if (firstInvalidTabPath === null) {
        firstInvalidTabPath = Constants.Routes.CvWorkExperience;
        firstInvalidTabTitle = 'Work Experience';
      }
    } else {
      const updateModel = getWorkExperienceUpdateModel(workExperienceForm);
      submit_(
        updateModel,
        this.dataService,
        new Subject(),
        new Subject(),
        true,
      );
    }
    const educationForm = new FormArray(
      (res.education ?? []).map((e) => newEducationItemControl(e)),
    );
    tabValid = this.validateTab(res, 'education', educationForm);
    if (!tabValid) {
      if (firstInvalidTabPath === null) {
        firstInvalidTabPath = Constants.Routes.CvEducation;
        firstInvalidTabTitle = 'Education';
        this.dataService.dataInvalid$.next(true);
      }
    } else {
      const updateModel = getEducationUpdateModel(educationForm);
      submit_(
        updateModel,
        this.dataService,
        new Subject(),
        new Subject(),
        true,
      );
    }
    const lookupData = this.dataService.lookupData$.getValue() as ILookupData;
    const technicalSkillsForm = new FormArray(
      (res.technicalSkills ?? []).map((e) =>
        newSkillItemControl(e, lookupData),
      ),
    );
    tabValid = this.validateTab(res, 'technicalSkills', technicalSkillsForm);
    if (!tabValid) {
      if (firstInvalidTabPath === null) {
        firstInvalidTabPath = Constants.Routes.CvTechnicalSkills;
        firstInvalidTabTitle = 'Technical Skills';
      }
    } else {
      const updateModel = getTechnicalSkillsUpdateModel(
        technicalSkillsForm,
        this.dataService,
        [],
        false,
        null,
        () => [],
      );
      submit_(
        updateModel,
        this.dataService,
        new Subject(),
        new Subject(),
        true,
      );
    }
    const languageOptions = this.dataService.getLanguageOptions();

    const languageProficienciesForm = new FormArray(
      (res.languageProficiencies ?? []).map((e) =>
        newLanguageItemControl(languageOptions, e, lookupData),
      ),
    );
    tabValid = this.validateTab(
      res,
      'languageProficiencies',
      languageProficienciesForm,
    );
    if (!tabValid) {
      if (firstInvalidTabPath === null) {
        firstInvalidTabPath = Constants.Routes.CvLanguages;
        firstInvalidTabTitle = 'Languages';
      }
    } else {
      const updateModel = getLanguagesUpdateModel(
        languageProficienciesForm,
        languageOptions,
      );
      submit_(
        updateModel,
        this.dataService,
        new Subject(),
        new Subject(),
        true,
      );
    }
    const certificateForm = new FormArray(
      (res.certifications ?? []).map((e) => newCertificationItemControl(e)),
    );

    tabValid = this.validateTab(res, 'certifications', certificateForm);

    if (!tabValid) {
      if (firstInvalidTabPath === null) {
        firstInvalidTabPath = Constants.Routes.CvCertification;
        firstInvalidTabTitle = 'Certification';
      }
    } else {
      const updateModel = getCertificationUpdateModel(
        certificateForm,
        res.additionalInfo,
      );
      submit_(
        updateModel,
        this.dataService,
        new Subject(),
        new Subject(),
        true,
      );
    }
    if (firstInvalidTabPath !== null) {
      this.missingDataPageText = `Some fields in ${firstInvalidTabTitle} were not populated but are mandatory.`;

      this.router.navigate([firstInvalidTabPath], {
        queryParams: { 'highlight-missing-inputs': 'true' },
      });
    }
  }

  validateTab(
    res: any,
    cvAttributeName: string,
    formArray: FormArray,
  ): boolean {
    if (
      !res ||
      !res[cvAttributeName] ||
      !res[cvAttributeName].length ||
      !formArray ||
      !formArray.length
    ) {
      return true;
    }

    updateValueAndValidityRecursively(formArray);

    return formArray.valid;
  }

  handleDialogCancelAction(): void {
    this.scrapingRequestSubscription?.unsubscribe();
    this.scrapingStatusSubscription?.unsubscribe();
    this.progressService.hideLoader(true);
    this.dialogRef.close({ action: 'close' });
  }

  isFormValid(): boolean {
    return (
      !this.linkedInUrl.errors &&
      this.linkedInUrl.value.includes(Constants.LinkedIn.ProfileUrlPrefix)
    );
  }
}
