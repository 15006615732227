import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../base/auth.service';
import { Constants } from '../constants';

@Injectable()
export class HomeGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate = async (): Promise<boolean> => {
    try {
      const canSearch = await this.authService.userCanPerformAction(Constants.RoleActions.canSearch)
      if (!canSearch) {
        this.router.navigate([Constants.Routes.Cv]);
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
}
