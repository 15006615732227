<div class="notFoundContainer">
  <div class="background-paralax">
    <img
      class="bg-layer"
      [ngStyle]="{
        left: 'calc(-3% - ' + xShiftValue * LAYER1_PARALLAX_SPEED + 'px)'
      }"
      src="/assets/images/BGmini.jpg"
    />
    <img
      class="bg-layer"
      [ngStyle]="{
        left: 'calc(-3% - ' + xShiftValue * LAYER2_PARALLAX_SPEED + 'px)'
      }"
      src="/assets/images/Error404-mini.png"
    />
    <img
      class="bg-layer"
      [ngStyle]="{
        left: 'calc(-3% - ' + xShiftValue * LAYER3_PARALLAX_SPEED + 'px)'
      }"
      src="/assets/images/Stones-mini.png"
    />
  </div>
  <div class="not-found-content">
    <img class="skillset-icon" src="/assets/svg/lithos-vector-01.svg" />
    <div class="not-found-message">
      <div>It seems such a page doesn’t exist!</div>
      <lss-button type="button" (action)="goHome()" class="go-home-button">
        Get Back Home
      </lss-button>
    </div>
  </div>
</div>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
