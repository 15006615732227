import {
  Component,
  Input,
  EventEmitter,
  ChangeDetectionStrategy,
  Output,
  ElementRef,
  Renderer2,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'lss-button',
  template: `
    <button
      mat-flat-button
      [ngClass]="matButtonType"
      color="{{ disabled ? 'disabled' : color }}"
      (click)="action.emit($event)"
      [disabled]="disabled"
      [attr.type]="type"
      class="color-{{ color }} {{ class }}"
      [attr.id]="id ? id : null"
      [class.mat-icon-button]="color === 'icon'"
      [class.color-dark]="color === 'dark'"
      [class.has-content]="content.children.length"
    >
      <span #content>
        <ng-content></ng-content>
      </span>
    </button>
  `,
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() type?: string;
  @Input() matButtonType?:
    | 'mat-button'
    | 'mat-raised-button'
    | 'mat-flat-button'
    | 'mat-stroked-button'
    | 'mat-icon-button'
    | 'mat-fab'
    | 'mat-mini-fab';
  @Input() color:
    | 'primary'
    | 'secondary'
    | 'basic-primary'
    | 'basic-secondary'
    | 'link-primary'
    | 'link'
    | 'basic'
    | 'icon'
    | 'dark' = 'primary';
  @Input() id?: string;
  @Input() class?: string;
  @Output() action = new EventEmitter();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    // Since default button type is "mat-flat-button" then it overrides
    // default one ("mat-button"). For this case we are removing "mat-flat-button"
    // class from button element because "mat-button" class will be applied. Therefore
    // we can get correct styling for button.
    // NOTE: Its still a really hacky solution:
    // https://github.com/angular/components/issues/15367#issuecomment-674452315
    if (this.matButtonType === 'mat-button') {
      this.renderer.removeClass(
        this.elementRef.nativeElement.firstChild,
        'mat-flat-button',
      );
    }
  }
}
