import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterCandidateComponent } from './register-candidate.component';
import { ButtonModule, RenderingModule, TextModule } from '@lss/lss-ui';
import { RouterModule } from '@angular/router';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggedActionsModule } from '../logged-user-actions/logged-actions.module';
import { CanDeactivateGuardService } from './can-deactivate.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: RegisterCandidateComponent,
        canDeactivate: [CanDeactivateGuardService],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class RegisterCandidateRoutingModule {}

@NgModule({
  declarations: [RegisterCandidateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RegisterCandidateRoutingModule,
    RenderingModule,
    LoggedActionsModule,
    FormsModule,
    MatCardModule,
    TextModule,
  ],
  exports: [RegisterCandidateComponent],
})
export class RegisterCandidateModule {}
