import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { HttpClientModule } from '@angular/common/http';
import { Amplify } from 'aws-amplify';
import { BackToTopComponent } from './base/back-to-top/back-to-top.component';
import { SearchGuardComponent } from './search/search-guard.component';
import { ErrorHandlerService } from './error/error-handler.service';
import { Constants } from './constants';
import { CommonModule } from '@angular/common';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { NotificationComponent } from './base/notification/notification.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ERROR_SERVICE_TOKEN, DATA_SERVICE_TOKEN } from '@lss/lss-types';
import {
  ConfirmationModule,
  IconModule,
  ProgressService,
  ToolbarModule,
  ButtonModule,
} from '@lss/lss-ui';
import { ErrorService } from './error/error.service';
import { DataService } from './base/data.service';
import { LoggedActionsModule } from './logged-user-actions/logged-actions.module';
import { PageNotFoundModule } from './base/page-not-found/not-found.module';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { GlobalService } from './global.service';
import { SetPasswordModule } from './set-password/set-password.module';
import { RegisterCandidateModule } from './register-candidate/register-candidate.module';
import { TermsOfServiceComponent } from './company-policies/terms-of-service/terms-of-service.component';
import { MatCardModule } from '@angular/material/card';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { TipsAndTricksComponent } from './base/tips-and-tricks/tips-and-tricks/tips-and-tricks.component';
import { PrivacyPolicyComponent } from './company-policies/privacy-policy/privacy-policy.component';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolClientId,
    oauth: {
      domain: environment.oauthDomain,
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: environment.oauthRedirectSignIn,
      redirectSignOut: environment.oauthRedirectSignOut,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: environment.apiName,
        endpoint: environment.endpoint,
      },
      {
        name: environment.apiName + 'Public',
        endpoint: environment.publicEndpoint,
      },
    ],
  },
});

@NgModule({
  declarations: [
    AppComponent,
    SearchGuardComponent,
    NotificationComponent,
    TermsOfServiceComponent,
    TipsAndTricksComponent,
    PrivacyPolicyComponent,
    BackToTopComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    ConfirmationModule,
    BrowserAnimationsModule,
    ButtonModule,
    QuillModule.forRoot(),
    MatSnackBarModule,
    MatFormFieldModule,
    ToolbarModule,
    IconModule,
    LoggedActionsModule,
    SetPasswordModule,
    PageNotFoundModule,
    MatDialogModule,
    MatIconModule,
    RegisterCandidateModule,
    MatCardModule,
    ForgotPasswordModule,
    MatTooltipModule,
  ],
  providers: [
    {
      provide: Constants.Window.token,
      useValue: Constants.Window.window,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: ERROR_SERVICE_TOKEN,
      useClass: ErrorService,
    },
    {
      provide: DATA_SERVICE_TOKEN,
      useClass: DataService,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    ProgressService,
    GlobalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
