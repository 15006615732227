import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'lss-mover',
  template: `
    <ng-container *ngIf="canMoveUp || canMoveDown">
      <div
        [class.disabled]="!canMoveUp"
        (click)="moveUp.emit()"
        class="move move-up"
      >
        <lss-icon>arrow_drop_up</lss-icon>
      </div>
      <div
        [class.disabled]="!canMoveDown"
        (click)="moveDown.emit()"
        class="move move-down"
      >
        <lss-icon>arrow_drop_down</lss-icon>
      </div>
    </ng-container>
  `,
  styleUrls: ['./mover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoverComponent implements OnInit {
  @Input() canMoveUp = true;
  @Input() canMoveDown = true;

  @Output() moveUp = new EventEmitter();
  @Output() moveDown = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
