import {
  AlignmentType,
  BorderStyle,
  convertInchesToTwip,
  Document,
  ExternalHyperlink,
  Header,
  HeightRule,
  ImageRun,
  LevelFormat,
  NumberFormat,
  OverlapType,
  PageNumber,
  Paragraph,
  RelativeHorizontalPosition,
  Table,
  TableAnchorType,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from 'docx';
import { CapitalizeFirstLetter } from '../../utils';

export const LogoImagePath = './assets/images/28stone-logo.jpg';
export const LeftParagraphPadding = 1400;
export const RightParagraphPadding = 1400;
export const NumLinesOrangeColumn = 50;

export const NoBorder = {
  borders: {
    top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
    bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
    left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
    right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
  },
};

function CreateColorLine(hexColor: string): Paragraph {
  return new Paragraph({
    text: '',
    border: {
      top: {
        style: BorderStyle.SINGLE,
        size: 7,
        color: hexColor,
      },
    },
    indent: { left: LeftParagraphPadding, right: RightParagraphPadding },
  });
}

export const BlackLineTop = CreateColorLine('000000');
export const GreyLineTop = CreateColorLine('A6A6A6');
export const EmptyParagraph = new Paragraph('');
export const EmptyTextNewLine = new TextRun({ text: '', break: 1 });
export function NewText(obj): TextRun {
  return new TextRun(obj);
}
export function NewParagraph(obj): Paragraph {
  return new Paragraph(obj);
}

export async function BuildDocx({
  logo,
  cv,
  languagesList,
  educationList,
  certificationsList,
  skillsList,
  summarySection,
  workExperienceList,
}): Promise<Document> {
  const { firstName, lastName } = cv.personalInfo;
  const fullName = `${CapitalizeFirstLetter(firstName)} ${CapitalizeFirstLetter(
    lastName,
  )}`;

  return new Document({
    ...DocStyles,
    ...DocNumbering,
    sections: [
      {
        ...DocSectionsProperties,
        ...(await DocSectionsHeader(logo)),
        children: [
          OrangeTableColumn(
            skillsList,
            languagesList,
            educationList,
            certificationsList,
          ),
          EmptyParagraph,
          FullNameParagraph(fullName),
          EmailLinkParagraph(cv.contactInfo.workEmail),
          ...summarySection,
          ...workExperienceList,
        ],
      },
    ],
  });
}

export const DocStyles = {
  styles: {
    paragraphStyles: [
      {
        id: 'default',
        name: 'Normal',
        run: {
          font: 'Nunito',
          color: '000000',
          bold: false,
          size: 18,
        },
      },
    ],
    characterStyles: [
      {
        id: 'orangeStyle',
        name: 'orangeStyle',
        basedOn: 'Normal',
        run: {
          size: 28,
          bold: true,
          color: 'F05A28',
          italics: false,
        },
      },
      {
        id: 'companyNameStyle',
        name: 'companyNameStyle',
        basedOn: 'Normal',
        run: {
          size: 22,
          bold: true,
          color: '000000',
          italics: false,
        },
      },
      {
        id: 'roleTitleStyle',
        name: 'roleTitleStyle',
        basedOn: 'Normal',
        run: {
          size: 20,
          bold: false,
          color: '000000',
          italics: true,
        },
      },
      {
        id: 'companyPeriodStyle',
        name: 'companyPeriodStyle',
        basedOn: 'Normal',
        run: {
          size: 16,
          bold: false,
          color: '000000',
        },
      },
      {
        id: 'fullnameStyle',
        name: 'fullnameStyle',
        basedOn: 'Normal',
        run: {
          size: 52,
        },
      },
      {
        id: 'newParagraphTitleStyle',
        name: 'newParagraphTitleStyle',
        basedOn: 'Normal',
        run: {
          size: 28,
          bold: true,
          color: '000000',
        },
      },
      {
        id: 'boldStyle',
        name: 'boldStyle',
        basedOn: 'Normal',
        run: {
          bold: true,
        },
      },
    ],
  },
};

export const DocNumbering = {
  numbering: {
    config: [
      {
        levels: [
          {
            level: 0,
            format: LevelFormat.DECIMAL,
            text: '%1.',
            alignment: AlignmentType.START,
            style: {
              paragraph: {
                indent: {
                  left: convertInchesToTwip(0.5),
                  hanging: convertInchesToTwip(0.18),
                },
              },
            },
          },
        ],
        reference: 'numberingRef',
      },
    ],
  },
};

export const DocSectionsProperties = {
  properties: {
    page: {
      margin: {
        header: 350,
        top: 1600,
        right: 0,
        bottom: 1800,
        left: 0,
      },
      pageNumbers: {
        start: 1,
        formatType: NumberFormat.DECIMAL,
      },
    },
  },
};

export async function DocSectionsHeader(image): Promise<any> {
  return {
    headers: {
      default: new Header({
        children: [
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 200,
                      type: WidthType.DXA,
                    },
                    ...NoBorder,
                    children: [
                      new Paragraph({
                        children: [
                          new ImageRun({
                            data: image,
                            transformation: {
                              width: 138,
                              height: 54,
                            },
                          }),
                        ],
                        indent: { left: 300 },
                      }),
                    ],
                  }),
                  new TableCell({
                    margins: { right: 0 },
                    ...NoBorder,
                    children: [
                      new Paragraph({
                        spacing: {
                          before: 600,
                        },
                        border: {
                          top: {
                            style: BorderStyle.SINGLE,
                            size: 10,
                            color: 'FFC000',
                          },
                        },
                        indent: {
                          left: 300,
                        },
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    },
  };
}

export function BuildTableCompanyPeriod(period): Table {
  return new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            verticalAlign: VerticalAlign.CENTER,
            margins: {
              right: 0,
              left: 0,
              top: 20,
            },
            ...NoBorder,
            children: [period],
          }),
        ],
      }),
    ],
    float: {
      horizontalAnchor: TableAnchorType.MARGIN,
      verticalAnchor: TableAnchorType.TEXT,
      relativeHorizontalPosition: RelativeHorizontalPosition.RIGHT,
      rightFromText: 1000,
      bottomFromText: 0,
    },
    width: {
      size: 3000,
      type: WidthType.DXA,
    },
    layout: TableLayoutType.AUTOFIT,
  });
}

export function DegreeText(degree: string, period: string): TextRun {
  return period !== ''
    ? new TextRun({
        text: `${degree}, ${period}`,
        bold: true,
      })
    : new TextRun('');
}

export function EducationParagraph(
  degreePar: TextRun[],
  institution: string,
): Paragraph {
  return degreePar && institution !== ''
    ? new Paragraph({
        children: [
          ...degreePar,
          new TextRun({
            text: institution,
          }),
        ],
      })
    : null;
}

export function LanguageParagraph(
  language: string,
  proficiencyDescription: string,
): Paragraph {
  return new Paragraph({
    text: `${language} - ${proficiencyDescription}`,
  });
}

export function BulletedParagraph(txt: string): Paragraph {
  return new Paragraph({
    text: txt,
    bullet: {
      level: 0,
    },
    indent: { left: 350 },
    style: 'default',
  });
}

export function BulletedNumberedParagraph(txt: string, flagBullet): Paragraph {
  const bulletedOpt = {
    bullet: {
      level: 0,
    },
  };
  const numberedOpt = {
    numbering: {
      reference: 'numberingRef',
      level: 0,
    },
  };
  let opt;
  flagBullet === 'bullet' ? (opt = bulletedOpt) : (opt = numberedOpt);

  return new Paragraph({
    text: txt,
    ...opt,
    indent: { left: LeftParagraphPadding + 350, right: RightParagraphPadding },
    style: 'default',
  });
}

export function RoleParagraph(role: string): Paragraph {
  return new Paragraph({
    children: [
      new TextRun({
        text: role,
        style: 'roleTitleStyle',
      }),
    ],
    indent: { left: LeftParagraphPadding, right: RightParagraphPadding },
    alignment: AlignmentType.LEFT,
  });
}

export function CompanyNameParagraph(name: string): Paragraph {
  return new Paragraph({
    children: [
      new TextRun({
        text: name,
        style: 'companyNameStyle',
      }),
    ],
    indent: { left: LeftParagraphPadding, right: RightParagraphPadding },
    alignment: AlignmentType.LEFT,
  });
}

export function PeriodParagraph(txt: string, italic?: boolean): Paragraph {
  return new Paragraph({
    children: [
      new TextRun({
        text: txt,
        style: 'companyPeriodStyle',
        italics: italic,
      }),
    ],
    indent: { right: RightParagraphPadding },
    alignment: AlignmentType.RIGHT,
  });
}

export function FullNameParagraph(fullname: string): Paragraph {
  return new Paragraph({
    children: [
      new TextRun({
        text: fullname,
        style: 'fullnameStyle',
      }),
    ],
    indent: { left: LeftParagraphPadding, right: RightParagraphPadding },
  });
}

export function EmailLinkParagraph(email: string): Paragraph {
  return new Paragraph({
    children: [
      new ExternalHyperlink({
        children: [
          new TextRun({
            text: email,
            style: 'Hyperlink',
            size: 22,
          }),
        ],
        link: 'mailto:' + email,
      }),
    ],
    indent: { left: 350, right: 1000 },
  });
}

export function OrangeColumnSectionTitleParagraph(
  title: string,
  spaceBefore = true,
): Paragraph {
  const spaceBeforeObj = spaceBefore
    ? {
        spacing: {
          before: 300,
        },
      }
    : null;

  return new Paragraph({
    ...spaceBeforeObj,
    children: [
      new TextRun({
        text: title,
        style: 'orangeStyle',
      }),
    ],
  });
}

export const SummaryTitleParagraph: Paragraph = new Paragraph({
  children: [
    new TextRun({
      text: 'Summary',
      style: 'newParagraphTitleStyle',
    }),
  ],
  indent: { left: LeftParagraphPadding, right: RightParagraphPadding },
  spacing: {
    before: 300,
    after: 300,
  },
});

export const WorkExperienceTitleParagraph = new Paragraph({
  children: [
    new TextRun({
      text: 'Work Experience',
      style: 'newParagraphTitleStyle',
    }),
  ],
  indent: { left: LeftParagraphPadding, right: RightParagraphPadding },
  spacing: {
    before: 300,
    after: 150,
  },
});

export function OrangeTableColumn(
  skills: Paragraph[] = [],
  language: Paragraph[] = [],
  education: Paragraph[] = [],
  certifications: Paragraph[] = [],
): Table {
  return new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            ...NoBorder,
            margins: {
              top: 350,
              bottom: 0,
              left: 350,
              right: 350,
            },
            children: [...skills, ...language, ...education, ...certifications],
            columnSpan: 1,
            shading: {
              fill: 'FEF4F0',
            },
          }),
        ],
        height: {
          value: 15260,
          rule: HeightRule.EXACT,
        },
      }),
    ],
    float: {
      horizontalAnchor: TableAnchorType.MARGIN,
      verticalAnchor: TableAnchorType.MARGIN,
      absoluteVerticalPosition: 1000,
      absoluteHorizontalPosition: 300,
      overlap: OverlapType.NEVER,
      rightFromText: 350,
    },
    width: {
      size: 4100,
      type: WidthType.DXA,
    },
    layout: TableLayoutType.FIXED,
  });
}
