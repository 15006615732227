import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import {
  DATA_SERVICE_TOKEN,
  IGetCandidateDatadResult,
  SetPasswordItemResult,
} from '@lss/lss-types';
import { DataService } from '../base/data.service';
import { AuthService } from '../base/auth.service';
import { Constants } from '../constants';
import { ErrorStateMatcher } from '@angular/material/core';
import { take } from 'rxjs/operators';
import { NotificationService } from '../base/notification/notification.service';
import { enterApp } from '../base/login/login-utils';
import { ConfirmationService } from '@lss/lss-ui';

@Component({
  selector: 'lss-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  errorText: string;
  policy: boolean;
  personal: boolean;
  disableSubmit: boolean = false;
  hasUser$: Observable<boolean>;
  encryptedEmail: string;
  public form = new FormGroup({
    email: new FormControl(),
    fullname: new FormControl(),
    password: new FormControl(),
  });
  destroyed$ = new Subject();
  minLength = 8;
  maxLength = 50;
  isResetUrl: boolean = false;
  isLoaded$ = new Subject<boolean>();
  userRole: string;

  matcher = new PasswordErrorStateMatcher();

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    public notificationService: NotificationService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  readTerms(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([Constants.Routes.TermsOfService]),
    );
    window.open(url, '_blank');
  }

  readPrivacyPolicy(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([Constants.Routes.PrivacyPolicy]),
    );
    window.open(url, '_blank');
  }

  ngOnInit(): void {
    this.isLoaded$.next(false);

    this.authService
      .isAuthenticated()
      .then(async (e) => {
        e
          ? await enterApp(
              this.dataService,
              this.authService,
              this.router,
              this.confirmationService,
            )
          : false;
      })
      .catch(() => this.cd.markForCheck());

    this.route.queryParams.subscribe((params: { url: string }) => {
      this.encryptedEmail = encodeURIComponent(params.url);

      this.dataService
        .getCandidateData({
          url: this.encryptedEmail,
        })
        .subscribe({
          next: (res: IGetCandidateDatadResult) => {
            if (res) {
              const data = Object.values(res);
              const hasPasswordUrl = data.some(
                (d) =>
                  d.name === SetPasswordItemResult.SET_PASSWORD_URL ||
                  d.name === SetPasswordItemResult.RESET_URL,
              );
              if (!hasPasswordUrl) {
                this.router.navigate([Constants.Routes.Login]);
              }
              let givenName = '';
              let familyName = '';
              data.forEach((item: IGetCandidateDatadResult) => {
                switch (item.name) {
                  case SetPasswordItemResult.GIVEN_NAME:
                    givenName =
                      item.value.charAt(0).toUpperCase() + item.value.slice(1);
                    break;
                  case SetPasswordItemResult.FAMILY_NAME:
                    familyName =
                      item.value.charAt(0).toUpperCase() + item.value.slice(1);
                    break;
                  case SetPasswordItemResult.EMAIL:
                    this.form.controls.email.setValue(item.value);
                    break;
                  case SetPasswordItemResult.RESET_URL:
                    this.isResetUrl = true;
                    break;
                  case SetPasswordItemResult.ROLE:
                    this.userRole = item.value;
                    break;
                }
                this.form.controls.fullname.setValue(
                  givenName + ' ' + familyName,
                );
              });
              this.isLoaded$.next(true);
            }
          },
          error: (error) => {
            this.errorText = error?.errorMessage;
            this.router.navigate([Constants.Routes.ForgotPassword], {
              queryParams: { error: '500 from aws' },
            });
          },
        });
    });
  }

  setPassword(): void {
    this.disableSubmit = true;
    this.errorText = null;
    this.dataService
      .setPassword({
        username: this.form.controls.email.value,
        password: this.form.controls.password.value,
        permanent: true,
      })
      .subscribe({
        next: (data) => {
          if (data.isSuccess) {
            this.isLoaded$.next(false);
            this.authService
              .signIn(data.email, this.form.controls.password.value)
              .then(async () => {
                await enterApp(
                  this.dataService,
                  this.authService,
                  this.router,
                  this.confirmationService,
                );
                this.notificationService.open(
                  Constants.Notifications.PasswordSetSuccess,
                );
              });
          }
        },
        error: (error) => {
          this.errorText = error?.response?.data[0]?.ErrorMessage;
          this.deletePasswordErrorTextOnChange();
          this.form.controls.password.markAsPristine();
          this.cd.markForCheck();
        },
      });
  }

  isDisabled(): boolean {
    return (
      this.form?.pristine ||
      this.form?.invalid ||
      this.disableSubmit ||
      !this.personal ||
      !this.policy
    );
  }

  disableSave(): boolean {
    return this.form?.pristine || this.form?.invalid;
  }

  deletePasswordErrorTextOnChange() {
    this.form.controls['password'].valueChanges.pipe(take(1)).subscribe(() => {
      this.errorText = null;
    });
  }

  showMessage(): boolean {
    return this.userRole === Constants.UserRoles.candidate;
  }
}

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return control.dirty && !control.valid;
  }
}
