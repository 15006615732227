import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../constants';
import { IErrorPageModel } from '@lss/lss-types';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  error$ = new BehaviorSubject<IErrorPageModel>(null);
  itemDateError$ = new BehaviorSubject<string>('');

  constructor(private router: Router, private ngZone: NgZone) {}

  navigateToErrorPage(error: IErrorPageModel): void {
    this.error$.next(error);

    this.ngZone.run(() => {
      this.router.navigate([Constants.Routes.NotFound]);
    });
  }
}
