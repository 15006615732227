import { ICognitoUser, ILoggedInUser, IIdentityToken } from '@lss/lss-types';
import { Constants } from '../../constants';
import {
  ConfirmationService,
  getDataFromSessionStorage,
  removeDataFromSessionStorage,
} from '@lss/lss-ui';
import { BehaviorSubject, first, interval, map, take } from 'rxjs';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { SubmitConfirmComponent } from 'src/lss-ui/src/lib/form/confirmation/submit-confirm/submit-confirm.component';
import { Auth } from 'aws-amplify';

const LOG_OUT_COUNTDOWN_IN_SECONDS = 120;

export const TOKEN_REFRESH_DIALOG_ID = 'token-refresh-dialog-id';

export function getLoggedInUserData(cognitoUser: ICognitoUser): ILoggedInUser {
  const idToken = cognitoUser?.signInUserSession.idToken;
  const email = getUserEmail(idToken);
  const userRoles = getUserRoles(idToken);
  const fullName = getUserFullName(idToken);

  return {
    email,
    userRoles,
    idToken: idToken?.jwtToken,
    expiry: cognitoUser.signInUserSession.accessToken.payload.exp,
    fullName,
  };
}

export const getUserSpecificReturnUrl = (
  isCandidate: boolean,
  canSearch: boolean,
): string | null =>
  isCandidate
    ? Constants.Routes.CvTechnicalSkills
    : canSearch
    ? Constants.Routes.Home
    : null;

export function getUserRoles(idToken: IIdentityToken): string[] {
  try {
    return idToken.payload[Constants.Login.CognitoGroupsKey];
  } catch (e) {
    console.error(e);
  }
}

export function getUserFullName(idToken: IIdentityToken): string | null {
  if (idToken.payload.given_name && idToken.payload.family_name) {
    return `${idToken.payload.given_name} ${idToken.payload.family_name}`;
  }
  return null;
}

export const isTokenExpired = (expiry: number) => expiry * 1000 <= Date.now();

export const getTokenExpireTimeout = (expiry: number) =>
  new Date(expiry * 1000).valueOf() - new Date().valueOf();

export function getUserEmail(idToken: IIdentityToken): string {
  return idToken.payload.email;
}

export function setAutoLogoutAfterGivenTime(
  timeToLogOut: number,
  router: Router,
  authService: AuthService,
): NodeJS.Timeout {
  return setTimeout(() => {
    authService.refreshToken$.next(null);
    authService.isBasicLogout.next(false);
    localStorage.setItem(Constants.StorageKeys.loggedIn, getNewRandom());
    router.navigate([Constants.Routes.Login]).then(() => {
      authService.signOut();
      sessionStorage.setItem(Constants.StorageKeys.tokenExpired, 'true');
    });
  }, timeToLogOut);
}

export function displayModalBeforeTokenIsExpired(
  timer: NodeJS.Timeout,
  timeToLogOut: number,
  confirmationService: ConfirmationService,
  router: Router,
  authService: AuthService,
): NodeJS.Timeout {
  const timeToShowMessage = LOG_OUT_COUNTDOWN_IN_SECONDS * 1000;
  const timeToLogOutSecs = Math.floor(timeToLogOut / 1000);

  return setTimeout(() => {
    const body = createModalBodyWithCountdown(
      Math.min(LOG_OUT_COUNTDOWN_IN_SECONDS, timeToLogOutSecs),
    );
    showModalPopUp(body, timer, confirmationService, router, authService);
  }, Math.max(timeToLogOut - timeToShowMessage, 0));
}

export function createModalBodyWithCountdown(
  countdown: number,
): BehaviorSubject<string> {
  const body = new BehaviorSubject<string>(logOutWarningMessage(countdown));
  interval(1000)
    .pipe(
      take(countdown),
      map((count) => countdown - (count + 1)),
    )
    .subscribe((x) => body.next(logOutWarningMessage(x)));
  return body;
}

export function logOutWarningMessage(timeLeft: number): string {
  return `You’ll be logged out in <b>${timeLeft}</B> seconds. Do you want to stay signed in?`;
}

export function showModalPopUp(
  body: BehaviorSubject<string>,
  timer: NodeJS.Timeout,
  confirmationService: ConfirmationService,
  router: Router,
  authService: AuthService,
): void {
  const confirmData = ConfirmationService.simpleConfirmationDataFactory(
    'Your session is about to expire',
    body,
    'Yes, keep me signed in',
    'Log out',
  );
  const confirmed$ = confirmationService.confirm$(
    SubmitConfirmComponent,
    confirmData,
    '600px',
    TOKEN_REFRESH_DIALOG_ID,
  );

  confirmed$.pipe(first()).subscribe((confirmValue) => {
    if (confirmValue) {
      authService.refreshToken().then(() => {
        clearTimeout(timer);
      });
    } else if (confirmValue === false) {
      // can be undefined -> just close modal with no action
      authService.refreshToken$.next(null);
      authService.isBasicLogout.next(false);
      sessionStorage.setItem(Constants.StorageKeys.tokenExpired, 'false');
      localStorage.setItem(Constants.StorageKeys.loggedIn, getNewRandom());
      router.navigate([Constants.Routes.Login]).then(() => {
        authService.signOut();
      });
    }
  });
}

export function getNewRandom(): string {
  let random = Math.random().toString();
  const currentValue = window.localStorage.getItem(
    Constants.StorageKeys.loggedIn,
  );
  while (random === currentValue) {
    random = Math.random().toString();
  }

  return random;
}

export async function enterApp(
  dataService: DataService,
  authService: AuthService,
  router: Router,
  confirmationService: ConfirmationService,
): Promise<void> {
  dataService.setCognitoSync().subscribe(async () => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(
      async (currentUser) => {
        const loggedInUser = getLoggedInUserData(currentUser);
        authService.setLoggedInUser(loggedInUser);
        authService.fullName$.next(loggedInUser.fullName);
        localStorage.setItem(
          Constants.StorageKeys.fullname,
          loggedInUser.fullName,
        );

        const userReturnUrl = getUserSpecificReturnUrl(
          await authService.userHasRole(Constants.UserRoles.candidate),
          await authService.userCanPerformAction(
            Constants.RoleActions.canSearch,
          ),
        );

        const returnUrl =
          userReturnUrl ||
          getDataFromSessionStorage(Constants.Login.ReturnUrlSessionKey) ||
          Constants.Routes.Cv;
        removeDataFromSessionStorage(Constants.Login.ReturnUrlSessionKey);

        router.navigateByUrl(returnUrl);
      },
    );
  });
}

export function setUpTokenRefresh(
  user: ILoggedInUser,
  authService: AuthService,
  router: Router,
  confirmationService: ConfirmationService,
): NodeJS.Timeout[] {
  const timeToLogOut = getTokenExpireTimeout(user.expiry);
  const autoLogOutTimer = setAutoLogoutAfterGivenTime(
    timeToLogOut,
    router,
    authService,
  );
  const beforeExpirationTimer = displayModalBeforeTokenIsExpired(
    autoLogOutTimer,
    timeToLogOut,
    confirmationService,
    router,
    authService,
  );

  return [autoLogOutTimer, beforeExpirationTimer];
}
