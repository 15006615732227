<h1 class="title">
  {{ title }}
  <lss-icon
    (mouseenter)="openDialog()"
    (mouseleave)="leaveDialog()"
    id="technicalInfo"
    *ngIf="isCandidate && componentRoute === constantRoute"
  >
    <sup>info_outline</sup></lss-icon
  >
</h1>
<h3 class="subtitle">{{ subtitle }}</h3>

<div class="top">
  <div class="actions">
    <lss-button
      class="saveForm {{ nesting }}"
      id="saveFormTop"
      [disabled]="isButtonDisabled()"
      (action)="submit()"
      [color]="isCandidate ? 'secondary' : 'primary'"
    >
      <span>{{ submitText }}</span>
    </lss-button>

    <lss-button
      *ngIf="!!nextRoute && !isCandidate"
      id="saveFormAndContinueTop"
      class="{{ nesting }}"
      (action)="submitAndContinue()"
      [disabled]="isButtonDisabled()"
    >
      <span>Save and Continue</span>
      <mat-icon>arrow_right_alt </mat-icon>
    </lss-button>
  </div>
</div>
<div class="body">
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>

<div class="actions">
  <lss-button
    *ngIf="!isCandidate"
    class="saveForm {{ nesting }}"
    id="saveFormBottom"
    (action)="submit()"
    [disabled]="isButtonDisabled()"
    [color]="isCandidate ? 'secondary' : 'primary'"
  >
    <span>
      {{ submitText }}
    </span>
  </lss-button>
  <lss-button
    *ngIf="!!nextRoute && !isCandidate"
    id="saveFormAndContinueBottom"
    class="{{ nesting }}"
    (action)="submitAndContinue()"
    [disabled]="isButtonDisabled()"
    >Save and Continue
    <mat-icon>arrow_right_alt </mat-icon>
  </lss-button>
</div>

<ng-container *ngIf="isCandidate">
  <div class="bottom-spacer"></div>
  <div *ngIf="isCandidate" class="footer">
    <div id="center">
      <lss-button
        id="submitFormBottom"
        class="{{ nesting }}"
        (action)="submitFormToHR()"
        [disabled]="isSubmitButtonDisabled()"
      >
        <span>Submit</span>
        <mat-icon class="inline-icon">check</mat-icon>
      </lss-button>
      <div class="submittedDate" *ngIf="cv.submittedDate">
        Submitted on {{ cv.submittedDate * 1000 | date : 'dd/MM/yyyy' }}
      </div>
    </div>
  </div>
</ng-container>
