import { TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ILookupValue } from './types';
import { Subject } from 'rxjs';

export interface HasTable<T> {
  table: T;
}

export class TableConfig<DataT> {
  /**
   * If set to `false` then all entries will be displayed
   *
   * @memberof TableComponent
   */
  isPageable = false;
  isSelectable = false;
  isMultiSelect = true;
  tableColumns: TableColumn<DataT>[] = [];
  paginationSizes: number[] = [5, 10, 15, 20];
  defaultPageSize: number = this.paginationSizes[1];
  lenght: number;
  rowActionTmpl: TemplateRef<unknown>;
  /**
   * Template that will be shown in tooltip on hover of info icon in case
   * delete button is not shown. Dependant on {@link showDeleteButton}
   *
   * @type {TemplateRef<any>}
   * @memberof TableConfig
   */
  noDeleteButtonTemplate?: TemplateRef<any>;
  noEditCellTemplate?: TemplateRef<any>;
  showEditButton = true;
  showDeleteButton = true;
  /**
   * Function is used to conditions when should delete button be displayed.
   * This won't work if {@link showDeleteButton} is set to `false`
   *
   * **IMPORTANT:** This method gets execuded on every change (idk why. Maybe more optimizations should be done).
   * Make sure this function is lightweight and without any requests
   *
   * @memberof TableComponent
   */
  showDeleteButtonConditionFn: (element?: DataT) => boolean = () => true;
  /**
   * Flag indicates if regular actions (edit/delete) should be replaced
   * with custom action ({@link rowActionTmpl}). If `false` then custom
   * action template will be added after regular actions
   *
   * @memberof TableComponent
   */
  replaceActions = false;
  constructor(
    init: Partial<TableConfig<DataT>> & { tableColumns: TableColumn<DataT>[] },
  ) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
export type ColumnEditType = 'text' | 'number' | 'autocomplete';

export type ColumnFilterType =
  | 'text'
  | 'number'
  | 'autocomplete'
  | 'select'
  | 'date';

//#region Validation rules
// Credits: ChatGPT hor help
type ValidatorMethodNames = Exclude<
  keyof typeof Validators,
  // Excluding method names that are not needed
  'compose' | 'composeAsync' | 'prototype' | 'nullValidator'
>;

type ValidatorMethodParamType<K extends ValidatorMethodNames> = Parameters<
  (typeof Validators)[K]
>[0];

type ValidatorMethodValueType<K extends ValidatorMethodNames> =
  // In case of undefined we can use "any" because its a special case and in reality
  // shouldn't even be possible
  ValidatorMethodParamType<K> extends undefined
    ? any
    : // Mapping string or RegExp types
    ValidatorMethodParamType<K> extends string | RegExp
    ? string | RegExp
    : // As a fallback using "boolean" because judging by current state
    // only boolean values lack type
    ValidatorMethodParamType<K> extends number
    ? number
    : boolean;

export type AvailableValidators = {
  [K in ValidatorMethodNames]?: ValidatorMethodValueType<K>;
};

export interface ValidationRule extends AvailableValidators {
  /**
   * TODO: Implement display of error message
   *
   * @type {string}
   * @memberof ValidationRule
   */
  errorMessage?: string;
}

//#endregion

export interface CellEditConfig {
  type: ColumnEditType;
  /**
   * List of options/values for select in case of dropdown or autocomplete or any other select
   *
   * @type {ILookupValue<any>[]}
   * @memberof CellEditConfig
   */
  values?: ILookupValue<any>[];
  /**
   * Values can be provided/generated based on certain criteria. If this function is not
   * provided then {@link values} list will be used as fallback.
   *
   * **IMPORTANT:** This method gets execuded on every change (idk why. Maybe more optimizations should be done).
   * Make sure this function is lightweight and without any requests
   *
   * @memberof CellEditConfig
   */
  dynamicValues?: (element: any) => ILookupValue<any>[];
  /**
   * List with validation rules that need to be applied.
   * NOTE: Each validation rule should have a separate record. Example:
   *
   * ```
   * [{required: true}, {min: 100}]
   * ```
   *
   * This is because each validation rule could have a separate error message and/or
   * other data that is relevant only to it
   *
   * @type {ValidationRule[]}
   * @memberof CellEditConfig
   */
  validation: ValidationRule[];

  /**
   * Additional callback for value change
   * NOTE: triggered by autocomplete only
   * provide to another controls if needed
   * @memberof CellEditConfig
   */
  onValueChanged?: (
    value: ILookupValue<any>,
    control: FormControl,
    element: any & Editable,
  ) => void;
}

export interface CellFilterConfig {
  /**
   * Filter type. Used to render correct input. By default everything acts as `text`
   *
   * @type {ColumnFilterType}
   * @memberof CellFilterConfig
   */
  type: ColumnFilterType;
  isMultiSelect?: boolean;
  /**
   * List of options/values for select in case of dropdown or autocomplete or any other select
   *
   * @type {ILookupValue<any>[]}
   * @memberof CellFilterConfig
   */
  values?: ILookupValue<any>[];
}

export type ColumnType = 'string' | 'number' | 'date' | 'custom';
type WidthUnit = 'px' | '%' | 'em' | 'rem';
type WidthWithUnit = `${number}${WidthUnit}` | 'auto';

export interface TableColumn<DataT> {
  name: string;
  key: keyof DataT & string;
  /**
   * Indicates if this is column has default sorting.
   * If this property is set then this column will act as default column
   * sorting with direction as value. If there are multiple columns that
   * have this property then only first found will be used
   *
   * @type {('' | 'asc' | 'desc')}
   * @memberof TableColumn
   */
  defaultSorting?: 'asc' | 'desc';
  isSortable?: boolean;
  isFiltrable?: boolean;
  /**
   * **IMPORTANT:** This method gets execuded on every change (idk why. Maybe more optimizations should be done).
   * Make sure this function is lightweight and without any requests
   *
   * @memberof TableColumn
   */
  isEditable?: (element: DataT) => boolean;
  arrowPosition?: 'left' | 'right';
  contentPosition?: 'left' | 'right' | 'center';
  hideName?: boolean;
  cellOptions: {
    type: ColumnType;
    /**
     * Template to render if {@link type} is set to `custom`
     *
     * @type {TemplateRef<unknown>}
     * @memberof TableColumn
     */
    customRenderTmpl?: TemplateRef<unknown>;
    /**
     * Value formatting if possible/supported.
     * * For type "number" it can be: '1.2-2' which will result into `| number:'1.2-2'`
     * * For type "date" it can be: 'dd.MM.YYYY' which will restul into `| date:'dd.MM.YYYY'`
     *
     * @type {string}
     */
    format?: string;
    editingOptions?: CellEditConfig;
    filteringOptions?: CellFilterConfig;
    width?: WidthWithUnit;
  };
}

export interface Editable {
  isEditMode: boolean;
  form: FormGroup;
  isProcessing: boolean;
  isDataChanged: boolean;
  dataChangesFinished$: Subject<void>;
}

export interface Createable extends Editable {
  /**
   * Order number for pending list. In some way its unique index.
   * Used to determine which is the correct "not created" element in table
   *
   * @type {number}
   * @memberof Createable
   */
  pendingNumber: number;
}

export interface DataIndex {
  dataIndex: number;
}

export interface SaveEditableRow<T = any> {
  newValue: T;
  oldValue: T;
  /**
   * Index of element in original array. During sorting/filtering element index gets
   * modified but this `dataIndex` will remain as it was in the passed in data array
   *
   * @type {number}
   * @memberof SaveEditableRow
   */
  dataIndex: number;
  /**
   * Editing completion method. Needs to be called when editing can be considered as complete, e.g.
   * when server responded with success.
   * @param {boolean} [updateElementValues] Should element values be updated in table. Will
   * update old values with updated and keep the reference to that object. `false` value can be used
   * to manually handle value update logic
   *
   * @memberof SaveEditableRow
   */
  complete: (updateElementValues?: boolean) => void;
  /**
   * Action error handling method. Used to perform actions in case of error.
   * One of the use cases is to disable {@link Editable.isProcessing} flag because
   * otherwise element row will be left hanging in processing state
   *
   * @memberof SaveEditableRow
   */
  error: () => void;
}

export interface DefaultSorting {
  columnKey: string;
  direction: 'asc' | 'desc';
}
