import { HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService, SimpleConfirmComponent } from '@lss/lss-ui';
import { Observable, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DATA_SERVICE_TOKEN,
  IRegisterCandidateResult,
  ISimpleConfirmData,
  NotificationMode,
} from '@lss/lss-types';
import { DataService } from '../base/data.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Constants } from '../constants';
import { first, take } from 'rxjs/operators';
import { emailStructureValidator } from '../utils';
import { NotificationService } from '../base/notification/notification.service';
import { AuthService } from '../base/auth.service';

@Component({
  selector: 'lss-register-candidate',
  templateUrl: './register-candidate.component.html',
  styleUrls: ['./register-candidate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterCandidateComponent implements OnInit, OnDestroy {
  errorText: string;
  emailErrorText: string;
  disableSubmit = false;
  exEmployeeConversion = false;
  exEmployeeName: string;
  exEmployeeEmail: string;
  canClearForm: boolean;
  title = this.activatedRoute.snapshot.data.data.title;
  isExternalHR: boolean;
  destroyed$ = new Subject();

  public form = new FormGroup({
    email: new FormControl(null, [
      emailStructureValidator(),
      Validators.required,
    ]),
    name: new FormControl(null, [Validators.required]),
    surname: new FormControl(null, [Validators.required]),
    link: new FormControl(),
  });

  registerFunction: (
    body: {
      email: string;
      name: string;
      surname: string;
    },
    handleError: () => void,
  ) => Observable<IRegisterCandidateResult>;

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private clipboard: Clipboard,
    private activatedRoute: ActivatedRoute,
    public notificationService: NotificationService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  async ngOnInit(): Promise<void> {
    this.form.valueChanges.subscribe((v) => {
      this.canClearForm = v?.email || v?.link || v?.name || v?.surname;
      this.disableSubmit = false;
      this.dataService.formState.next(v as FormGroup);
      this.exEmployeeConversion && v?.link === null && !v?.email
        ? (this.canClearForm = false)
        : null;
    });

    this.registerFunction =
      this.activatedRoute.snapshot.data.url ===
      Constants.Routes.RegisterCandidate
        ? this.dataService.registerCandidate
        : this.dataService.registerExternalHr;
    this.isExternalHR = await this.authService.userHasRole(
      Constants.UserRoles.externalHr,
    );

    this.initConvertFormIfNeeded();
  }

  showSnackBar(message?: string): void {
    const bodyMessage = message
      ? message
      : this.isExternalHR
      ? Constants.ErrorMessages.candidateExistsExternalHR
      : Constants.ErrorMessages.candidateExists;
    this.dataService.notificationService.open({
      title: Constants.Status.failed,
      body: bodyMessage,
      mode: NotificationMode.Error,
    });
  }

  changeEmailControlToInvalid(): void {
    this.form.controls.email.setValue(this.form.controls.email.value);
    this.form.controls.email.setErrors({ required: true });
  }

  deleteEmailErrorTextOnChange(): void {
    this.form.controls.email.valueChanges.pipe(take(1)).subscribe(() => {
      this.emailErrorText = null;
    });
  }

  private handleError = () => {
    this.emailErrorText = Constants.ErrorMessages.sameEmailExists;
    this.showSnackBar();
    this.changeEmailControlToInvalid();
    this.deleteEmailErrorTextOnChange();
    this.disableSubmit = false;
    this.cd.markForCheck();
  };

  registerUser(): void {
    this.disableSubmit = true;
    this.errorText = null;
    this.emailErrorText = null;
    if (this.exEmployeeConversion) {
      this.convertExEmployee();
    } else {
      this.registerFunction(
        {
          email: this.form.controls.email.value,
          name: this.form.controls.name.value,
          surname: this.form.controls.surname.value,
        },
        this.handleError,
      ).subscribe({
        next: (data) => {
          this.updateLink(data.encryptedEmail);
        },
        error: (e) => {
          this.errorText = e.response?.data[0]?.ErrorMessage;
          this.disableSubmit = false;
          this.cd.markForCheck();
        },
      });
    }
  }

  copyInvitationLink(): void {
    this.disableSubmit = true;
    this.clipboard.copy(this.form.controls.link.value);

    this.showNotification();
  }

  onUrlClick(): void {
    if (this.form.controls.link.value) {
      this.copyInvitationLink();
    }
  }

  onRightClick(): boolean {
    this.onUrlClick();
    return false;
  }

  private showNotification(): void {
    this.notificationService.open(
      Constants.Notifications.CopyLinkToClipboardSuccess,
    );
  }

  isDisabled(): boolean {
    return this.form?.pristine || this.form?.invalid || this.disableSubmit;
  }

  clearForm(): void {
    this.disableSubmit = false;
    this.form?.reset();
    this.initConvertFormIfNeeded();
    this.form?.markAsUntouched();
    this.form?.markAsPristine();
    this.cd.markForCheck();
  }

  private convertExEmployee(): void {
    this.dataService
      .convertExEmployeeToCandidate(this.exEmployeeEmail, {
        email: this.form.controls.email.value,
        firstName: this.form.controls.name.value,
        lastName: this.form.controls.surname.value,
      })
      .subscribe({
        next: (data) => {
          if (data.status === HttpStatusCode.Accepted) {
            this.showMergeDialog();
            return;
          }

          this.updateLink(data.data as string);
        },
        error: (e) => {
          this.handleConversionError(e);
        },
      });
  }

  private mergeCandidates(): void {
    this.dataService
      .mergeCandidates(this.exEmployeeEmail, {
        email: this.form.controls.email.value,
        firstName: this.form.controls.name.value,
        lastName: this.form.controls.surname.value,
      })
      .subscribe({
        next: (data) => {
          this.updateLink(data);
        },
        error: (e) => {
          this.handleConversionError(e);
        },
      });
  }

  private initConvertFormIfNeeded(): void {
    if (this.activatedRoute.snapshot.paramMap.get('convert')) {
      const cv = this.dataService.cv$.getValue();
      if (cv) {
        this.exEmployeeConversion = true;
        this.exEmployeeName = [
          cv.personalInfo.firstName,
          cv.personalInfo.lastName,
        ].join(' ');
        this.exEmployeeEmail = cv.contactInfo.workEmail;
        this.title = 'Convert to candidate';
        this.form.controls.name.setValue(cv.personalInfo.firstName);
        this.form.controls.surname.setValue(cv.personalInfo.lastName);
      }
    }
  }

  private showMergeDialog(): void {
    const data: ISimpleConfirmData = {
      header: Constants.DuplicateCandidate.Header,
      body: Constants.DuplicateCandidate.Body,
      confirmButtonText: Constants.DuplicateCandidate.OKButton,
      declineButtonText: Constants.DuplicateCandidate.CancelButton,
      resultFilter: () => true,
    };

    this.confirmationService
      .confirm$(SimpleConfirmComponent, data, '400px')
      .pipe(first())
      .subscribe((confirm) => {
        if (confirm) {
          this.mergeCandidates();
        } else {
          this.disableSubmit = false;
          this.cd.markForCheck();
        }
      });
  }

  private handleConversionError(e: any): void {
    this.emailErrorText = e.ErrorMessage;
    this.showSnackBar(e.ErrorMessage);
    this.changeEmailControlToInvalid();
    this.deleteEmailErrorTextOnChange();

    this.disableSubmit = false;
    this.cd.markForCheck();
  }

  private updateLink(encryptedEmail: string): void {
    this.form.controls.link.setValue(
      location.origin +
        '/' +
        Constants.Routes.SetPasswordCandidate +
        '?url=' +
        encryptedEmail,
      { emitEvent: false },
    );
    this.form.markAsPristine();
    this.cd.markForCheck();
  }
}
