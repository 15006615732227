import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseControl } from '../base.control';
import { IGroupableLookupValue, ILookupValue } from '@lss/lss-types';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { fromEvent, Observable } from 'rxjs';
import { containsLookup } from '../../utils';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lss-autocomplete',
  template: `
    <mat-form-field [floatLabel]="floatLabel">
      <mat-label *ngIf="label">{{ label }}</mat-label>

      <input
        *ngIf="digitsOnly"
        digitOnly
        [decimal]="decimal"
        [max]="maxValue"
        [required]="required"
        type="text"
        class="autocomplete-input"
        matInput
        #input
        [matAutocomplete]="auto"
        (keyup.enter)="handleClose()"
        [(ngModel)]="value"
        placeholder="{{ placeholder }}"
      />

      <input
        *ngIf="!digitsOnly"
        type="text"
        class="autocomplete-input"
        matInput
        #input
        [required]="required"
        [matAutocomplete]="auto"
        (keyup)="onKeyup($event)"
        [(ngModel)]="value"
        placeholder="{{ placeholder }}"
        (blur)="onBlur($event)"
        (focus)="onFocus()"
      />

      <mat-icon>arrow_drop_down</mat-icon>
      <mat-autocomplete
        (optionSelected)="onSelected($event)"
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <ng-container *ngIf="displayAsGroups">
          <mat-optgroup
            *ngFor="let group of filteredGroups | async"
            [label]="group.groupName"
          >
            <mat-option *ngFor="let option of group.options" [value]="option">
              {{ option.displayName }}
            </mat-option>
          </mat-optgroup>
        </ng-container>

        <ng-container *ngIf="!displayAsGroups">
          <mat-option
            *ngFor="
              let option of isLanguage ? filteredLanguages : filteredOptions
            "
            [value]="option"
            [style.color]="this.notFound ? 'red' : 'black'"
            >{{ option.displayName }}</mat-option
          >
        </ng-container>
      </mat-autocomplete>

      <mat-error *ngIf="!hideError">Field is mandatory</mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent
  extends BaseControl
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() options: ILookupValue[];
  @Input() excludeOptions: ILookupValue[] = [];
  @Input() groups: IGroupableLookupValue[];
  @Input() digitsOnly?: boolean;
  @Input() decimal?: boolean;
  @Input() maxValue?: number;
  @Input() placeholder?: string;
  @Input() focusedPlaceholder?: string | undefined;
  @Input() searchFromStart = true;
  @Input() exclude$: Observable<ILookupValue[]>;
  @Input() isLanguage: boolean;
  @Input() hideError: boolean;
  @Input() floatLabel = '';
  @Input() enterNewValueMode: boolean;
  @Input() autoSetOnMatch: boolean;
  /**
   * Special case to allow filtering from all options. By default
   * filtering is done by already filtered options. This is mostly
   * an additional flag to avoid regression
   *
   * @memberof AutocompleteComponent
   */
  @Input() filterFromAllOptions = false;

  @Output() selected = new EventEmitter();

  @ViewChild('auto') autocomplete: MatAutocomplete;
  @ViewChild('input') input: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputAutoComplete: MatAutocompleteTrigger;

  filteredOptions$: Observable<ILookupValue[]>;
  filteredOptions: ILookupValue[];
  filteredGroups: Observable<IGroupableLookupValue[]>;
  displayAsGroups: boolean;
  filteredLanguages: ILookupValue[] = [];
  notFound: boolean;
  isFocused: boolean;
  isTextTyped: boolean;
  originalPlaceholder: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.excludeOptions) {
      const sourceOptions = this.filterFromAllOptions
        ? this.options
        : this.filteredOptions;

      this.filteredOptions = sourceOptions?.filter(
        (option) =>
          !containsLookup(option, changes.excludeOptions.currentValue),
      );
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.isLanguage) {
      this.useLanguageFilter();
    } else {
      this.useOtherFilter();
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'click')
      .pipe(
        tap((e) => this.inputAutoComplete.openPanel()),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  dataInGroups(
    options: ILookupValue[],
    groups: IGroupableLookupValue[],
  ): boolean {
    if (!options && !groups) {
      throw new Error(
        'Autocomplete data classification failed: no options or groups provided',
      );
    }
    return !!this.groups;
  }

  displayFn(lookup: ILookupValue | number): string {
    if (typeof lookup !== 'number') {
      return lookup && lookup.displayName.toString();
    } else {
      return lookup.toString();
    }
  }

  onSelected(event: MatAutocompleteSelectedEvent): void {
    this.isTextTyped = false;
    this.handleClose();

    // Emits on mouse select and enter key from drop down menu
    this.selected.emit(event.option.value);

    if (this.filteredOptions$) {
      this.filteredOptions$ = this.filteredOptions$.pipe(
        startWith(''),
        map(() => this.options),
      );
      this.ngOnInit();
    }
    if (this.filteredGroups) {
      this.filteredGroups = this.filteredGroups.pipe(
        startWith(''),
        map(() => this.groups),
      );
      this.ngOnInit();
    }
  }

  public focusInput(): void {
    this.input.nativeElement.focus();
  }

  private filterOptions(
    value: string | ILookupValue,
    options: ILookupValue[],
  ): ILookupValue[] {
    if (value === null || value === undefined) {
      return options.filter((e) => !containsLookup(e, this.excludeOptions));
    }

    const filterValue = (
      typeof value === 'string' ? value : value.displayName
    ).toLowerCase();

    const result = this.searchFromStart
      ? options.filter(
          (e) =>
            e.displayName.toLowerCase().startsWith(filterValue) &&
            !containsLookup(e, this.excludeOptions),
        )
      : options.filter(
          (e) =>
            e.displayName.toLowerCase().includes(filterValue) &&
            !containsLookup(e, this.excludeOptions),
        );

    return result;
  }

  private filterGroups(value: string): IGroupableLookupValue[] {
    return this.groups
      .map((e) => ({
        groupName: e.groupName,
        options: this.filterOptions(value, e.options),
      }))
      .filter((e) => e.options.length > 0);
  }

  private useOtherFilter() {
    this.displayAsGroups = this.dataInGroups(this.options, this.groups);
    this.filteredOptions$ =
      !this.displayAsGroups &&
      this.ngControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          if (typeof value === 'string') {
            return this.filterOptions(value, this.options);
          }
          // For ILookupValue
          // value was populated from control
          // -> no options filtering require
          return this.options;
        }),
        takeUntil(this.destroyed$),
      );

    if (this.filteredOptions$ instanceof Observable) {
      this.filteredOptions$.subscribe(
        (options) => (this.filteredOptions = options),
      );
    }

    this.filteredGroups =
      this.displayAsGroups &&
      this.ngControl.valueChanges.pipe(
        startWith(''),
        map((e) => this.filterGroups(e)),
        takeUntil(this.destroyed$),
      );
  }

  private useLanguageFilter() {
    this.exclude$.subscribe((selectedLanguages) => {
      this.notFound = false;
      this.filteredLanguages = this.options
        .filter((option) =>
          selectedLanguages.every((language) => language.key !== option.key),
        )
        .filter((option) => {
          if (typeof this.value === 'string' || !this.value) {
            const val = this.value ?? '';
            return option.displayName
              .toLowerCase()
              .startsWith(val.toLowerCase());
          }
        });

      if (this.filteredLanguages.length === 0 && this.isFocused) {
        this.notFound = true;
        this.filteredLanguages.push({
          key: 'missing',
          displayName: 'No match found',
          group: null,
        });
      }
    });
  }

  handleClose() {
    this.inputAutoComplete.closePanel();
    this.input.nativeElement.blur();
  }

  onBlur(event: FocusEvent): void {
    this.isFocused = false;
    if (this.focusedPlaceholder) {
      this.placeholder = this.originalPlaceholder;
    }
    if (
      event.relatedTarget &&
      (event.relatedTarget as HTMLElement)?.tagName === 'MAT-OPTION'
    ) {
      return;
    }

    const inputValue = (event.target as HTMLInputElement).value.toLowerCase();

    const option = this.options?.find(
      (e) => e.displayName.toLowerCase() === inputValue,
    );
    if (!option) {
      this.writeValue('');
      this.selected.emit('');
      return;
    }

    if (this.isLanguage) {
      const isLanguageExcluded =
        this.excludeOptions
          .map((e) => {
            return typeof e === 'string' ? e : e?.displayName.toLowerCase();
          })
          .flat()
          .filter((e) => e.toLowerCase() === inputValue).length > 1;

      isLanguageExcluded ? this.writeValue('') : this.writeValue(option);
    } else {
      // autoselect if entered text corresponds to option
      // emit only if user enter some text
      if (this.isTextTyped) {
        this.isTextTyped = false;

        if (this.autoSetOnMatch) {
          this.writeValue(option);
          this.selected.emit(option);
        } else {
          this.writeValue('');
        }
      }
    }
  }

  onFocus(): void {
    this.notFound = false;
    this.isFocused = true;
    if (this.focusedPlaceholder) {
      this.originalPlaceholder = this.placeholder;
      this.placeholder = this.focusedPlaceholder;
    }
    this.filteredLanguages = this.options?.filter((option) =>
      (this.excludeOptions || []).every(
        (language) => language.key !== option.key,
      ),
    );
  }

  onKeyup($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      if (this.enterNewValueMode) {
        const inputValue = (
          event.target as HTMLInputElement
        ).value.toLowerCase();
        this.selected.emit(inputValue);
      }

      this.handleClose();
    } else {
      this.isTextTyped = true;
    }
  }
}
