import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

export const updateValueAndValidityRecursively = (
  group: FormGroup | FormArray,
) => {
  if (!group || !group.controls) {
    return;
  }
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key];

    if (
      abstractControl instanceof FormGroup ||
      abstractControl instanceof FormArray
    ) {
      updateValueAndValidityRecursively(abstractControl);
    } else {
      abstractControl.updateValueAndValidity();
    }
  });
};

export const timeout = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const basicInitialized = (input: any): boolean => {
  return !(input === undefined || input === null || Number.isNaN(input));
};

export const objectNotEmpty = (input: any): boolean => {
  return (
    basicInitialized(input) &&
    typeof input === 'object' &&
    !!Object.keys(input).length
  );
};

export const stringInitialized = (input: any): boolean => {
  return (
    basicInitialized(input) &&
    (typeof input === 'string' || input instanceof String)
  );
};

export const stringNotEmptySpaces = (input: any): boolean => {
  return (
    stringInitialized(input) &&
    input.length &&
    input.replace(/ |\s|\t|\v|\f|\n|\r/g, '').length
  );
};

export const booleanInitialized = (input: any): boolean => {
  return (
    basicInitialized(input) &&
    (typeof input === 'boolean' || input instanceof Boolean)
  );
};

export const isValidEmailStructure = (input: string): boolean => {
  const emailRegex = new RegExp(/^[\w-\.]+@(([\w-]+\.){1,5})[a-zA-Z]{2,6}$/);
  return emailRegex.test(input);
};

export function emailStructureValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (typeof value === 'string') {
      const isValidEmail = isValidEmailStructure(value);
      return isValidEmail
        ? null
        : { invalidStructure: { value: control.value } };
    }

    return null;
  };
}

export function formatTimestamp(timestamp): string | null {
  if (!timestamp) {
    return null;
  }
  const date = new Date(timestamp * 1000);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
  return formattedDate;
}

export function CapitalizeFirstLetter(str: string): string {
  if (str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  }
}

export function trimRichEditorValue(value: string): string {
  if (value) {
    const closing = /<\/p>(?![\s\S]*<\/p>[\s\S]*$)/i;
    var trimmedText = value.replace(closing, '').replace('<p>', '').trim();
    return `<p>${CapitalizeFirstLetter(trimmedText)}</p>`;
  }
}
