<div *ngIf="isLoaded$ | async">
  <mat-card *ngIf="!isResetUrl">
    <div>
      <img
        class="logo"
        alt="28 stone"
        aria-label="28 stone logo"
        src="assets/svg/28stone-logo.svg"
      />
    </div>

    <mat-card-title>Set password</mat-card-title>
    <p *ngIf="showMessage()">
      This password is needed in case you close the website before completing
      your profile, so you can pick up where you left off.
    </p>

    <div class="form-container">
      <div *ngIf="!!errorText" class="error-text">
        <mat-error>{{ errorText }}</mat-error>
      </div>

      <lss-text
        [readonly]="true"
        [formControl]="form.controls.fullname"
        label="Full Name"
      ></lss-text>

      <lss-text
        [readonly]="true"
        [formControl]="form.controls.email"
        label="Email Address"
      ></lss-text>

      <lss-text
        class="password"
        [type]="'password'"
        [formControl]="form.controls.password"
        [showVisibilityIcon]="true"
        [required]="true"
        [minlength]="minLength"
        [maxLength]="maxLength"
        [customMatcher]="matcher"
        label="New Password"
        hideError="true"
      >
      </lss-text>
      <mat-error
        *ngIf="
          form.controls.password.dirty &&
          form.controls.password.hasError('required')
        "
        >Password is required</mat-error
      >
      <mat-error *ngIf="form.controls.password.hasError('minlength')">
        <mat-icon>close</mat-icon>
        At least 8 symbols long
      </mat-error>
      <mat-error
        *ngIf="
          !form.controls.password.hasError('minlength') &&
          !form.controls.password.hasError('required')
        "
      >
        <mat-icon class="valid">done</mat-icon>
        At least 8 symbols long
      </mat-error>

      <div class="checkboxes">
        <mat-checkbox [color]="'primary'" class="present" [(ngModel)]="policy">
          I accept 28Stone's
        </mat-checkbox>
        <a (click)="readTerms()" (mousedown)="$event.stopPropagation()">
          Terms of Service
        </a>
        and
        <a (click)="readPrivacyPolicy()" (mousedown)="$event.stopPropagation()">
          Privacy Policy
        </a>
        <mat-checkbox
          [color]="'primary'"
          [(ngModel)]="personal"
          class="privacy-checkbox"
        >
          I agree that all the personal information provided can be used in
          existing and future 28Stone recruitment processes (internally and with
          28Stone clients)
        </mat-checkbox>
      </div>

      <div class="actions">
        <lss-button
          [color]="'primary'"
          [disabled]="isDisabled()"
          (action)="setPassword()"
        >
          Set Password
        </lss-button>
      </div>
    </div>
  </mat-card>

  <mat-card *ngIf="isResetUrl">
    <div>
      <img
        class="logo"
        alt="28 stone"
        aria-label="28 stone logo"
        src="assets/svg/28stone-logo.svg"
      />
    </div>

    <mat-card-title>Create new password</mat-card-title>
    <div class="form-container">
      <div *ngIf="!!errorText" class="error-text">
        <mat-error>{{ errorText }}</mat-error>
      </div>

      <lss-text
        class="password"
        [type]="'password'"
        [formControl]="form.controls.password"
        [showVisibilityIcon]="true"
        [required]="true"
        [minlength]="minLength"
        [maxLength]="maxLength"
        [customMatcher]="matcher"
        label="New Password"
        hideError="true"
      >
      </lss-text>
      <mat-error
        *ngIf="
          form.controls.password.dirty &&
          form.controls.password.hasError('required')
        "
        >Password is required</mat-error
      >
      <mat-error *ngIf="form.controls.password.hasError('minlength')">
        <mat-icon>close</mat-icon>
        At least 8 symbols long
      </mat-error>
      <mat-error
        *ngIf="
          !form.controls.password.hasError('minlength') &&
          !form.controls.password.hasError('required')
        "
      >
        <mat-icon class="valid">done</mat-icon>
        At least 8 symbols long
      </mat-error>

      <div class="actions">
        <lss-button
          [color]="disableSave() ? 'basic' : 'primary'"
          [disabled]="disableSave()"
          (action)="setPassword()"
        >
          Save Password
        </lss-button>
      </div>
    </div>
  </mat-card>
</div>
