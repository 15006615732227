import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password.component';
import { ButtonModule, RenderingModule, TextModule } from '@lss/lss-ui';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggedActionsModule } from '../logged-user-actions/logged-actions.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    RenderingModule,
    LoggedActionsModule,
    FormsModule,
    MatCardModule,
    TextModule,
    MatIconModule
  ],
  exports: [ForgotPasswordComponent],
})
export class ForgotPasswordModule {}