<ng-container [ngSwitch]="column?.cellOptions?.filteringOptions?.type">
  <ng-container *ngSwitchDefault>
    <lss-text
      type="text"
      [formControl]="control"
      [placeholder]="column?.name"
      [minLength]="
        column?.cellOptions?.editingOptions?.validation
          | validatorValue : 'minLength'
      "
      [maxLength]="
        column?.cellOptions?.editingOptions?.validation
          | validatorValue : 'maxLength'
      "
    ></lss-text>
  </ng-container>
  <ng-container *ngSwitchCase="'number'">
    <lss-number
      [formControl]="control"
      [placeholder]="column?.name"
      [minValue]="
        column?.cellOptions?.editingOptions?.validation | validatorValue : 'min'
      "
      [maxValue]="
        column?.cellOptions?.editingOptions?.validation | validatorValue : 'max'
      "
    ></lss-number>
  </ng-container>
  <ng-container *ngSwitchCase="'autocomplete'">
    <lss-cell-edit-autocomplete
      [placeholder]="column?.name"
      [options]="column?.cellOptions.filteringOptions?.values"
      (selected)="updateSelected($event)"
    ></lss-cell-edit-autocomplete>
  </ng-container>
  <ng-container *ngSwitchCase="'select'">
    <lss-select-autocomplete
      [formControl]="control"
      [placeholder]="column?.name"
      hideError="true"
      [options]="column?.cellOptions?.filteringOptions?.values"
      [multiple]="column?.cellOptions?.filteringOptions?.isMultiSelect"
    ></lss-select-autocomplete>
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    <lss-date-picker
      label=""
      [formControl]="control"
      [placeholder]="column?.name"
    ></lss-date-picker>
  </ng-container>
</ng-container>
