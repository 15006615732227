import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../base/auth.service';
import { Constants } from '../constants';

@Injectable()
export class ExternalHRGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate = async (): Promise<boolean> => {
    try {
      const isExternalHR = await this.authService.userHasRole(
        Constants.UserRoles.externalHr
      );
      if (isExternalHR) {
        this.router.navigate([Constants.Routes.Home]);
      }
      return !isExternalHR;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
}
