import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { BaseControl } from '../base.control';
import { FormErrorStateManager } from '../text/text.component';

@Component({
  selector: 'lss-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent extends BaseControl implements OnDestroy {
  @Input() formControl: FormControl;
  @Input() label: string = 'Choose a date';
  @Input() customMatcher: ErrorStateMatcher;
  @Input() validationErrorsMap: ValidationErrors = {
    required: 'Date is missing!',
  };
  @Input() placeholder: string;
  @Input() hideError: boolean;

  @ViewChild('picker') private picker: MatDatepicker<any>;

  public matcher = new FormErrorStateManager();

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getErrorMessage() {
    for (let key in this.formControl.errors) {
      return this.validationErrorsMap?.[key];
    }
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(event): void {
    if (
      this.picker.opened &&
      !document.getElementById(this.picker.id).contains(event.target) &&
      (!event.target.className ||
        !event.target.className.includes('mat-calendar'))
    ) {
      this.picker.close();
    }
  }
}
