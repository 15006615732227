import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'lss-editable-item-table-header',
  template: '<ng-content></ng-content>',
  styleUrls: ['./editable-item-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableItemTableHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
