import { HostListener, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ConfirmationService } from '@lss/lss-ui';
import {
  debounceTime,
  distinctUntilChanged,
  throttle,
  switchMap,
} from 'rxjs/operators';
import { Subscription, fromEvent, of, merge, timer } from 'rxjs';
import { Router } from '@angular/router';
import {
  TOKEN_REFRESH_DIALOG_ID,
  getTokenExpireTimeout,
  setUpTokenRefresh,
} from './login/login-utils';
import { Constants } from '../constants';

const MILLIS_IN_SEC = 1000;
const USER_ACTIVITY_DEBOUNCE_TIME = 2 * MILLIS_IN_SEC; // 2 secs debounce on user interaction
const TOKEN_REFRESH_THROTTLE_TIME = 600 * MILLIS_IN_SEC; // 10 mins - interval between token refresh
const MIN_TTL_TO_REFRESH_ON_START = 900 * MILLIS_IN_SEC; // 15 mins - if ttl on app enter is less -> refresh immediatelly

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private activitySubscription$;
  private timeouts: NodeJS.Timeout[] = [];
  private ttl: number;
  private lastTokenActivity: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
  ) {
    const authenticatedUser$ = this.authService.authenticatedUser$;

    authenticatedUser$.pipe(distinctUntilChanged()).subscribe((user) => {
      this.stopActivityMonitoring();

      if (user) {
        this.lastTokenActivity = new Date().valueOf();

        // TTL
        this.ttl = getTokenExpireTimeout(user.expiry);

        console.info(`TTL: ${Math.round(this.ttl / 1000)} secs`);

        // If same tab refresh -> no refresh token will oocurs
        // so refresh it manually if not much time left

        if (this.ttl < MIN_TTL_TO_REFRESH_ON_START) {
          this.authService.refreshToken();
        } else {
          this.activitySubscription$ = this.startActivityMonitoring();

          const timers = setUpTokenRefresh(
            user,
            this.authService,
            this.router,
            this.confirmationService,
          );

          this.timeouts.forEach((timeout) => {
            clearTimeout(timeout);
          });

          this.timeouts = timers;
        }
      }
    });
  }

  startActivityMonitoring(): Subscription {
    // storage event with
    addEventListener('storage', this.onStorageEventHandler);

    const events = ['click', 'keyup', 'mouseup'];

    const eventStreams = events.map((event) => fromEvent(document, event));
    const activityEvents$ = merge(...eventStreams);

    return activityEvents$
      .pipe(
        debounceTime(USER_ACTIVITY_DEBOUNCE_TIME),
        switchMap(() => {
          // Calc throttle duration
          // if ttl is low -> update immediately
          // if last activity was more then throttle duration time ago -> update immediately (optional)
          // else update after throttle duration will end
          const sinceLastActivity =
            new Date().valueOf() - this.lastTokenActivity;

          const throttleTime =
            this.ttl <= TOKEN_REFRESH_THROTTLE_TIME ||
            sinceLastActivity >= TOKEN_REFRESH_THROTTLE_TIME
              ? 0
              : TOKEN_REFRESH_THROTTLE_TIME;

          return of(throttleTime);
        }),
        throttle((throttleTime) => timer(throttleTime), {
          trailing: true,
          leading: false,
        }),
      )
      .subscribe(() => {
        this.authService.refreshToken().then(() => {
          // close refresh dialog if any
          this.confirmationService.closeDialogById(TOKEN_REFRESH_DIALOG_ID);

          this.lastTokenActivity = new Date().valueOf();
          console.info('Token refreshed.');
        });
      });
  }

  stopActivityMonitoring() {
    if (this.activitySubscription$) {
      this.activitySubscription$.unsubscribe();
    }
    removeEventListener('storage', this.onStorageEventHandler);
  }

  private onStorageEventHandler = (event: StorageEvent) => {
    if (event.key === Constants.StorageKeys.tokenRefreshExecutor) {
      // close refresh dialog if any
      this.confirmationService.closeDialogById(TOKEN_REFRESH_DIALOG_ID);

      // reassign new token to used data
      // it will also restart activity monitoring
      this.authService.reassignTokens();
    }
  };
}
