import { Injectable } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { ICredentials } from '@aws-amplify/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ICognitoUser, ILoggedInUser } from '@lss/lss-types';
import { FormControl } from '@angular/forms';
import {
  getLoggedInUserData,
  getNewRandom,
  isTokenExpired,
} from './login/login-utils';
import { map } from 'rxjs/operators';
import { Constants } from '../constants';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticatedUser$ = new BehaviorSubject<ILoggedInUser>(null);
  fullName$ = new BehaviorSubject<string>(null);
  enableLinkedInImport = new FormControl(false);
  tokenSubscription = new Subscription();
  refreshToken$ = new BehaviorSubject<any>(null);
  isBasicLogout = new BehaviorSubject<boolean>(false);

  signOut(): void {
    this.authenticatedUser$.next(null);
    this.tokenSubscription.unsubscribe();
    localStorage.removeItem(Constants.StorageKeys.fullname);
    sessionStorage.clear();
    Auth.signOut().then(() => {
      window.location.reload();
    });
  }

  signIn(email, password): Promise<ICredentials> {
    return Auth.signIn(email, password);
  }

  federatedSignIn = (): Promise<ICredentials> => {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  async refreshToken(): Promise<void> {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err, session) => {
          if (err) {
            console.error('Unable to refresh Token', err);
            sessionStorage.setItem(Constants.StorageKeys.tokenExpired, 'true');
            this.signOut();
          }

          const newUserData = {
            ...this.authenticatedUser$.getValue(),
            idToken: session.idToken?.jwtToken,
            refreshToken: session.refreshToken?.token,
            expiry: session.accessToken?.payload.exp,
          };

          this.authenticatedUser$.next(newUserData);
          this.refreshToken$.next(session.accessToken?.payload);
        },
      );
    } catch (e) {
      console.log('Unable to refresh Token', e);
      sessionStorage.setItem(Constants.StorageKeys.tokenExpired, 'true');
      this.signOut();
    } finally {
      // send signal to other tabs if any
      localStorage.setItem(
        Constants.StorageKeys.tokenRefreshExecutor,
        getNewRandom(),
      );
    }
  }

  reassignTokens() {
    const timerId = setTimeout(() => {
      clearTimeout(timerId);

      Auth.currentSession().then((currentSession: CognitoUserSession) => {
        const newUserData = {
          ...this.authenticatedUser$.getValue(),
          idToken: currentSession.getIdToken().getJwtToken(),
          refreshToken: currentSession.getRefreshToken().getToken(),
          expiry: currentSession.getAccessToken().payload.exp,
        };

        this.authenticatedUser$.next(newUserData);
        this.refreshToken$.next(currentSession.getAccessToken()?.payload);
      });
    }, 3000);
  }

  setLoggedInUser = (user: ILoggedInUser) => {
    this.authenticatedUser$.next(user);
  };

  getLoggedInUser = async (): Promise<ILoggedInUser | null> => {
    if (this.authenticatedUser$.getValue()) {
      return this.authenticatedUser$.getValue();
    }

    try {
      const cognitoUser: ICognitoUser | null =
        await Auth.currentAuthenticatedUser();
      return cognitoUser ? getLoggedInUserData(cognitoUser) : null;
    } catch (e) {
      console.warn(`Error while fetching logged in user: ${e}`);
      return null;
    }
  };

  isAuthenticated = async (): Promise<boolean> => {
    const loggedInUser = await this.getLoggedInUser();

    if (loggedInUser) {
      return !isTokenExpired(loggedInUser.expiry);
    }

    return false;
  };

  userHasRole = async (role: string): Promise<boolean> => {
    const user = await this.getLoggedInUser();
    return !!user && user.userRoles?.includes(role);
  };

  userHasRole$ = (role: string): Observable<boolean> => {
    return this.authenticatedUser$.pipe(
      map((user) => !!user && user.userRoles?.includes(role)),
    );
  };

  userCanPerformAction = async (roleActions: string[]): Promise<boolean> => {
    const user = await this.getLoggedInUser();
    return !!user && roleActions.some((r) => user.userRoles?.includes(r));
  };

  userCanPerformAction$ = (roleActions: string[]): Observable<boolean> => {
    return this.authenticatedUser$.pipe(
      map(
        (user) =>
          !!user && roleActions.some((r) => user.userRoles?.includes(r)),
      ),
    );
  };
}
