import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechnicalSkillsModalComponent } from './technical.skills.modal';
import {
  ButtonModule,
  IconModule,
  TooltipModule,
  RenderingModule,
  ChipsModule,
} from '@lss/lss-ui';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [TechnicalSkillsModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    IconModule,
    MatButtonToggleModule,
    TooltipModule,
    RenderingModule,
    ChipsModule,
  ],
  exports: [TechnicalSkillsModalComponent],
})
export class TechnicalSkillsModalModule {}
