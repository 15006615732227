<div class="item-container">
  <lss-icon
    editable-item-add
    class="decorative-icon item-title-icon"
    [svgIcon]="'certificate'"
  ></lss-icon>
  <lss-text
    #focusField
    [label]="'Certificate'"
    [formControl]="formGroup.controls.certificate"
    placeholder="Ex. Microsoft Azure"
    maxLength="360"
  ></lss-text>
  <lss-text
    [label]="'Institution'"
    [formControl]="formGroup.controls.institution"
    placeholder="Ex. Sunset Learning Institute"
    maxLength="256"
  ></lss-text>
  <lss-range-picker
    [formGroup]="formGroup.controls.period"
    [endDateOptional]="true"
    [allowPresent]="false"
    [futureYears]="20"
  ></lss-range-picker>
  <lss-text
    [label]="'Notes'"
    [formControl]="formGroup.controls.notes"
  ></lss-text>
</div>
