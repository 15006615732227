import {
  Component,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from '../base.control';

@Component({
  selector: 'lss-number',
  template: `
    <mat-form-field appearance="standard">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <input
        type="text"
        name="number-input"
        digitOnly
        [min]="minValue"
        [max]="maxValue"
        matInput
        [(ngModel)]="value"
        autocomplete="off"
        [required]="required"
        [placeholder]="placeholder"
      />
      <mat-error *ngIf="!hideError">Field is mandatory</mat-error>
    </mat-form-field>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberComponent extends BaseControl {
  @Input() placeholder: string;
  @Input() decimal = true;
  @Input() maxValue?: number;
  @Input() minValue?: number;
  @Input() hideError: boolean;
}
