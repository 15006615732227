import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Constants } from '../constants';

@Component({
  selector: 'lss-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchboxComponent implements OnInit, OnDestroy {
  form = new FormGroup({
    search: new FormControl(),
  });

  destroyed$ = new Subject();

  constructor(
    protected router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    router.events.subscribe(() => this.handleClear());
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        tap((e) =>
          this.form.controls.search.setValue(e[Constants.Search.SearchTerm]),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  submit(value: string): void {
    sessionStorage.setItem(Constants.StorageKeys.loadState, 'false');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([Constants.Routes.Search], {
      queryParams: { [Constants.Search.SearchTerm]: value },
    });
  }

  handleClear(): void {
    this.form.reset();
  }
}
