import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, TextModule, IconModule } from '@lss/lss-ui';
import { PageNotFoundComponent } from './not-found.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [
    CommonModule,
    IconModule,
    TextModule,
    ButtonModule,
    MatProgressBarModule,
  ],
  exports: [PageNotFoundComponent],
})
export class PageNotFoundModule {}
