import {
  animation,
  animate,
  style,
  AnimationBuilder,
  AnimationMetadata,
} from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { UiConstants } from '../../ui-constants';

export const enterAnimation = animation([
  style({ opacity: 0 }),
  animate('500ms ease-in'),
]);

export const leaveAnimation = animation([
  animate('500ms ease-out', style({ opacity: 1 })),
]);

function animateWrapper(
  animationBuilder: AnimationBuilder,
  element: any
): (
  animationMetadata: AnimationMetadata | AnimationMetadata[]
) => Observable<any> {
  return (animationMetadata: AnimationMetadata | AnimationMetadata[]) => {
    if (!animationBuilder) {
      throw new Error('Unable to construct animation: no builder provided.');
    }

    if (!element) {
      throw new Error('Unable to animate an element: none was provided.');
    }

    const subject$ = new Subject();
    const player = animationBuilder.build(animationMetadata).create(element);

    player.onDone(() => {
      subject$.next(true);
    });
    player.play();

    return subject$;
  };
}

export function animateCreate(
  animationBuilder: AnimationBuilder,
  element: any
): Observable<any> {
  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  return animateWrapper(
    animationBuilder,
    element
  )([
    style({ opacity: 0, height: '0px' }),
    animate(
      UiConstants.Animation.Duration.slideInDuration,
      style({ height: '*' })
    ),
    animate(
      UiConstants.Animation.Duration.fadeInDuration,
      style({ opacity: 1 })
    ),
  ]);
}

export function animateRemove(
  animationBuilder: AnimationBuilder,
  element: any
): Observable<any> {
  return animateWrapper(
    animationBuilder,
    element
  )([
    style({ opacity: 1, height: '*' }),
    animate(
      UiConstants.Animation.Duration.fadeOutDuration,
      style({ opacity: 0 })
    ),
    animate(
      UiConstants.Animation.Duration.slideOutDuration,
      style({ height: '0px' })
    ),
  ]);
}
