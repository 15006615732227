import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { IPeriod, IPeriodItem } from '@lss/lss-types';
import { addYears, getDate, getTimestamp, isNil } from '../../utils';
import { BaseControl } from '../base.control';
import { RangePickerPopupComponent } from './range-picker-popup.component';
import { Router } from '@angular/router';
import { UiConstants } from '../../ui-constants';
import { ErrorService } from '../../../../../lss/src/app/error/error.service';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'lss-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: RangePickerComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangePickerComponent
  extends BaseControl
  implements OnInit, OnDestroy, OnChanges
{
  @Input() allowPresent = true;
  @Input() endDateOptional = false;
  @Input() futureYears = 0;
  @Input() minStartYear = 1970;
  @Input() periodInvalid = '';
  @Input() error: string;
  @Input() formGroup: FormGroup;
  @Input() required: boolean;
  @Input() hideError: boolean;
  @Input() isCourse: boolean;

  @ViewChild('calendarInputElement')
  calendarInputElement!: ElementRef<HTMLInputElement>;

  overlayRef: OverlayRef;
  destroyed$ = new Subject();
  displayValue: string;
  period: IPeriod;
  manualInputPeriod: IPeriod;
  matcher = new RangePickerErrorStateMatcher();
  dateError$ = new BehaviorSubject<string>('');
  setDisabledState = null;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    protected elementRef: ElementRef,
    private overlay: Overlay,
    protected cd: ChangeDetectorRef,
    private router: Router,
    protected injector: Injector,
    public errorService: ErrorService,
  ) {
    super(elementRef, cd, injector);
  }

  ngOnInit(): void {
    if (this.formGroup && this.formGroup.value) {
      this.period = this.getPeriod(this.formGroup.value);
      this.displayValue = this.getDisplayValue(this.period);
      this.applyPeriod(this.period);
      this.formGroup.markAsPristine();

      this.cd.detectChanges();

      this.formGroup.valueChanges
        .pipe(
          tap((value) => {
            // reset errors
            this.formGroup.setErrors(null);

            const period = this.getPeriod(value);
            if (period) {
              this.value = value;
            }

            this.period = period;
            this.displayValue = this.getDisplayValue(this.period);
            this.cd.detectChanges();
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  touched(): void {
    this.formGroup.markAsTouched();
  }

  manualInputOnChange(event: any): void {
    const userInput = this.parseUserInput(event.target.value);
    if (userInput) {
      this.error = undefined;
      this.manualInputPeriod = userInput;
      this.applyPeriod(this.manualInputPeriod);
      this.formGroup.patchValue(this.value);
      this.displayValue = this.getDisplayValue(this.manualInputPeriod);
    } else {
      this.formGroup.reset();
      this.displayValue = event.target.value;
      this.error = 'Required format MM/YYYY - MM/YYYY';
    }
    this.formGroup.markAsDirty();
    this.updateRangePickerState();
  }

  onInputTyping() {
    this.hideError = true;
  }

  onFocusOut(): void {
    this.hideError = false;
  }

  open(event): void {
    this.calendarInputElement.nativeElement.focus();
    this.formGroup.markAsTouched();
    event.stopPropagation();
    const calendarModalMarginLeft = 82.5;
    if (!this.overlayRef) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetX: calendarModalMarginLeft,
          },
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetX: calendarModalMarginLeft,
          },
        ])
        .withFlexibleDimensions(false)
        .withLockedPosition(true);

      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
      });
    }

    if (this.overlayRef.hasAttached()) {
      return;
    }

    const popupPortal = new ComponentPortal(RangePickerPopupComponent);
    const popupRef: ComponentRef<RangePickerPopupComponent> =
      this.overlayRef.attach(popupPortal);

    popupRef.instance.data = this.manualInputPeriod
      ? this.manualInputPeriod
      : this.getPeriod(this.value);
    popupRef.instance.allowPresent = this.allowPresent;
    popupRef.instance.endDateOptional = this.endDateOptional;
    popupRef.instance.futureYears = this.futureYears;
    popupRef.instance.minStartYear = this.minStartYear;

    this.displayValue = this.getDisplayValue(popupRef.instance.data);
    this.formGroup.markAsTouched();

    popupRef.instance.valueChanges$
      .pipe(
        tap((e: IPeriod) => {
          this.displayValue = this.getDisplayValue(e);
          this.applyPeriod(e);
          this.manualInputPeriod = undefined;
          this.formGroup.patchValue(this.value);
          this.formGroup.markAsDirty();

          this.updateRangePickerState();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.router.events
      .pipe(
        first(),
        tap(() => this.overlayRef.hasAttached() && this.overlayRef.detach()),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.overlayRef
      .backdropClick()
      .pipe(
        first(),
        tap(() => {
          this.overlayRef.detachBackdrop();
          this.overlayRef.detach();
        }),
      )
      .subscribe();

    this.overlayRef
      .outsidePointerEvents()
      .pipe(
        first(),
        tap(() => {
          this.overlayRef.detachBackdrop();
          this.overlayRef.detach();
        }),
      )
      .subscribe();

    this.cd.markForCheck();
  }

  computeValidity(status): void {
    if (!this.formGroup) {
      return;
    }

    if (
      status === 'VALID' &&
      ((this.period && this.period.present) ||
        (this.formGroup.errors &&
          !this.formGroup.errors['startDateGreaterThanEndDate']))
    ) {
      this.formGroup.setErrors(null);
    } else if (
      !this.formGroup.errors ||
      (this.formGroup.errors &&
        !this.formGroup.errors['startDateGreaterThanEndDate'])
    ) {
      this.formGroup.setErrors({ invalid: true });
    }
  }

  validate() {
    // reset error before validation
    this.dateError$.next('');

    const { startDate, endTimestamp, present } = this.value || {};

    const allowedStartDate = getTimestamp(new Date());
    const allowedEndDate = getTimestamp(addYears(new Date(), this.futureYears));

    // start date is missing
    if (!startDate) {
      this.dateError$.next(UiConstants.Date.MandatoryMessage);
      return { invalid: true };
    }

    // end date is missing and there is no 'Present' and optional flag;
    if (!endTimestamp && !present && !this.endDateOptional) {
      this.dateError$.next(UiConstants.Date.EndDateIsMissing);
      return { invalid: true };
    }

    // can't be greater than now or now + futureYears
    if (
      (endTimestamp > allowedEndDate && !present) ||
      startDate > allowedEndDate
    ) {
      this.dateError$.next(UiConstants.Date.InFuture);
      return { datesAreInFuture: true };
    }

    // start date can't be in the future
    if (startDate > allowedStartDate) {
      this.dateError$.next(UiConstants.Date.StartInFuture);
      return { startDateIsInFuture: true };
    }

    // start date is greater than end date
    if (endTimestamp && endTimestamp < startDate) {
      this.dateError$.next(UiConstants.Date.StartGreaterThanEndMessage);
      return { startDateGreaterThanEndDate: true };
    }

    // additional logic from view ->

    if (this.periodInvalid) {
      this.dateError$.next(this.periodInvalid);
      return { periodValidationError: this.periodInvalid };
    }

    return null;
  }

  getPeriod(periodItem: IPeriodItem): IPeriod {
    if (!periodItem) {
      return;
    }

    const startDate = periodItem.startDate
      ? getDate(periodItem.startDate)
      : null;
    const endDate = periodItem.endTimestamp
      ? getDate(periodItem.endTimestamp)
      : null;

    const period = {
      startMonth: startDate?.getMonth(),
      startYear: startDate?.getFullYear(),
      endMonth: endDate?.getMonth(),
      endYear: endDate?.getFullYear(),
      present: periodItem.present,
    } as IPeriod;

    return period;
  }

  applyPeriod(period: IPeriod): void {
    const applied = {
      startDate: 0,
      endTimestamp: 0,
      present: false,
    } as IPeriodItem;
    if (
      !isNil(period) &&
      !isNil(period.startMonth) &&
      !isNil(period.startYear) &&
      period.startMonth < 12
    ) {
      applied.startDate = getTimestamp(
        new Date(period.startYear, period.startMonth, 1),
      );
    } else {
      applied.startDate = null;
    }

    if (
      !isNil(period) &&
      !isNil(period.endMonth) &&
      !isNil(period.endYear) &&
      period.endMonth < 12
    ) {
      applied.endTimestamp = getTimestamp(
        new Date(period.endYear, period.endMonth, 1),
      );
    } else if (this.endDateOptional) {
      applied.endTimestamp = null;
    }

    if (!isNil(period) && period.present) {
      applied.present = period.present;
    }

    this.value = applied;
    this.cd.markForCheck();
  }

  getDisplayValue(period: IPeriod): string {
    if (!period) {
      return;
    }
    const startMonthName = isNil(period.startMonth)
      ? null
      : this.getMonthAsString(period.startMonth);
    const validStartDisplayValue =
      startMonthName && period.startYear
        ? `${startMonthName}/${period.startYear}`
        : period.startYear
        ? period.startYear
        : null;

    const endMonthName = isNil(period.endMonth)
      ? null
      : this.getMonthAsString(period.endMonth);
    let validEndDisplayValue =
      endMonthName && period.endYear
        ? `${endMonthName}/${period.endYear}`
        : period.endYear
        ? period.endYear
        : null;

    if (!validEndDisplayValue && !this.endDateOptional) {
      validEndDisplayValue = UiConstants.Date.Present;
    }

    let result = '';
    if (validStartDisplayValue) {
      result += `${validStartDisplayValue}`;
    }
    if (validStartDisplayValue && validEndDisplayValue) {
      result += ` - ${validEndDisplayValue}`;
    }

    return result;
  }

  updateRangePickerState(): void {
    const validationResult = this.validate();
    this.formGroup.setErrors(validationResult);
    this.cd.markForCheck();
  }

  getErrorMessage(): string {
    return this.error ?? this.dateError$.getValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['periodInvalid']) {
      if (this.periodInvalid) {
        this.computeValidity('INVALID');
      } else {
        this.computeValidity('VALID');
      }

      this.updateRangePickerState();
    }
  }

  private getMonthAsString(month: number): string {
    month += 1;
    if (month < 10) {
      return '0' + month;
    }
    return month.toString();
  }

  private parseUserInput(text: string): IPeriod {
    const userPeriod: IPeriod = {} as IPeriod;

    if (text && text.length >= 4) {
      // Allow input with year only
      const rangeParts = text.trim().split('-');

      // avoid multi-delimeted entry
      if (rangeParts.length > 2) {
        return null;
      }

      const dateAndYearRe = /^(0{1}[1-9]{1}|1[012])[./](\d{4}$)/;
      const onlyYearRe = /^\d{4}$/;
      const presentRe = /present/i;

      const startString = rangeParts[0].trim();
      if (dateAndYearRe.test(startString)) {
        const dateParts = startString.match(dateAndYearRe);
        userPeriod.startMonth = this.normalizeMonthTo0Based(dateParts[1]);
        userPeriod.startYear = +dateParts[2];
      } else if (onlyYearRe.test(startString)) {
        userPeriod.startYear = +startString;
      } else {
        return null;
      }

      // Process end date if present
      if (rangeParts.length > 1) {
        const endString = rangeParts[rangeParts.length - 1].trim();
        if (dateAndYearRe.test(endString)) {
          const dateParts = endString.match(dateAndYearRe);
          userPeriod.endMonth = this.normalizeMonthTo0Based(dateParts[1]);
          userPeriod.endYear = +dateParts[2];
        } else if (onlyYearRe.test(endString)) {
          userPeriod.endYear = +endString;
        } else if (this.allowPresent && presentRe.test(endString)) {
          userPeriod.present = presentRe.test(endString);
        } else {
          return null;
        }
      }
    }

    return userPeriod;
  }

  private normalizeMonthTo0Based(monthString: string): number {
    return Number(monthString) - 1;
  }
}

export class RangePickerErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return form && form.invalid && (form.dirty || form.touched);
  }
}
