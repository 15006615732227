import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, of, combineLatest } from 'rxjs';
import { ActivatedRoute, Data } from '@angular/router';
import { FormGroup, FormArray } from '@angular/forms';
import { tap, map, first, share, takeUntil, mergeMap } from 'rxjs/operators';
import {
  IUpdateModel,
  NavigationCommand,
  DATA_SERVICE_TOKEN,
  INavigationConfirmData,
  IDataService,
  IEmployeeCv,
} from '@lss/lss-types';
import { SimpleConfirmComponent } from './confirmation/simple-confirm/simple-confirm.component';
import { NavigationConfirmComponent } from './confirmation/navigation-confirm/navigation-confirm.component';
import { ConfirmationService } from './confirmation/confirmation.service';
import { setDataToSessionStorage } from '../utils';

@Component({ template: '' })
export abstract class BaseComponent<T> implements OnDestroy, OnInit {
  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: IDataService<T>,
    protected activatedRoute: ActivatedRoute,
    protected confirmationService: ConfirmationService,
  ) {}

  public currentRouteData$: Observable<Data> = this.activatedRoute.data;
  protected dataReady$: Observable<boolean>;
  protected readonly destroyed$ = new Subject();
  protected readonly afterSubmit$ = new Subject();

  abstract form: FormGroup | FormArray;

  abstract onDataReady?(cv: T): void;

  abstract getUpdateModel?(): IUpdateModel<T>;

  ngOnInit(): void {
    this.dataReady$ = this.dataService.cv$.pipe(
      tap(this.onDataReady),
      tap(() => {
        if (!this.form) {
          console.warn(`data received but 'form' was not initialized`);
        }
      }),
      map((e) => !!e),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public modalRemoveConfirmationFactory(
    header: string,
    body: string,
  ): Observable<boolean> {
    return modalRemoveConfirmationFactory(
      this.confirmationService,
      header,
      body,
    );
  }

  public confirmNavigation(data: INavigationConfirmData): Observable<boolean> {
    if (this.form.valid) {
      return this.confirmationService
        .confirm$(NavigationConfirmComponent, data, '535px')
        .pipe(
          mergeMap((e) => {
            return this.confirmationService.handleNavCommand(e, this.submit);
          }),
        );
    } else {
      return this.confirmationService.confirm$(
        SimpleConfirmComponent,
        data,
        '535px',
      );
    }
  }

  public processUpdateModel(model: IUpdateModel<T>): Observable<any> {
    return of(null);
  }

  public submit = (dontShowNotification = false) => {
    return submit_(
      this.getUpdateModel(),
      this.dataService,
      this.destroyed$,
      this.afterSubmit$,
      dontShowNotification,
      this.processUpdateModel,
    );
  };
}

export const submit_ = (
  rawUpdateModel,
  dataService: any,
  destroyed$: Subject<unknown>,
  afterSubmit$: Subject<unknown>,
  dontShowSuccessNotification = false,
  processUpdateModel?: (model: IUpdateModel<IEmployeeCv>) => Observable<any>,
) => {
  const updateModel = {
    cv: { ...dataService.cv$.getValue(), ...rawUpdateModel.cv },
    skills: rawUpdateModel.skills,
  };

  dataService.cvState$.next(updateModel.cv);
  const result$ = combineLatest([
    dataService.save(updateModel.cv, dontShowSuccessNotification),
    processUpdateModel ? processUpdateModel(updateModel) : of(null),
  ]).pipe(
    map(([ee]) => ee),
    first(),
    share(),
    takeUntil(destroyed$),
  );

  result$.subscribe((e) => {
    dataService.cv$.next(e);
    afterSubmit$.next(e);
    setDataToSessionStorage(e, 'cv');
    dataService.cvSaved$.next(true);
  });
  return result$;
};

export function modalRemoveConfirmationFactory(
  modalService: ConfirmationService,
  header: string,
  body: string,
  confirmButtonText?: string,
  declineButtonText?: string,
): Observable<boolean> {
  return modalService
    ? modalService.confirm$(
        SimpleConfirmComponent,
        ConfirmationService.simpleConfirmationDataFactory(
          header,
          body,
          confirmButtonText,
          declineButtonText,
        ),
        '535px',
      )
    : undefined;
}
