import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { RichTextAreaComponent } from './rich-text-area.component';

@NgModule({
  declarations: [RichTextAreaComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, QuillModule],
  exports: [RichTextAreaComponent],
})
export class RichTextAreaModule {}
