import { Pipe, PipeTransform } from '@angular/core';
import { ILookupValue } from '@lss/lss-types';

@Pipe({
  name: 'alphabeticalSort',
})
export class AlphabeticalSortPipe implements PipeTransform {
  transform(value: ILookupValue[]) {
    return value.sort((a, b) =>
      a.displayName.toLocaleLowerCase() > b.displayName.toLocaleLowerCase()
        ? 1
        : -1
    );
  }
}
