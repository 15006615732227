import { NgModule } from '@angular/core';
import { ActionsBarComponent } from './actions-bar.component';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [ActionsBarComponent],
  imports: [ButtonModule, IconModule],
  exports: [ActionsBarComponent],
})
export class ActionsBarModule {}
