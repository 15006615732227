import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken, Self } from '@angular/core';

export const LSS_OVERLAY_ID = new InjectionToken<string>('Overlay element id');

@Injectable()
export class LssOverlayContainer extends OverlayContainer {
  containerElementId: string;

  constructor(
    @Inject(DOCUMENT) document: any,
    protected platform: Platform,
    @Self() @Inject(LSS_OVERLAY_ID) containerId: any,
  ) {
    super(document, platform);
    this.containerElementId = containerId;
  }

  getContainerElement(): HTMLElement {
    const el = document.getElementById(this.containerElementId);
    return el ? el : super.getContainerElement();
  }
}
