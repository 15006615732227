<div
  [style.display]="!showTemplateHeader ? null : 'none'"
  *ngIf="authenticatedUser$ | async"
>
  <ng-container>
    <lss-toolbar
      *ngIf="qualificationFormToolbar"
      [urlRoute]="routeToForm"
    ></lss-toolbar>
    <lss-toolbar *ngIf="!qualificationFormToolbar" [urlRoute]="routeToHome">
      <lss-logged-actions></lss-logged-actions>
    </lss-toolbar>
  </ng-container>
</div>

<router-outlet></router-outlet>

<ng-container>
  <div *ngIf="version$ | async as version">
    <div class="version">
      <p>Client Version: {{ version.clientVersion }}</p>
      <p>Api Version: {{ version.apiVersion }}</p>
    </div>
  </div>

  <div class="global-retry-overlay" *ngIf="window['globalRetryOverlay']">
    <img class="logo" src="assets/svg/28stone-logo.svg" />
    <div class="contents">
      <div class="loader-animation">
        <div class="wrapper">
          <div class="press">
            <div class="holder"></div>
            <div class="lid"></div>
            <div class="carafe">
              <div class="drop"></div>
              <div class="spout"></div>
              <div class="contents">
                <div class="grounds"></div>
                <div class="water"></div>
              </div>
            </div>
            <div class="plunger">
              <div class="shaft">
                <div class="knob"></div>
              </div>
              <div class="filter"></div>
            </div>
          </div>
        </div>
      </div>
      <h1>We are setting up your account!</h1>
      <h2>
        It might take a minute or two. <br />
        Relax and make yourself a cup of coffee!
      </h2>
    </div>
  </div>
</ng-container>
<div id="lss-custom-overlay" class="cdk-overlay-container"></div>
<lss-back-to-top></lss-back-to-top>
