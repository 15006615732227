import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Constants } from '../constants';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const errorHandler = this.getErrorHandler(error);

    errorHandler();
  }

  getErrorHandler(raw: any): () => void {
    if (!raw) {
      console.log(
        'Processing error failed: returning empty error handler factory'
      );
      return () => {};
    }
    try {
      if (raw?.rejection.response) {
        return () => {
          const error = {
            status: raw.rejection.response.status,
            statusText: tryGetErrorDescription(raw.rejection.response.status),
          };

          const errorService = this.injector.get(ErrorService);

          errorService.navigateToErrorPage(error);
        };
      }
    } catch (e) {
      console.log(
        'Processing error failed: returning default error handler factory'
      );
    }

    return () => {
      console.log('We have an error:', raw);
    };
  }
}

function tryGetErrorDescription(errorCode: number | string): string {
  return (
    Constants.StatusCodeDescriptions[`E${errorCode?.toString()}`] ||
    Constants.StatusCodeDescriptions.E500
  );
}
