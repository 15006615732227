import {
  AbstractControl,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import {
  ILookupValue,
  IGroupableLookupValue,
  IPeriodItem,
  ILookupData,
  IUserObject,
  ICvCertificationItem,
  ICvWorkExperienceItem,
  IEducationItem,
  ILanguageProficiency,
  ITechnicalSkill,
  ISkillRequestData,
} from '@lss/lss-types';
import { UiConstants } from './ui-constants';
import { DatePipe } from '@angular/common';
import { Subject, BehaviorSubject } from 'rxjs';
import { Constants } from '../../../../src/lss/src/app/constants';
import { RouterStateSnapshot } from '@angular/router';

// Src: https://stackoverflow.com/a/63877129
export function isValidDate(date: Date) {
  return (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date.getTime())
  );
}

export function getUTCDateWithoutTime(date: Date) {
  const newDate = isValidDate(date) ? date : new Date(date);
  return new Date(
    Date.UTC(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      0,
      0,
      0,
      0,
    ),
  );
}

export function getTimestamp(date?: Date): number {
  if (!date) {
    return Math.floor(new Date().getTime() / 1000);
  }

  return (
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds(),
    ) / 1000
  );
}

export function getDate(timestamp: number): Date {
  return new Date(timestamp * 1000);
}

export function addYears(date: Date, years: number): Date {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(dateCopy.getFullYear() + years);
  return dateCopy;
}

export function tryGetDate(timestamp: number | string): Date {
  if (timestamp === 'Present') {
    return new Date();
  }
  return timestamp !== undefined && timestamp !== null
    ? getDate(timestamp as number)
    : null;
}

export function tryGetDateString(timestamp: number | string): string {
  if (timestamp === 'Present') {
    return timestamp;
  }

  if (timestamp === undefined || timestamp === null) {
    return '';
  }

  const datePipe = new DatePipe('en-US');
  const date = getDate(timestamp as number);
  return datePipe.transform(date, UiConstants.Date.DefaultFormat);
}

export function buildDate(
  startTimeStamp: number,
  present: boolean,
  endTimeStamp: number | string,
): string {
  if (!startTimeStamp || (!endTimeStamp && !present)) {
    return '';
  }
  return `${tryGetDateString(startTimeStamp)} - ${
    present ? 'Present' : tryGetDateString(endTimeStamp)
  }`;
}

export function getDateRangeString(period: IPeriodItem) {
  const startDateString = tryGetDateString(period.startDate);
  let endDateString = tryGetDateString(period.endTimestamp);

  if (!numberHasValue(period.endTimestamp)) {
    endDateString = period.present ? UiConstants.Date.Present : '';
  }

  if (!startDateString || !endDateString) {
    return '';
  }
  if (endDateString === '') {
    return `${startDateString}`;
  }

  return `${startDateString} - ${endDateString}`;
}

export function resetDate(date: Date): Date {
  date.setDate(1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function getValidators(validatable: { validator: any }): any {
  return validatable ? validatable.validator({} as AbstractControl) : {};
}

export function tryGetLookupKey(lookupValue: ILookupValue): string {
  return lookupValue && lookupValue.key;
}

export function getSkillRequestData(
  lookupValue: ILookupValue,
): ISkillRequestData {
  return lookupValue
    ? {
        key: lookupValue.key,
        skill: lookupValue.displayName,
      }
    : null;
}

export function tryGetLookupDisplayName(lookupValue: ILookupValue): string {
  return lookupValue && lookupValue.displayName;
}

export function containsLookup(
  lookupValue: ILookupValue,
  lookupList: Array<ILookupValue | string>,
): boolean {
  return !!lookupList?.find((ee) =>
    typeof ee !== 'string'
      ? compareToNewLookup(ee, lookupValue)
      : compareToRawInput(lookupValue, ee),
  );
}

export function compareToRawInput(
  existingLookup: ILookupValue,
  rawInput: string,
): boolean {
  return existingLookup.displayName === rawInput;
}

export function compareToNewLookup(
  existingLookup: ILookupValue,
  newLookup: ILookupValue,
): boolean {
  return existingLookup?.key === newLookup.key;
}

export function groupLookupValues(
  lookupValues: ILookupValue[],
  categories: ILookupValue[],
): IGroupableLookupValue[] {
  const intermediateGroup = new Map<string, ILookupValue[]>();

  for (const category of categories) {
    intermediateGroup.set(category.key, []);
  }

  for (const lookupValue of lookupValues) {
    intermediateGroup.get(lookupValue.group)?.push(lookupValue);
  }

  return [...intermediateGroup.entries()].map(([key, value]) => ({
    groupName: key,
    options: value,
  })) as IGroupableLookupValue[];
}

export function creatingEditableItemDecorator(
  formGroup: FormGroup,
  model: any = null,
): void {
  if (!formGroup || model) {
    return;
  }

  formGroup.addControl(
    UiConstants.Animation.animationStateControlName,
    new FormControl(UiConstants.Animation.State.Creating),
  );
}

export function isNil(value: any): boolean {
  return value === null || value === undefined;
}

export function stripHTML(value: string): string {
  return value ? value.replace(/(<([^>]+)>)/gi, '') : value;
}

export function getBase64(file: Blob) {
  const subject = new Subject();
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = function () {
    subject.next(reader.result);
  };

  return subject.asObservable();
}

export function getLastUsedValue(lastUsed) {
  if (typeof lastUsed === 'string') {
    lastUsed = parseInt(lastUsed);
  }

  if (typeof lastUsed === 'object') {
    lastUsed = parseInt(lastUsed.displayName);
  }
  return lastUsed;
}

export function getPeriodFormGroup(
  startDate: number,
  endTimestamp: number,
  present: boolean,
  disabled = false,
  groupValidators = [],
) {
  if (numberHasValue(endTimestamp)) {
    present = false;
  }
  const form = new FormGroup(
    {
      startDate: new FormControl({ value: startDate, disabled }, [
        Validators.required,
      ]),
      endTimestamp: new FormControl({ value: endTimestamp, disabled }, [
        Validators.required,
      ]),
      present: new FormControl({ value: present, disabled }, [
        Validators.required,
      ]),
    },
    groupValidators,
  );

  if (disabled) {
    form.disable();
  }

  return form;
}

export function getPeriodFormGroupWithAdditionalValidator(
  startDate: number,
  endTimestamp: number,
  present: boolean,
  disabled = false,
  startDateValidators = [],
  endDateValidators = [],
  groupValidators = [],
) {
  return new FormGroup(
    {
      startDate: new FormControl(
        { value: startDate, disabled },
        startDateValidators,
      ),
      endTimestamp: new FormControl(
        { value: endTimestamp, disabled: false },
        endDateValidators,
      ),
      present: new FormControl({ value: present, disabled }),
    },
    groupValidators,
  );
}

export function setDataToLocalStorage(userKey, value, dataParam) {
  const userObject = JSON.parse(
    localStorage.getItem(userKey) ?? Constants.Empty.Object,
  ) as IUserObject;
  userObject[dataParam] = value;
  localStorage.setItem(userKey, JSON.stringify(userObject));
}

export function setDataToSessionStorage(value, key) {
  const userKey = Constants.User.UserObjectKeyFactory(key);
  sessionStorage.setItem(userKey, JSON.stringify(value));
}

export function getDataFromSessionStorage(key) {
  const userKey = Constants.User.UserObjectKeyFactory(key);
  return JSON.parse(sessionStorage.getItem(userKey));
}

export function removeDataFromSessionStorage(key) {
  const userKey = Constants.User.UserObjectKeyFactory(key);
  sessionStorage.removeItem(userKey);
}

export function isObjectEmpty(obj: any): boolean {
  return !!Object.values(obj).every((element) => !element);
}

export function regroupLookups(
  lookupData$: BehaviorSubject<ILookupData>,
): void {
  const lookupData = lookupData$.getValue();

  if (!lookupData) {
    console.warn('Regrouping lookups failed: lookup data not defined.');

    return;
  }

  lookupData.skillGroups = groupLookupValues(
    lookupData.skills,
    lookupData.categories,
  );
  lookupData.languageProficiencies = lookupData.languageProficiencies?.sort(
    (a, b) => a.displayName?.localeCompare(b.displayName) || 0,
  );
  lookupData.skillProficiencies = lookupData.skillProficiencies?.sort(
    (a, b) => a.displayName?.localeCompare(b.displayName) || 0,
  );

  lookupData$.next(lookupData);
}

export function validateTechnicalSkills(skills: ITechnicalSkill[]): boolean {
  return skills?.every(
    (s) => s.skill && s.lastUsed && s.yearsOfExperience && s.skillProficiency,
  );
}

export function validateWorkExperience(work: ICvWorkExperienceItem[]): boolean {
  if (work?.length === 1) {
    const emptyWorkExperienceItem = structuredClone(work[0]);
    emptyWorkExperienceItem.roles = null;
    if (
      isObjectEmpty(emptyWorkExperienceItem) &&
      !!work[0].roles.every((r) => isObjectEmpty(r))
    ) {
      return true;
    }
  }

  return work?.every(
    (w) =>
      w.company &&
      w.startDate &&
      w.roles?.every(
        (r) => r.title && r.startDate && (r.present || r.endTimestamp),
      ),
  );
}

export function validateEducation(education: IEducationItem[]): boolean {
  const validEducationItems = education?.every(
    (e) =>
      e.institution &&
      e.location &&
      e.degrees?.every(
        (d) =>
          d.degreeOrSpecialization &&
          d.startDate &&
          (d.endTimestamp || d.present),
      ),
  );
  return validEducationItems || education?.length === 0;
}

export function validateLanguages(language: ILanguageProficiency[]): boolean {
  const validLanguageItems = language?.every(
    (l) => l?.language && l?.proficiency,
  );
  return validLanguageItems || language?.length === 0;
}

export function validateCertifications(
  certification: ICvCertificationItem[],
): boolean {
  const validCertificationItems = certification?.every(
    (c) => c.certificate && c.validFrom,
  );
  return validCertificationItems || certification?.length === 0;
}

/**
 * Checks if only one of the two arguments is truthy
 *
 * @param {boolean} a First value
 * @param {boolean} b Second value
 * @return {boolean} Returns `true` if only one of the arguments is true. Otherwise returns `false`
 */
export function oneIsTrue(a: boolean, b: boolean): boolean {
  return a !== b && (a || b);
}

export function isLoggingOut(nextRoute: RouterStateSnapshot): boolean {
  return nextRoute?.url === `/${Constants.Routes.Login}`;
}

/**
 * Sorts array by certain key and returns same sorted array
 */
export function sortArray(
  array: unknown[],
  key: string,
  direction: 'asc' | 'desc',
): unknown[] {
  let sortedAsc = [];
  let fieldDataType = 'string';

  // extract column data type
  if (array.length) {
    fieldDataType = typeof array[0][key];
  } else {
    return [];
  }

  switch (fieldDataType) {
    case 'number':
      sortedAsc = array.sort((a: number, b: number) => {
        return a[key] - b[key];
      });
      break;

    // add additional type if needed

    case 'object':
      // means some custom fields where value is LookupValue type - like key and displayValue
      // use it's displayValue for sorting
      sortedAsc = array.sort((a: unknown, b: unknown) => {
        const first = isNil(a[key]?.displayName) ? '' : a[key].displayName;
        const second = isNil(b[key]?.displayName) ? '' : b[key].displayName;
        return first.toString().localeCompare(second.toString());
      });
      break;

    default:
      sortedAsc = array.sort((a: unknown, b: unknown) => {
        // If value is Nil aka null/undefined then converting it to
        // empty string in order to avoid errors
        const first = isNil(a[key]) ? '' : a[key].toLowerCase();
        const second = isNil(b[key]) ? '' : b[key].toLowerCase();
        // Handling cases when value starts with accented character
        return compareAccentedCharacters(first.toString(), second.toString());
      });
  }

  return direction === 'asc' ? sortedAsc : sortedAsc.reverse();
}

export function compareAccentedCharacters(a: string, b: string): number {
  const collator = new Intl.Collator(undefined, { sensitivity: 'base' });
  const normalizedA = a.normalize('NFD');
  const normalizedB = b.normalize('NFD');

  if (normalizedA < normalizedB) {
    return -1;
  } else if (normalizedA > normalizedB) {
    return 1;
  } else {
    return collator.compare(a, b);
  }
}

export function numberHasValue(num: number): boolean {
  return num !== 0 && num !== null && num !== undefined;
}
