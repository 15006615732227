import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { BaseControl } from '../base.control';
import { ILookupValue } from '@lss/lss-types';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lss-select',
  template: `
    <mat-form-field>
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <mat-select
        #selectComponent
        [(ngModel)]="value"
        [required]="required"
        [compareWith]="compareFn"
        [multiple]="multiple"
        [placeholder]="placeholder"
        (blur)="handleBlur($event)"
      >
        <mat-option
          *ngFor="let option of options | keyvalue : originalOrder"
          [value]="option.value"
          >{{ option.value.displayName }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="!hideError">Field is mandatory</mat-error>
    </mat-form-field>
  `,
  styles: [
    'mat-form-field { width: inherit; } ::ng-deep .mat-select-value{ height:20px!important;  } mat-select { font-size: 15px; }',
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends BaseControl {
  @Input() options: ILookupValue[];
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() hideError: boolean;

  @ViewChild('selectComponent')
  selectComponent: MatSelect;

  compareFn(c1: ILookupValue, c2: ILookupValue): boolean {
    return c1 && c2 ? c1.key === c2.key : c1 === c2;
  }

  originalOrder = () => {
    return 0;
  };

  handleBlur(event: FocusEvent): void {
    if (
      !event.relatedTarget ||
      (event.relatedTarget as Node).nodeName !== 'MAT-OPTION'
    ) {
      this.selectComponent.close();
    }
  }
}
