<ng-container *ngIf="currentRouteData$ | async as currentRouteData">
  <div class="cv-wrapper">
    <lss-form-card
      *ngIf="dataReady$ | async"
      [subtitle]="currentRouteData.subtitle"
      [title]="currentRouteData.title"
      [form]="form"
      [formSubmit]="submit"
      [isCandidate]="authService.userHasRole$(candidate) | async"
      (submitToHR)="submitToHR()"
      [cv]="dataService.cv$ | async"
      [cvSaved]="dataService.cvSaved$ | async"
    >
      <lss-actions-bar
        id="'addCompanyTop'"
        (add)="addCompanyToStart()"
        [label]="'Add Company'"
      ></lss-actions-bar>

      <lss-editable-item
        #editableItem
        *ngFor="
          let formControl of form.controls;
          let index = index;
          let first = first;
          let last = last
        "
        class="work-experience"
        (remove)="remove(form, index)"
        [nesting]="0"
        [state]="formControl.controls.animationState?.value"
        [getRemoveConfirmation$]="getRemoveConfirmation(index)"
      >
        <lss-icon
          *ngIf="!formControl.controls.permanent && !first"
          [label]="'Remove Company'"
          editable-item-remove
          >close</lss-icon
        >
        <lss-mover
          editable-item-mover
          *ngIf="form.controls?.length > 1"
          [canMoveUp]="canMoveUp(first, index)"
          [canMoveDown]="canMoveDown(last, index)"
          (moveUp)="moveUp(editableItem.elementRef.nativeElement, index)"
          (moveDown)="moveDown(editableItem.elementRef.nativeElement, index)"
        >
        </lss-mover>

        <lss-work-experience-item
          editable-item-body
          [formGroup]="formControl"
          [focusElement]="index === focusedIndex"
        ></lss-work-experience-item>
      </lss-editable-item>

      <lss-actions-bar
        id="'addCompanyBottom'"
        (add)="append()"
        [label]="'Add Company'"
      ></lss-actions-bar>
    </lss-form-card>
  </div>
</ng-container>
