import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../../constants';

@Component({
  selector: 'lss-tips-and-tricks',
  templateUrl: './tips-and-tricks.component.html',
  styleUrls: ['./tips-and-tricks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipsAndTricksComponent {
  routeToHome = Constants.Routes.Home;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    this.scrollToElement(params.pageBlock);
  }

  scrollToElement(elementId?: string) {
    setTimeout(() => {
      if (elementId) {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }, 100);
  }
}
