import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditableItemComponent } from './editable-item.component';
import { EditableItemTableHeaderComponent } from './editable-item-table-header.component';
import { ButtonModule } from '../button/button.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { RenderingModule } from '../rendering/rendering.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [EditableItemComponent, EditableItemTableHeaderComponent],
  imports: [
    CommonModule,
    ButtonModule,
    MatCardModule,
    RenderingModule,
    MatDialogModule,
  ],
  exports: [EditableItemComponent, EditableItemTableHeaderComponent],
})
export class EditableItemModule {}
