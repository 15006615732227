import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipAnimatedComponent } from './tooltip-animated.component';
import { TooltipHandlerDirective } from './tooltip-handler.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { TooltipWrapperComponent } from './tooltip-wrapper/tooltip-wrapper.component';

@NgModule({
  imports: [CommonModule, MatDialogModule],
  declarations: [TooltipAnimatedComponent, TooltipHandlerDirective, TooltipWrapperComponent],
  exports: [TooltipAnimatedComponent]
})
export class TooltipAnimatedModule {}
