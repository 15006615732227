<div class="search">
  <form [formGroup]="form">
    <input
      id="search"
      name="search"
      type="text"
      matInput
      placeholder="Search"
      class="searchbox"
      [formControl]="form.controls.search"
      (keyup.enter)="submit(form.controls.search.value)"
    />
    <lss-icon
      matSuffix
      class="searchIcon"
      (click)="submit(form.controls.search.value)"
      >search</lss-icon
    >
  </form>
</div>
