import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NotificationComponent } from './notification.component';
import { NotificationMode } from '@lss/lss-types';

export type OpenFunctionProps = {
  title: string;
  body: string;
  mode: NotificationMode;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  open({ title, body, mode }: OpenFunctionProps): void {
    this.snackBar.openFromComponent(NotificationComponent, {
      duration: 3000,
      panelClass: mode?.toLowerCase(),
      data: { title, body, mode, close: () => this.snackBar.dismiss() },
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
