<ng-container *ngIf="currentRouteData$ | async as currentRouteData">
  <ng-container *ngIf="dataReady$ | async">
    <div class="rows-counter">
      <lss-skill-bar [form]="form"></lss-skill-bar>
    </div>
  </ng-container>

  <div class="cv-wrapper">
    <lss-form-card
      *ngIf="dataReady$ | async"
      class="technical-skills"
      [title]="currentRouteData.title"
      [subtitle]="currentRouteData.subtitle"
      [desiredSkillChanged]="desiredSkillChanged"
      [form]="form"
      [formSubmit]="submit"
      (submitToHR)="submitToHR()"
      (formHasChanged)="formHasChanged($event)"
      [isCandidate]="authService.userHasRole$(candidate) | async"
      [cv]="dataService.cv$ | async"
      [cvSaved]="dataService.cvSaved$ | async"
    >
      <lss-technical-skill-card
        [nesting]="'hidden'"
        [title]="'Existing skills'"
        [proficiencyOptions]="proficiencyOptions"
        [skillGroups]="skillGroups"
        [lookupData]="lookUpData"
        (onAdd)="handleTechnicalCard($event)"
        [disableSave]="true"
        [storageTechnicalSkill$]="storageSavedTechnicalSkills$"
        [form]="form"
      >
        <mat-card id="desiredSkillsCard">
          <div id="desiredSkillsHeader2">
            <mat-card-title class="title">
              Skills you want to learn
            </mat-card-title>
            <mat-card-subtitle class="subtitle">
              Use this section to pick skills that you are interested in
              learning. You can add a new skill by typing in the name and
              pressing “Enter”.
            </mat-card-subtitle>
          </div>
          <lss-autocomplete
            [excludeOptions]="desiredSkillsWithDupes$ | async"
            [formControl]="desiredSkill"
            [groups]="skillGroups"
            [options]="skills"
            [searchFromStart]="false"
            [placeholder]="'Skills you want to learn'"
            [focusedPlaceholder]="'Type skills you want to learn'"
            (selected)="addDesiredSkill()"
            [enterNewValueMode]="true"
          ></lss-autocomplete>
          <mat-chip-listbox #chipList>
            <mat-chip-option
              *ngFor="let option of alreadySelectedDesiredSkills$ | async"
              [selectable]="false"
              [removable]="true"
              (removed)="removeDesiredSkill(option)"
            >
              {{ option?.displayName || option }}
              <lss-icon matChipRemove>cancel</lss-icon>
            </mat-chip-option>
          </mat-chip-listbox>
        </mat-card>
      </lss-technical-skill-card>
    </lss-form-card>
  </div>
</ng-container>
<div id="lss-technical-custom-overlay" class="cdk-overlay-container"></div>
