export const UiConstants = {
  Date: {
    DefaultFormat: 'MM/yyyy',
    Months: [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ],
    Present: 'Present',
    InvalidMessage: 'Date is invalid',
    MandatoryMessage: 'Mandatory date missing',
    StartGreaterThanEndMessage: 'Start date is after end date',
    OutOfRangeMessage: 'Date interval is out of workplace date interval',
    InFuture: 'Start and End date cannot be in the future',
    StartInFuture: 'Start date cannot be in the future',
    EndDateIsMissing: 'End date is required',
  },
  Attributes: {
    dateformat: 'dateformat',
  },
  Button: {
    Color: {
      BasicPrimary: 'basic-primary',
      LinkPrimary: 'link-primary',
      Primary: 'primary',
      Icon: 'icon',
      Basic: 'basic',
    },
  },
  Animation: {
    animationStateControlName: 'animationState',
    State: {
      Ready: 'ready',
      Creating: 'creating',
      RemovingFadeOut: 'removingFadeOut',
      RemovingSlideOut: 'removingSlideOut',
    },
    Duration: {
      slideInDuration: 200,
      fadeInDuration: 300,
      fadeOutDuration: 200,
      slideOutDuration: 300,
      afterFadeOutDuration: 500,
    },
  },
  TechnicalSkills: {
    Title: 'Technical Skills',
  },
};
