import { FormControl } from '@angular/forms';

export class LssValidators {
  static isNumber(control: FormControl): { [key: string]: boolean } {
    if (
      /^\d+$/.test(control.value) === false &&
      typeof control.value === 'string'
    ) {
      return { isNumber: true };
    } else {
      return null;
    }
  }
}
