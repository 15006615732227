<mat-card>
  <div>
    <img
      class="logo"
      alt="28 stone"
      aria-label="28 stone logo"
      src="../../../assets/svg/28stone-logo.svg"
    />
  </div>

  <br />

  <div class="content">
    <h1>Privacy Policy</h1>

    <p>
      Hereby the job applicant (“<b>you</b>”) is informed how the controllers
      described below uses Your personal data within the SkillSet application
      available at
      <a href="http://skillset.28stone.com" target="_blank">
        http://skillset.28stone.com</a
      >
      or
      <a href="https://skillset.28stone.cloud" target="_blank">
        https://skillset.28stone.cloud</a
      >.
    </p>

    <h3>A. Controllers</h3>

    <p>
      Depending on who is your potential or current employer, the controller of
      your personal data can be one or both of these companies:
    </p>

    <ul>
      <li>
        a company “<b>28Stone Riga</b>” (Sabiedrība ar ierobežotu atbildību
        “28Stone Riga”) with registration number 40103612535, and registered
        address at Rīga, Gustava Zemgala gatve 74, LV-1039, or
      </li>
      <li>
        a company <b>28Stone Consulting</b>, Inc with registration number
        0101015974, and registered address at 21 Yale Street, Maplewood, NJ
        07040, ASV.
      </li>
    </ul>

    <p class="text-center">
      Both of the companies jointly or separately further referred as
      “<b>Company</b>” or “<b>we</b>”
    </p>

    <p>
      Please be informed that we are the controller of Your personal data
      mentioned in this Privacy Policy (“<b>Privacy Policy</b>”).
    </p>

    <p>
      The summary of our personal data processing activities can be found at the
      end of the document.
    </p>

    <h3>B. Collected Personal Data</h3>

    <p>The information that we may receive falls into the following types:</p>

    <ul>
      <li>
        <b>Basic information</b>: job title, given name, surname, email address,
        gender, phone number, mobile phone number, address, postal code,
        citizenship.
      </li>
      <li>
        <b>Education Information</b>: school name, graduation type, education
        degree, certifications and qualifications, additional trainings.
      </li>
      <li>
        <b>Experience Information</b>: organization, position, job description,
        previous experience, references (if provided), skills, cover
        letter-related information.
      </li>
      <li>
        <b>Web Information</b>: links to social media profiles and information
        stored within such profiles, publicly accessible information on you
        related to recruitment.
      </li>
      <li>
        <b>Technical Information</b>: information related to using our web-page,
        recruitment portal and related profiles.
      </li>
    </ul>

    <h3>C. How Personal Data is Used</h3>

    <p>We use your information for:</p>

    <ul>
      <li>
        searching for job candidates and examining whether they meet our
        requirements;
      </li>
      <li>
        managing your account, if you create one by applying for Company’s open
        vacancy or if you are already employed by Company;
      </li>
      <li>
        managing your existing employment relationships with Company for human
        resources purposes;
      </li>
      <li>
        protecting and ensuring our legitimate interests, including:
        <ul>
          <li>ensuring the security of our digital and physical resources;</li>
          <li>
            ensuring that we have necessary recruitment personal data about you
            for at least 1 year after the submission of your application.
          </li>
        </ul>
      </li>
    </ul>

    <h3>D. Legal Basis for Using Personal Data</h3>

    <p>
      <b>Consent</b>: where you provide it for specific cases, for example, for
      collecting references or storing application data longer than for 1 year.
    </p>
    <p>
      <b>Legitimate interests of Company or third parties</b>: such interests
      might include looking for an appropriate candidate, maintaining and
      managing employment relationships with you, ensuring security of our
      resources and colleagues, having necessary information on the recruitment
      for a corresponding term, legal and financial interests as well as other
      relevant interests.
    </p>

    <h3>E. Your Personal Data Recipients and Sources</h3>

    <p>
      We may <b>share</b> your personal data, with the following third parties
      in the following circumstances:
    </p>

    <ul>
      <li>
        <b>Our group companies</b>: as our group consists of several companies,
        we can transfer your personal data to other related companies for the
        purposes stated in this Privacy Policy, including where there is
        recruitment or talent management process common for several group
        companies.
      </li>
      <li>
        <b>Third-Party service providers</b>: we may share your personal data
        with third-party service providers for example, IT services providers,
        data storage services providers, recruitment companies.
      </li>
      <li>
        <b>When required by competent authorities</b>: where required by law,
        court order or other government or law enforcement authorities in order
        to comply with legal requirements.
      </li>
      <li>
        <b>In relation to corporate processes</b>: in case of any reorganization
        of our business we may need to disclose information to a potential buyer
        and its professional advisers.
      </li>
      <li>
        <b>In the process of engaging with clients</b> we may share your profile
        with existing or prospective clients.
      </li>
      <li>
        <b>Within dispute resolution, legal proceedings and similar cases</b>:
        when we need to protect our interests, we might need to transfer your
        personal data to advisors, courts or any other relevant parties.
      </li>
    </ul>

    <p>
      You directly provide our Company with the data we collect. We collect data
      and process data when you:
    </p>

    <ul>
      <li>Create an account/profile;</li>
      <li>Visit our website;</li>
      <li>Use or view our website via your browser’s cookies.</li>
    </ul>

    <p>
      We may also <b>receive</b> Your personal data from the following sources:
    </p>

    <ul>
      <li>
        our group companies where there is a common recruitment or talent
        management process for the respective companies;
      </li>
      <li>recruitment services providers and similar services providers;</li>
      <li>
        your previous employers and other contact persons for reference
        purposes;
      </li>
      <li>
        publicly accessible resources, where such information is relevant for
        the recruitment process, such as social media profiles and other
        web-pages.
      </li>
    </ul>

    <h3>
      F. Information Transferring outside the European Union and European
      Economic Area
    </h3>

    <p>
      Where we use services providers located outside the European Union and
      European Economic Area, your personal data can be transferred to the
      corresponding third countries. Your personal data can also be transferred
      as provided above. Before transferring we will take steps to ensure
      appropriate safeguards.
    </p>
    <p>
      Depending on the circumstances, we may use special personal data
      protection safeguards set forth in the Regulation (EU) 2016/679 of the
      European Parliament and of the Council of 27 April 2016 on the protection
      of natural persons with regard to the processing of personal data and on
      the free movement of such data (“<b>GDPR</b>”), such as entering into
      standard data protection clauses or using derogations specified in the
      GDPR.
    </p>

    <p>
      For further information on how we handle and protect your personal data
      within such transfers, please contact us at
      <a [href]="'mailto:' + 'hr@28stone.com'">hr@28stone.com</a>.
    </p>

    <h3>G. Personal Data Retention Term</h3>
    <p>
      We usually store the received applications within a specific recruitment
      process. Employmentrelated data will be stored within a specific
      employment term. In certain circumstances we might store the information
      for a longer term. More detailed information is provided in the table in
      the end of the document.
    </p>
    <p>
      After the expiration of the retention term, your corresponding personal
      data will be deleted or anonymized.
    </p>

    <h3>H. Changes to Privacy Policy</h3>
    <p>
      This Privacy Policy may be updated from time to time. If we make a
      substantial change to our Privacy Policy, we will inform you via websites,
      e-mail or other means.
    </p>

    <h3>I. Contacting Us about this Privacy Policy</h3>
    <p>
      If you have any questions or comments about this Privacy Policy, please
      contact us at
      <a [href]="'mailto:' + 'hr@28stone.com'">hr@28stone.com</a> or +371
      24933824.
    </p>
    <p>
      You also have the right to contact and lodge a complaint with the national
      data protection authority (in Latvia: Datu valsts inspekcija,
      <a href="https://www.dvi.gov.lv/lv" target="_blank">
        https://www.dvi.gov.lv/lv</a
      >, +371 67223131,
      <a [href]="'mailto:' + 'pasts@dvi.gov.lv'">pasts@dvi.gov.lv</a> ) although
      we would encourage you to first contact us directly to resolve any
      concerns.
    </p>

    <h3>J. Your Personal Data Protection Rights</h3>
    <p>
      You have the right to request us to delete, access, update, and correct
      your personal data under our management and control, and to receive a copy
      (including a machine-readable copy) of your personal data, subject to
      certain exceptions prescribed by law.
    </p>
    <p>
      You also have the right to object to the manner in which we use your
      personal data (including for direct marketing purposes), restrict the
      processing of your personal data and to withdraw any consent you have
      granted us. If you withdraw your consent, this does not affect the
      lawfulness of the processing carried out prior to withdrawal of consent.
      Please note that if you withdraw your consent for the processing of your
      specific personal data, we might not be able to provide you with certain
      services, if your personal data is necessary for their provision to you.
    </p>
    <p>
      You may exercise these rights any time by writing to us at the contact
      information set out above. We may request certain personal data for the
      purposes of verifying the identity of the individual seeking access to own
      personal data records.
    </p>

    <h3>K. Summary of the Personal Data Processing Activities</h3>

    <table>
      <thead>
        <tr>
          <th><strong>Purpose</strong></th>
          <th><strong>Data</strong></th>
          <th><strong>Basis</strong></th>
          <th><strong>Term</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Searching for job candidates and examining whether they meet our
            requirements
          </td>
          <td>
            <p>Basic Information</p>
            <p>Education Information</p>
            <p>Experience Information</p>
            <p>Web Information</p>
          </td>
          <td>
            <p>Legitimate interests</p>
            Consent
          </td>
          <td>
            Within recruitment process. In case a contract is not concluded with
            a candidate, personal data may be stored for future reference unless
            explicitly asked to delete it.
          </td>
        </tr>
        <tr>
          <td>
            Managing your account, if you create one by applying for the
            Company’s open vacancy
          </td>
          <td>
            <p>Basic Information</p>
            <p>Education Information</p>
            <p>Experience Information</p>
            <p>Web Information</p>
            <p>Technical Information</p>
          </td>
          <td>Legitimate interests</td>
          <td>
            While your recruitment personal data is stored.<br /><br />
            If you are employed by Company, personal data is stored while your
            employment is in force and up to additional 2 years.
          </td>
        </tr>
        <tr>
          <td>Managing your existing employment relationships with Company</td>
          <td>
            <p>Basic Information</p>
            <p>Education Information</p>
            <p>Experience Information</p>
            <p>Web Information</p>
            <p>Technical Information</p>
          </td>
          <td>
            <p>Legitimate interests</p>
            Consent
          </td>
          <td>
            Personal data is stored while your employment is in force, and may be 
            stored for future reference unless explicitly asked to delete it.
          </td>
        </tr>
        <tr>
          <td>Protecting and ensuring our legitimate interests</td>
          <td>
            <p>Basic Information</p>
            <p>Education Information</p>
            <p>Experience Information</p>
            <p>Web Information</p>
            <p>Technical Information</p>
          </td>
          <td>Legitimate interests</td>
          <td>
            From 3 (three) years and up to 10 (ten) years or for a longer term
            depending on whether there is any conflict between parties.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-card>
