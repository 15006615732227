<ng-container *ngIf="currentRouteData$ | async as currentRouteData">
  <div class="cv-wrapper">
    <lss-form-card
      *ngIf="dataReady$ | async"
      [subtitle]="currentRouteData.subtitle"
      [title]="currentRouteData.title"
      [form]="form"
      [formSubmit]="educationSubmit"
      [isCandidate]="authService.userHasRole$(candidate) | async"
      (submitToHR)="submitToHR()"
      [cv]="dataService.cv$ | async"
      [cvSaved]="dataService.cvSaved$ | async"
    >
      <lss-actions-bar
        (add)="addInstitutionToStart()"
        id="addInstitutionTop"
        [label]="'Add Institution'"
      ></lss-actions-bar>

      <lss-editable-item
        #editableItem
        class="education"
        *ngFor="
          let formGroup of form.controls;
          let index = index;
          let first = first;
          let last = last
        "
        (remove)="remove(form, index)"
        [state]="formGroup.controls.animationState?.value"
        [getRemoveConfirmation$]="getRemoveConfirmation(index)"
        [nesting]="0"
      >
        <lss-icon [label]="'Remove Institution'" editable-item-remove
          >close</lss-icon
        >

        <lss-mover
          editable-item-mover
          *ngIf="form.controls?.length > 1"
          [canMoveUp]="!first"
          [canMoveDown]="!last"
          (moveUp)="moveUp(editableItem.elementRef.nativeElement, index)"
          (moveDown)="moveDown(editableItem.elementRef.nativeElement, index)"
        >
        </lss-mover>

        <div editable-item-body>
          <lss-education-degree-item
            [formGroup]="formGroup"
            [focusElement]="index === focusedIndex"
          ></lss-education-degree-item>
        </div>
      </lss-editable-item>

      <lss-actions-bar
        (add)="append()"
        id="addInstitutionBottom"
        [label]="'Add Institution'"
      ></lss-actions-bar>
    </lss-form-card>
  </div>
</ng-container>
