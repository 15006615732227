import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import { Constants } from '../constants';
import { DataService } from '../base/data.service';
import { emailStructureValidator } from '../utils';

@Component({
    selector: 'lss-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    errorText: string;
    linkExpired: string;
    emailErrorText: string;
    disableSubmit: boolean = false;
    emailSent$ = new Subject<boolean>();
    public form = new FormGroup({
        email: new FormControl(null, [
            emailStructureValidator(),
            Validators.required,
        ])
    });
    destroyed$ = new Subject();

    constructor(
        @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    ngOnInit(): void {
        this.emailSent$.next(false);
        if (this.route.snapshot.queryParamMap.has('error')) {
            this.linkExpired = Constants.ErrorMessages.resetPasswordLinkHasExpired;
        }
    }

    isDisabled(): boolean {
        return this.form?.pristine || this.form?.invalid || this.disableSubmit;
    }

    resetPassword(): void {
        this.dataService.forgotPassword(
            {
                username: this.form?.controls?.email.value
            }
        )
            .subscribe({
                next: (data) => {
                    this.emailSent$.next(true);
                },
                error: (error) => {
                    this.emailSent$.next(true);
                }
            })
    }

    navigateToLoginPage(): void {
        this.router.navigate([Constants.Routes.Login]);
    }
}