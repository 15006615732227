import { NgModule } from '@angular/core';
import { TechnicalSkillCardComponent } from './technical-skill-card.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  ButtonModule,
  FormCardModule,
  IconModule,
  RenderingModule,
  EditableItemModule,
  SelectModule,
  AutocompleteModule,
  ButtonToggleModule,
  ConfirmationModule,
  TextModule,
  NumberModule,
  MoverModule,
} from '@lss/lss-ui';

@NgModule({
  declarations: [TechnicalSkillCardComponent],
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormCardModule,
    RenderingModule,
    EditableItemModule,
    SelectModule,
    AutocompleteModule,
    ButtonToggleModule,
    MatFormFieldModule,
    OverlayModule,
    ConfirmationModule,
    TextModule,
    NumberModule,
    MoverModule,
    IconModule,
  ],
  exports: [TechnicalSkillCardComponent],
})
export class TechnicalSkillCardModule {}
