<mat-card class="editable-item nesting-{{ nesting }}" [@fadeAnimation]="state">
  <div class="body">
    <div class="top-bar">
      <lss-button
        type="button"
        [color]="layoutInfo.removeColor"
        class="removeBtn editable-item-remove"
        (action)="onRemove()"
      >
        <ng-content select="[editable-item-remove]"></ng-content>
      </lss-button>

      <ng-content select="[editable-item-mover]"></ng-content>
    </div>

    <div class="editable-item-body">
      <ng-content select="[editable-item-body]"></ng-content>
    </div>
  </div>
</mat-card>
