import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ElementRef,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextAreaMode } from '@lss/lss-types';
import { BaseControl } from '../base.control';
import { stripHTML } from '@lss/lss-ui';

const MAX_LENGTH = 3000;

@Component({
  selector: 'lss-rich-text-area',
  template: `
    <quill-editor
      #editor
      class="{{ classList }}"
      [(ngModel)]="value"
      (keydown)="onKeyDown($event)"
      (onFocus)="onFocus($event)"
      [format]="format"
      [formats]="formats"
      [modules]="editorOptions"
      [required]="required"
      [placeholder]="placeholder"
      [maxLength]="maxChars"
    ></quill-editor>
    <ng-container *ngIf="showWithoutCounter; else showWithCounter">
      <div class="root">
        <div class="parent">
          <div class="textCounter"></div>
        </div>
      </div>
    </ng-container>
    <ng-template #showWithCounter>
      <div class="root">
        <div class="parent">
          <div class="textCounter">{{ textLength }}/{{ maxChars }}</div>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: ['./rich-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextAreaComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextAreaComponent
  extends BaseControl
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('editor', { static: true }) editor: QuillEditorComponent;
  @Input() mode = TextAreaMode.Default;
  @Input() formControl: FormControl;

  destroyed$ = new Subject();
  format = 'html';
  formats = ['bold', 'italic', 'underline', 'strike', 'list'];
  editorOptions = {
    toolbar: [['bold', 'italic', 'underline', 'strike']],
    clipboard: { matchVisual: false },
  } as any;
  placeholder = 'Insert text here..';
  classList: string;
  textLength: number;
  maxChars = MAX_LENGTH;
  showWithoutCounter = true;
  remainingChars: number;

  constructor(
    public elementRef: ElementRef,
    public cd: ChangeDetectorRef,
    public injector: Injector,
  ) {
    super(elementRef, cd, injector);
  }

  onKeyDown($event: KeyboardEvent): boolean {
    if ($event && this.textLength === MAX_LENGTH) {
      // Converting pressed key to lowercase so we don't have to deal
      // with small/big letters
      switch ($event.key.toLowerCase()) {
        case 'delete':
        case 'backspace':
        case 'control':
        case 'arrowleft':
        case 'arrowright':
        case 'arrowup':
        case 'arrowdown':
        case $event.ctrlKey && 'a':
        case $event.ctrlKey && 'c':
        case $event.ctrlKey && 'v':
        case $event.ctrlKey && 'x':
          return true;
      }

      // if text is selected
      // allow to input text because this will erase selection
      const selection = this.editor.quillEditor.selection;
      if (selection.savedRange.length) {
        return true;
      }

      return false;
    }
    return true;
  }

  public getConditionalKeyboardBindings = () => {
    return !(this.textLength === MAX_LENGTH);
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.initByMode(this.mode);
    const initValue = this.formControl.value;
    this.formControl.valueChanges.subscribe((newValue) => {
      if (initValue === newValue) {
        this.formControl.markAsPristine();
        this.formControl.markAsUntouched();
      }
    });
  }

  ngAfterViewInit(): void {
    this.editor.onContentChanged
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.textLength = value.text?.length - 1 || 0;

        // Quill editor adds \n at the end when value is inserted/pasted
        // so we should check if new value is not \n
        if (!this.formControl.value?.length && value.text !== '\n') {
          this.formControl.setValue(stripHTML(value.html));
        }

        // Cut remaining text
        if (this.textLength > MAX_LENGTH) {
          const editorContent = this.editor.quillEditor.getText();
          const trimmedContent = editorContent.substring(0, MAX_LENGTH);
          this.editor.quillEditor.setText(trimmedContent);
          this.formControl.setValue(trimmedContent);
        }
      });
  }

  onFocus(_$event): void {
    this.showWithoutCounter = false;
    this.textLength = this.editor.quillEditor.getText().length - 1 || 0;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  initByMode(mode: TextAreaMode): void {
    const { toolbar, classList } = configByMode[mode];
    const keyboard = {
      bindings: {
        tab: {
          handler: this.getConditionalKeyboardBindings,
        },
        handleEnter: {
          key: 13,
          handler: this.getConditionalKeyboardBindings,
        },
        'header enter': {
          key: 13,
          handler: this.getConditionalKeyboardBindings,
        },
      },
    };

    this.editorOptions.toolbar = toolbar;
    this.editorOptions.keyboard = keyboard;
    this.classList = classList;
  }
}

const configByMode = {
  [TextAreaMode.Default]: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    classList: 'mode-default',
  },
  [TextAreaMode.BulletList]: {
    toolbar: [['bold', 'italic', 'underline', 'strike']],
    classList: 'mode-bulletList',
  },
};
