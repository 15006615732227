import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TextComponent } from '../../../../../../lss-ui/src/lib/form/text/text.component';

@Component({
  selector: 'lss-course-item',
  templateUrl: './course-item.component.html',
  styleUrls: ['./course-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseItemComponent implements AfterViewInit {
  @Input() formGroup: FormGroup;
  @Input() focusElement = false;
  @ViewChild('focusField') focusField: TextComponent;

  ngAfterViewInit(): void {
    if (this.focusElement && this.focusField) {
      this.focusField.focusInput();
    }
  }
}
