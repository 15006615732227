<div class="forgot-password-container" *ngIf="form && !(emailSent$ | async)">
  <div>
    <img
      class="logo"
      alt="28 stone"
      aria-label="28 stone logo"
      src="assets/svg/28stone-logo.svg"
    />
  </div>
  <div class="error-container-parent">
    <div class="error-container" *ngIf="!!linkExpired">
      <span>
        <img
          class="error-icon"
          aria-label="exclamation-mark"
          src="assets/svg/exclamation-icon.svg"
        />
      </span>
      <span>
        <mat-error class="error-text">{{ linkExpired }}</mat-error>
      </span>
    </div>
  </div>

  <div class="email-container">
    <p class="title">Forgot password?</p>
    <div class="underline-text">
      Please enter your email address. If an account with this email exists,<br />
      a link to reset your password will be sent to this email address
    </div>
    <lss-form-card [form]="form" autocomplete="off">
      <div>
        <lss-text
          class="email"
          [formControl]="form.controls.email"
          label="Email Address"
          errorMessage="Not a valid email"
        ></lss-text>
        <div class="actions">
          <a class="back-link" (click)="navigateToLoginPage()">
            <mat-icon class="back-icon">keyboard_backspace</mat-icon>
            <span>Back to Login</span>
          </a>
          <lss-button [disabled]="isDisabled()" (action)="resetPassword()">
            Reset Password
          </lss-button>
        </div>
      </div>
    </lss-form-card>
  </div>
</div>
<div class="email-sent-container" *ngIf="emailSent$ | async">
  <div>
    <img
      class="logo"
      alt="28 stone"
      aria-label="28 stone logo"
      src="assets/svg/28stone-logo.svg"
    />
  </div>
  <div class="icon-container">
    <mat-icon>mail</mat-icon>
  </div>
  <p>Please check your inbox for an email with a password reset link</p>
  <div class="actions">
    <a class="back-link" (click)="navigateToLoginPage()">
      <mat-icon class="back-icon">keyboard_backspace</mat-icon>
      <span>Back to Login</span>
    </a>
  </div>
</div>
