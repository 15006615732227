import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ILookupValue } from '@lss/lss-types';
import { Observable } from 'rxjs';
import { AutocompleteComponent } from '../../../../../../lss-ui/src/lib/form/autocomplete/autocomplete.component';

@Component({
  selector: 'lss-language-item',
  templateUrl: './language-item.component.html',
  styleUrls: ['./language-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageItemComponent implements AfterViewInit {
  @Input() formGroup: FormGroup;
  @Input() languageProficiencyOptions: ILookupValue[];
  @Input() languageOptions: ILookupValue[];
  @Input() alreadySelectedLanguages$: Observable<any>;
  @Input() focusElement = false;
  @ViewChild('focusField') focusField: AutocompleteComponent;

  ngAfterViewInit(): void {
    if (this.focusElement && this.focusField) {
      this.focusField.focusInput();
    }
  }
}
