<ng-container *ngIf="currentRouteData$ | async as currentRouteData">
  <div class="cv-wrapper">
    <lss-form-card
      *ngIf="dataReady$ | async"
      class="language-proficiency"
      [title]="currentRouteData.title"
      [subtitle]="currentRouteData.subtitle"
      [form]="form"
      [formSubmit]="submit"
      [isCandidate]="authService.userHasRole$(candidate) | async"
      (submitToHR)="submitToHR()"
      [cv]="dataService.cv$ | async"
      [cvSaved]="dataService.cvSaved$ | async"
    >
      <lss-button
        [color]="'secondary'"
        (action)="addLanguage()"
        id="addLanguage"
      >
        <lss-icon [label]="'Add Language'">add</lss-icon>
      </lss-button>

      <lss-editable-item-table-header>
        <lss-icon></lss-icon>
        <label>Select Language</label>
        <label style="padding-left: 32px"
          >Select Proficiency
          <div
            class="icon"
            (mouseenter)="openDialog()"
            (mouseleave)="leaveDialog()"
          >
            <lss-icon id="languageInfo"><sup>info_outline</sup></lss-icon>
          </div>
        </label>
      </lss-editable-item-table-header>
      <lss-editable-item
        class="education"
        *ngFor="
          let formGroup of form.controls;
          let last = last;
          let index = index
        "
        (remove)="remove(form, index)"
        [nesting]="'table'"
        [state]="formGroup.controls.animationState?.value"
      >
        <lss-icon editable-item-remove>cancel</lss-icon>

        <lss-language-item
          editable-item-body
          [formGroup]="formGroup"
          [languageOptions]="languageOptions"
          [languageProficiencyOptions]="languageProficiencyOptions"
          [alreadySelectedLanguages$]="alreadySelectedLanguages$"
          [focusElement]="index === focusedIndex"
        ></lss-language-item>
      </lss-editable-item>
    </lss-form-card>
  </div>
</ng-container>
