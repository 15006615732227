<mat-card>
  <div>
    <img
      class="logo"
      alt="28 stone"
      aria-label="28 stone logo"
      src="../../../assets/svg/28stone-logo.svg"
    />
  </div>

  <br />

  <div class="content">
    <h1>Terms of Service</h1>

    <ul>
      <li class="top-level-heading">1. Terms</li>

      <li class="sub-level">
        <div>1.1.</div>
        <div>
          In using any services, products, platforms and sites (the
          <b>“Services”</b>) provided by a company 28Stone Consulting, Inc with
          the US registration number 0101015974 and a company “28Stone Riga”
          with the Latvian registration number 40103612535 (“<b>we</b>”,
          “<b>us</b>”, or, “<b>Company</b>”) to you (“<b>you</b>”, the
          “<b>User</b>”) agree to the following Terms of Service (the
          “<b>Terms</b>”). The Terms explain our obligations to you, and your
          obligations to us in the context of your use of the Services as
          defined below. The Terms is the entire agreement between us concerning
          the use of these Services.
        </div>
      </li>
      <li class="sub-level">
        <div>1.2.</div>
        <div>
          By using the Services, you agree to comply with these Terms and our
          Privacy Policy, all applicable laws and regulations, and any other
          legal notices or conditions or guidelines posted on our websites,
          including
          <a href="http://skillset.28stone.com" target="_blank">
            http://skillset.28stone.com</a
          >
          and
          <a href="https://skillset.28stone.cloud" target="_blank">
            https://skillset.28stone.cloud</a
          >
          and other websites related to SkillSet (the “<b>Website</b>”) which we
          may update without notice. If you do not agree with any of these
          terms, you are prohibited from using or accessing the Services.
        </div>
      </li>

      <li class="top-level-heading">2. Accessing the Services</li>

      <li class="sub-level">
        <div>2.1.</div>
        <div>You are not allowed to use the Services if you are under 18.</div>
      </li>
      <li class="sub-level">
        <div>2.2.</div>
        <div>
          If you might have discovered any vulnerability within the Website
          and/or the Services, please do not perform other further activities
          and let us know as soon as possible about it by contacting us at
          <a [href]="'mailto:' + emailAddress">info@28stone.com</a>. If you
          perform any further activities by exploiting the discovered
          vulnerability, please note that this might be a violation of these
          Terms and applicable law.
        </div>
      </li>
      <li class="sub-level">
        <div>2.3.</div>
        <div>
          In certain cases, to access the Services, User may create an account.
        </div>
      </li>
      <li class="sub-level">
        <div>2.4.</div>
        <div>
          The physical person establishing an account (the
          <b>“Account Manager”</b> on behalf of a legal entity) (the “<b
            >Account Entity</b
          >”) thereby confirms that he or she is acting with authority to bind
          the Account Entity to these Terms. We reserve the right to verify the
          identity and authority of such Users.
        </div>
      </li>
      <li class="sub-level">
        <div>2.5.</div>
        <div>
          Each User is solely responsible for his or her actions and for any and
          all information he or she makes available on the Services.
        </div>
      </li>

      <li class="top-level-heading">3. Services</li>

      <li class="sub-level">
        <div>3.1.</div>
        <div>
          Through the Website, Company provides Users with different Services,
          including a recruitment portal, tools to register and manage the
          registered profile.
        </div>
      </li>
      <li class="sub-level">
        <div>3.2.</div>
        <div>
          Account Managers can access the recruitment portal only by logging in
          through the Website using a username and password or using Google
          depending on the type of User.
        </div>
      </li>
      <li class="sub-level">
        <div>3.3.</div>
        <div>
          Company may from time to time offer various Services to Users on a
          no-fee basis and/ or on a paid basis.
        </div>
      </li>

      <li class="top-level-heading">4. User Code of Conduct</li>

      <li class="sub-level">
        <div>4.1.</div>
        <div>
          You shall ensure the data provided by you for the purpose of using the
          Services is valid at all times. You undertake to keep your information
          accurate and up-to-date.
        </div>
      </li>
      <li class="sub-level">
        <div>4.2.</div>
        <div>
          You shall not transfer your account and profile to anyone without
          first getting our express written permission.
        </div>
      </li>
      <li class="sub-level">
        <div>4.3.</div>
        <div>
          You shall not access the Website nor use the Services for any unlawful
          purposes or to conduct any unlawful activity, including unauthorized
          access to the information of other Users in any way.
        </div>
      </li>
      <li class="sub-level">
        <div>4.4.</div>
        <div>
          Each User is responsible for his or her use of the Website and
          Services, for correctness and completeness of any and all data entered
          into the Website or Services, and for any consequences thereof. The
          User is responsible for ensuring that he or she has the proper
          authority to upload and/or share the data.
        </div>
      </li>
      <li class="sub-level">
        <div>4.5.</div>
        <div>
          Company bears no responsibility for the data provided or used by you
          within the Website, including liability for disclosure of personal,
          confidential or non-public information.
        </div>
      </li>
      <li class="sub-level">
        <div>4.6.</div>
        <div>
          You will ensure that you have secured the consent of any person in a
          user capacity who is added to the account.
        </div>
      </li>
      <li class="sub-level">
        <div>4.7.</div>
        <div>
          You will not use or access our Services, directly or indirectly, to
          access and/or download any proprietary content located on the Website
          at any time.
        </div>
      </li>
      <li class="sub-level">
        <div>4.8.</div>
        <div>
          You may not use any automatic device, program, algorithm or
          methodology, or any similar or equivalent manual process, to access,
          acquire, copy or monitor any portion of the Website or any content, or
          in any way reproduce or circumvent the navigational structure or
          presentation of the Website or any content, to obtain or attempt to
          obtain any materials, documents or information through any means not
          purposely made available through the Website. Company reserves the
          right to bar any such activity.
        </div>
      </li>
      <li class="sub-level">
        <div>4.9.</div>
        <div>
          You may not attempt to gain unauthorized access to any portion or
          feature of the Website, or any other systems or networks connected to
          the Website or to any Company server, or to any of the services
          offered on or through the Website, by hacking or any other
          illegitimate means.
        </div>
      </li>
      <li class="sub-level">
        <div>4.10.</div>
        <div>
          You may not probe, scan or test the vulnerability of the Website or
          any network connected to the Website, nor breach the security or
          authentication measures on the Website or any network connected to the
          Website. You may not reverse look-up, trace or seek to trace any
          information on any other user of or visitor to the Website, or any
          other customer of Company.
        </div>
      </li>

      <li class="sub-level">
        <div>4.11.</div>
        <div>
          You agree that you will not take any action that imposes an
          unreasonable or disproportionately large load on the infrastructure of
          the Website or Company systems or networks, or any systems or networks
          connected to the Website or to Company.
        </div>
      </li>
      <li class="sub-level">
        <div>4.12.</div>
        <div>
          You agree not to use any device, software or routine to interfere or
          attempt to interfere with the proper working of the Website or any
          transaction being conducted on the Website, or with any other person’s
          use of the Website.
        </div>
      </li>
      <li class="sub-level">
        <div>4.13.</div>
        <div>
          You may not manipulate identifiers in order to disguise the origin of
          any message or transmittal you send to us on or through the Website or
          any service offered on or through the Website. You may not pretend
          that you are, or that you represent, someone else, or impersonate any
          other individual or entity.
        </div>
      </li>
      <li class="sub-level">
        <div>4.14.</div>
        <div>
          You may not use the Services: (i) to impersonate or attempt to
          impersonate Company, or any other person or entity; or (ii) for the
          purpose of exploiting, harming, or attempting to exploit or harm any
          third parties, including minors in any way.
        </div>
      </li>

      <li class="top-level-heading">5. Intellectual Property</li>

      <li class="sub-level">
        <div>5.1.</div>
        <div>
          The Website and its parts, including, but not limited to, the graphic
          design and software utilized on the Website, are subject to and
          protected by the intellectual property rights of Company or third
          parties. Without obtaining prior written authorization from Company or
          the corresponding third parties, you may not copy, modify, alter,
          publish, disseminate, sell or transfer, either wholly or in part, any
          component of this Website or any of the content that can be found on
          the Website. The trademark and visual design of any projects, services
          or products that are offered by Company are the intellectual property
          of Company or other third parties. All rights are hereby expressly
          reserved by Company or the corresponding third parties. Without
          obtaining prior written authorization from Company or the
          corresponding third parties, you may not copy, modify, alter, publish,
          disseminate, sell or transfer, either wholly or in part, any
          trademark, logo, any and all artwork or any content on this Website.
        </div>
      </li>
      <li class="sub-level">
        <div>5.2.</div>
        <div>
          Nothing in these Terms or its performance shall grant you any right,
          title, interest, or license in or to the names, logos, logotypes,
          trade dress, designs, or other trademarks of Company or other third
          parties.
        </div>
      </li>

      <li class="top-level-heading">6. Cancellation and Termination</li>

      <li class="sub-level">
        <div>6.1.</div>
        <div>
          Upon termination for any reason, Company has the right to delete all
          data, files, or other information that are stored in your account and
          profile unless and, to the extent, required by law to maintain the
          data, files or other information.
        </div>
      </li>
      <li class="sub-level">
        <div>6.2.</div>
        <div>
          Company may terminate Services at any time, without penalty,
          obligation to refund and without notice
        </div>
      </li>

      <li class="top-level-heading">7. Third-Party Data</li>

      <li class="sub-level">
        <div>7.1.</div>
        <div>
          As Account Manager you may use the Website for the processing of the
          personal data of other natural persons, for example, job candidates.
        </div>
      </li>
      <li class="sub-level">
        <div>7.2.</div>
        <div>
          By accepting these Terms, you have an obligation to comply with the
          Privacy Policy, and you confirm that you understand that you are
          completely responsible and liable for the processing of the personal
          data of third parties via the Website. Company shall be neither
          responsible nor liable for any personal data that you process via
          Website.
        </div>
      </li>
      <li class="sub-level">
        <div>7.3.</div>
        <div>
          Hereby you confirm that you understand that you have the right to use
          the Website for the processing of the third party’s personal data only
          if you have a legal basis to carry out such activities with the
          personal data and if you comply with the requirements of the
          applicable laws.
        </div>
      </li>
      <li class="sub-level">
        <div>7.4.</div>
        <div>
          By agreeing to these Terms, the User/Account Manager confirms that the
          data subjects, whose data will be processed, have been provided with
          privacy information by the User/Account Manager if the User/Account
          Manager represents the controller of the corresponding personal data.
        </div>
      </li>
      <li class="sub-level">
        <div>7.5.</div>
        <div>
          Company is in no way liable or responsible for the actions of the
          User/Account Manager with regards to handling of the personal data
          processed by the User/Account Manager.
        </div>
      </li>
      <li class="sub-level">
        <div>7.6.</div>
        <div>
          Company or the authorized representative thereof shall have the right
          to request to any information from User/Account Manager to ensure that
          User/Account Manager is compliant with the requirements of the Terms.
          User/Account Manager shall provide the requested information.
        </div>
      </li>
      <li class="sub-level">
        <div>7.7.</div>
        <div>
          The User/Account Manager shall assist Company in notifying the
          supervisory authorities and data subject on personal data breach.
        </div>
      </li>
      <li class="sub-level">
        <div>7.8.</div>
        <div>
          The User/Account Manager shall assist Company with performing a data
          protection impact assessment by providing the required information.
        </div>
      </li>

      <li class="top-level-heading">
        8. Disclaimer, Liability and Limitation of Liability
      </li>

      <li class="sub-level">
        <div>8.1.</div>
        <div>The materials provided on the Website are provided "as is".</div>
      </li>
      <li class="sub-level">
        <div>8.2.</div>
        <div>
          Company makes no warranties, expressed or implied, and hereby
          disclaims and negates all other warranties, including without
          limitation, implied warranties or conditions of merchantability,
          fitness for a particular purpose, or non-infringement of intellectual
          property or other violation of rights. Further, Company does not
          warrant or make any representations concerning the accuracy, likely
          results, security or reliability of the use of the materials on its
          Services.
        </div>
      </li>
      <li class="sub-level">
        <div>8.3.</div>
        <div>
          We do not warrant that the Services will meet your specific
          requirements, will be uninterrupted, timely, secure, or error- and
          virus-free, and the results that may be obtained from the User of the
          Services will be accurate or reliable.
        </div>
      </li>
      <li class="sub-level">
        <div>8.4.</div>
        <div>
          Company expressly disclaims responsibility for interruptions of
          service to Users due to third party service providers including, but
          not limited to, server providers, font or content provider, and/or any
          online hosting service.
        </div>
      </li>
      <li class="sub-level">
        <div>8.5.</div>
        <div>
          In no event shall Company or its suppliers be liable for any damages
          (including, without limitation, damages for loss of data or profit, or
          due to business interruption, computer failure) arising out of the use
          or inability to use the materials on the Website, even if Company or a
          Company authorized representative has been notified orally or in
          writing of the possibility of such damages. In no event shall the
          entire liability of Company to you in respect of any Service, whether
          direct or indirect, exceed the fees paid by you towards such Service.
        </div>
      </li>
      <li class="sub-level">
        <div>8.6.</div>
        <div>
          Materials appearing on the Website could include technical,
          typographical, or photographic errors. Company does not warrant that
          any of the materials on its Services are accurate, complete, or
          current. Company may make changes to the materials contained on its
          Services at any time without notice. Company does not, however, make
          any commitment to update the materials.
        </div>
      </li>
      <li class="sub-level">
        <div>8.7.</div>
        <div>
          The Website may contain links to other independent third-party
          websites. These websites are provided solely as a convenience to
          Users. Such websites are not under our control, and we are not
          responsible for and do not endorse the content of such websites,
          including any information or materials contained on such websites. You
          will need to make your own independent judgment regarding your
          interaction with these websites.
        </div>
      </li>
      <li class="sub-level">
        <div>8.8.</div>
        <div>
          You agree to indemnify and hold Company, its officers, directors,
          shareholders, predecessors, successors in interest, employees, agents,
          subsidiaries and affiliates, harmless from any demands, loss,
          liability, claims or expenses (including attorneys’ fees), made
          against Company by any third party due to or arising out of or in
          connection with your use of the Website.
        </div>
      </li>
      <li class="sub-level">
        <div>8.9.</div>
        <div>
          Violators of these Terms will have access terminated immediately and
          Company reserves the right to seek remedies and/or damages under the
          law.
        </div>
      </li>

      <li class="top-level-heading">9. Customer Support and Feedback</li>

      <li>
        Company welcomes your feedback. Please note that any questions,
        comments, suggestions, ideas, feedback, or other information provided by
        you to us (“<b>Comments</b>”) are not confidential and you hereby grant
        us a worldwide, perpetual, irrevocable, royalty-free license to
        reproduce, display, perform, distribute, publish, modify, edit or
        otherwise use such Comments as we deem appropriate, for any and all
        commercial and/or non-commercial purposes, at our sole discretion.<br /><br />

        Please contact us here:
        <a [href]="'mailto:' + emailAddress">info@28stone.com</a>
      </li>

      <li class="top-level-heading">10. Modifications to the Terms</li>

      <li class="sub-level">
        Company may revise these Terms at any time without notice. By using
        these Services, you are agreeing to be bound by the then current version
        of these Terms.
      </li>

      <li class="top-level-heading">11. Governing Law</li>

      <li class="sub-level">
        Any claim relating to the Services provided by the Company shall be
        governed and construed in accordance with the laws of the State of New
        Jersey, USA without regard to the conflicts of laws or principles
        thereof; and that the governing law herein provided shall be exclusive
        of all other law.
      </li>

      <li class="top-level-heading">12. Contact Us</li>

      <li>
        If you have any questions about these Terms, please contact us at:
        <a [href]="'mailto:' + emailAddress">info@28stone.com</a>
      </li>
    </ul>
  </div>
</mat-card>
