import {
  FormArray,
  AbstractControl,
  FormGroup,
} from '@angular/forms';
import { AnimationBuilder } from '@angular/animations';
import { IListCrudUtilService } from '@lss/lss-types';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export abstract class ListCrudUtilService implements IListCrudUtilService {
  constructor(protected animationBuilder: AnimationBuilder) {}

  insert(form: FormArray, index: number, item: AbstractControl): void {
    form.insert(index, item);
    form.markAsDirty();
  }

  insertAtEnd(form: FormArray, item: AbstractControl): void {
    const index = form.length
    form.insert(index, item);
    form.markAsDirty();

  }

  insertAtStart(form: FormArray, item: AbstractControl): void {
    form.insert(0, item);
    form.markAsDirty();
  }

  append(form: FormArray, item: AbstractControl): void {
    this.insert(form, form.length, item);
  }

  moveUp(form: FormArray, element: any, currentIndex: number): void {
    this.move(form, element, currentIndex, currentIndex - 1);
  }

  moveDown(form: FormArray, element: any, currentIndex: number): void {
    this.move(form, element, currentIndex, currentIndex + 1);
  }

  move(
    form: FormArray,
    element: any,
    currentIndex: number,
    newIndex: number
  ): void {
    const removed = this.remove(form, currentIndex);
    this.insert(form, newIndex, removed);

    form.markAsDirty();
  }

  remove(form: FormArray, index: number): FormGroup {
    const removed = form.at(index) as FormGroup;
    form.removeAt(index);
    form.markAsDirty();

    return removed;
  }
}
