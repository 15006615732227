<ng-container *ngIf="currentRouteData$ | async as currentRouteData">
  <div class="cv-wrapper">
    <lss-form-card
      *ngIf="dataReady$ | async"
      class="profesional-certifications"
      [title]="currentRouteData.title"
      [subtitle]="currentRouteData.subtitle"
      [form]="form"
      [additionalInfo]="additionalInfo"
      [formSubmit]="submit"
      [isCandidate]="authService.userHasRole$(candidate) | async"
      (submitToHR)="submitToHR()"
      [cv]="dataService.cv$ | async"
      [cvSaved]="dataService.cvSaved$ | async"
    >
      <mat-card>
        <mat-card-title class="mat-card-title">Certificates</mat-card-title>
        <mat-card-subtitle class="mat-card-subtitle no-margin"
          >Provide any certifications that you've earned</mat-card-subtitle
        >

        <lss-button
          [color]="'secondary'"
          (action)="addItem(certificates)"
          id="addCertificate"
        >
          <lss-icon [label]="'Add Certificate'">add</lss-icon>
        </lss-button>

        <lss-editable-item-table-header>
          <label style="padding-left: 16px">Certificate *</label>
          <label style="padding-left: 39px">Institution</label>
          <label style="width: 234px">Issued On* - Valid Until</label>
          <label>Notes</label>
        </lss-editable-item-table-header>

        <lss-editable-item
          class="education"
          #editableItem
          *ngFor="
            let formGroup of form.controls[certificates].controls;
            let last = last;
            let first = first;
            let index = index
          "
          (remove)="remove(form.controls[certificates], index)"
          [nesting]="'table'"
          [state]="formGroup.controls.animationState?.value"
        >
          <lss-icon editable-item-remove>cancel</lss-icon>
          <lss-mover
            editable-item-mover
            *ngIf="form.controls[certificates].controls?.length > 1"
            [canMoveUp]="!first"
            [canMoveDown]="!last"
            (moveUp)="
              moveUp(editableItem.elementRef.nativeElement, index, certificates)
            "
            (moveDown)="
              moveDown(
                editableItem.elementRef.nativeElement,
                index,
                certificates
              )
            "
          >
          </lss-mover>
          <lss-certificate-item
            editable-item-body
            [formGroup]="formGroup"
            [focusElement]="index === focusedIndex"
          ></lss-certificate-item>
        </lss-editable-item>
      </mat-card>
      <mat-card>
        <mat-card-title class="mat-card-title">Courses</mat-card-title>
        <mat-card-subtitle class="mat-card-subtitle no-margin"
          >Provide courses that are relevant to your position</mat-card-subtitle
        >

        <lss-button
          [color]="'secondary'"
          (action)="addItem(courses)"
          id="addCertificate"
        >
          <lss-icon [label]="'Add Course'">add</lss-icon>
        </lss-button>

        <lss-editable-item-table-header>
          <label style="padding-left: 16px">Course Name*</label>
          <label style="padding-left: 39px">Institution</label>
          <label style="width: 234px">Start Date* - End Date</label>
          <label>Notes</label>
        </lss-editable-item-table-header>

        <lss-editable-item
          class="education"
          #editableItem
          *ngFor="
            let formGroup of form.controls[courses].controls;
            let last = last;
            let first = first;
            let index = index
          "
          (remove)="remove(form.controls[courses], index)"
          [nesting]="'table'"
          [state]="formGroup.controls.animationState?.value"
        >
          <lss-icon editable-item-remove>cancel</lss-icon>
          <lss-mover
            editable-item-mover
            *ngIf="form.controls[courses].controls?.length > 1"
            [canMoveUp]="!first"
            [canMoveDown]="!last"
            (moveUp)="
              moveUp(editableItem.elementRef.nativeElement, index, courses)
            "
            (moveDown)="
              moveDown(editableItem.elementRef.nativeElement, index, courses)
            "
          >
          </lss-mover>
          <lss-course-item
            editable-item-body
            [formGroup]="formGroup"
            [focusElement]="index === focusedIndex"
          ></lss-course-item>
        </lss-editable-item>
      </mat-card>
      <mat-card>
        <div class="certification-boxes">
          <mat-card-title>Hobbies</mat-card-title>
          <mat-card-subtitle
            >Enter a bulleted list of hobbies, interests and extracurricular
            activities.
          </mat-card-subtitle>
          <lss-tips
            [pageBlock]="'hobbies'"
            class="tips-and-examples"
            id="tipsAndExamples"
          ></lss-tips>
        </div>
        <form>
          <lss-rich-text-area
            [mode]="TextAreaMode.BulletList"
            [formControl]="additionalInfo.controls.hobbies"
          >
          </lss-rich-text-area>
        </form>
      </mat-card>
      <mat-card>
        <div class="certification-boxes">
          <mat-card-title>Additional comments</mat-card-title>
          <mat-card-subtitle>
            Is there anything else you'd like to tell us about your experience
            or qualifications?
          </mat-card-subtitle>
        </div>
        <form>
          <lss-rich-text-area
            [mode]="TextAreaMode.BulletList"
            [formControl]="additionalInfo.controls.additionalComments"
          >
          </lss-rich-text-area>
        </form>
      </mat-card>
    </lss-form-card>
  </div>
</ng-container>
