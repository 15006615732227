import { Inject, Injectable } from '@angular/core';
import {
  DATA_SERVICE_TOKEN,
  DocxParagraphObj,
  DocxRoleSection,
  ICvCertificationItem,
  ICvWorkExperienceItem,
  IEducationDegree,
  IEducationItem,
  IEmployeeCv,
  ILanguageProficiency,
  ILookupData,
  IPeriodItem,
  ITechnicalSkill,
} from '@lss/lss-types';
import { getDataFromSessionStorage } from '@lss/lss-ui';
import { DataService } from '../data.service';
import { AlignmentType, Packer, Paragraph, TextRun, UnderlineType } from 'docx';
import * as saveAs from 'file-saver';
import { CapitalizeFirstLetter, formatTimestamp } from '../../utils';
import {
  BlackLineTop,
  BuildDocx,
  BuildTableCompanyPeriod,
  BulletedNumberedParagraph,
  BulletedParagraph,
  CompanyNameParagraph,
  DegreeText,
  EducationParagraph,
  EmptyParagraph,
  EmptyTextNewLine,
  GreyLineTop,
  LanguageParagraph,
  LeftParagraphPadding,
  LogoImagePath,
  NewParagraph,
  NewText,
  NumLinesOrangeColumn,
  OrangeColumnSectionTitleParagraph,
  PeriodParagraph,
  RightParagraphPadding,
  RoleParagraph,
  SummaryTitleParagraph,
  WorkExperienceTitleParagraph,
} from './docx-definitions';

@Injectable({
  providedIn: 'root',
})
export class CvFileBuilderService {
  fullName: string;
  logoImagePath = LogoImagePath;
  logoImageBlob: Blob;
  numEducationLines = 0;
  numSkillsToDisplay = 0;

  constructor(
    @Inject(DATA_SERVICE_TOKEN)
    private dataService: DataService,
  ) {}

  async urlToBlob(url: string): Promise<Blob> {
    return (await fetch(url)).blob();
  }

  async getCVDocx(): Promise<void> {
    const logo = await this.urlToBlob(this.logoImagePath);
    const cv = getDataFromSessionStorage('cv') || ({} as IEmployeeCv);
    const { firstName, lastName } = cv.personalInfo;
    // ------------>
    // Array are created at the beginning to count all the usable rows for
    // skills list in orange column
    const languagesList = this.getLanguagesList(cv.languageProficiencies);
    const educationList = this.getEducationList(cv.education);
    const certificationsList = this.getBulletedCertificationList(
      cv.certifications,
    );
    const numLanguagesLines = languagesList?.length || 0;
    const numCertificationsLines = certificationsList?.length || 0;

    this.numSkillsToDisplay =
      NumLinesOrangeColumn -
      numLanguagesLines -
      numCertificationsLines -
      this.numEducationLines;
    // end of calculation
    // ------------>
    const skillsList = this.getBulletedSkillsList(cv.technicalSkills);
    const summarySection = this.getSummarySection(cv.summary.description);
    const workExperienceList = this.getWorkExperienceList(
      cv.workExperience,
      cv.customOffice,
    );

    const doc = await BuildDocx({
      logo,
      cv,
      languagesList,
      educationList,
      certificationsList,
      skillsList,
      summarySection,
      workExperienceList,
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `28Stone_CV_${firstName}_${lastName}`);
    });
  }

  public parseHtmlToTextRuns(html: string): Paragraph[] {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const paragraphArr: Paragraph[] = [];
    let paragraphObj: DocxParagraphObj = {
      alignment: AlignmentType.JUSTIFIED,
      indent: { left: LeftParagraphPadding, right: RightParagraphPadding },
      children: [],
    };
    let flagBullet: 'bullet' | number = 'bullet';

    const handleNode = (node: Node): TextRun | Paragraph => {
      const tag = node.nodeName.toLowerCase();
      node.textContent = CapitalizeFirstLetter(node.textContent);
      switch (tag) {
        case 'b':
        case 'strong':
          return NewText({ text: node.textContent, bold: true });
        case 'i':
        case 'em':
          return NewText({ text: node.textContent, italics: true });
        case 'u':
        case 'ins':
          return NewText({
            text: node.textContent,
            underline: { type: UnderlineType.SINGLE },
          });
        case 's':
        case 'strike':
        case 'del':
          return NewText({ text: node.textContent, strike: true });
        case 'li':
          return BulletedNumberedParagraph(node.textContent, flagBullet);
        default:
          return NewText({ text: node.textContent });
      }
    };

    // Text from text editor input starts and closes always with <p> or <ul> or <ol>
    for (let i = 0; i < doc.body.childNodes.length; i++) {
      const par = doc.body.childNodes[i];

      for (let j = 0; j < par.childNodes.length; j++) {
        const nodeName = doc.body.childNodes[i].nodeName.toLowerCase();
        nodeName === 'ol' ? (flagBullet = j + 1) : 'bullet';
        const node = par.childNodes[j];

        if (nodeName !== 'ol' && nodeName !== 'ul') {
          paragraphObj.children.push(handleNode(node));
        } else {
          paragraphArr.push(handleNode(node) as Paragraph);
        }
      }
      paragraphArr.push(NewParagraph(paragraphObj));
      paragraphObj.children = [];
    }
    return paragraphArr;
  }

  private getBulletedSkillsList(skills: ITechnicalSkill[]): Paragraph[] {
    const sectionTitle = OrangeColumnSectionTitleParagraph(
      'Software Experience',
      false,
    );
    const filteredSkills = skills.filter((item) => item.willingToUse);

    const sortedSkills = filteredSkills.sort((a, b) =>
      b.skillProficiency.localeCompare(a.skillProficiency) !== 0
        ? b.skillProficiency.localeCompare(a.skillProficiency)
        : b.lastUsed - a.lastUsed !== 0
        ? b.lastUsed - a.lastUsed
        : b.yearsOfExperience - a.yearsOfExperience,
    );

    let bulletStrings = sortedSkills.map((item) =>
      CapitalizeFirstLetter(item.skill),
    );

    if (bulletStrings.length >= this.numSkillsToDisplay) {
      bulletStrings = bulletStrings.slice(0, this.numSkillsToDisplay - 1);
    }

    const paragraphs = bulletStrings.map((bulletString) =>
      BulletedParagraph(bulletString),
    );

    return this.addSectionTitleIfList(paragraphs, sectionTitle);
  }

  private addSectionTitleIfList(
    paragraphList: Paragraph[],
    titleParagraph: Paragraph,
  ): Paragraph[] {
    if (paragraphList.length === 0 || paragraphList[0] === undefined) {
      return;
    }
    if (!titleParagraph || !Array.isArray(paragraphList)) {
      throw new Error('Invalid arguments');
    }

    const fullSection: Paragraph[] = [titleParagraph, ...paragraphList];
    return fullSection;
  }

  private getLanguagesList(languages: ILanguageProficiency[]): Paragraph[] {
    const sectionTitle = OrangeColumnSectionTitleParagraph('Languages');
    const lookupData = this.dataService.lookupData$.getValue() as ILookupData;

    const filteredLanguages = languages
      .filter(
        (item) =>
          item.proficiency && item.proficiency !== 'elementary_proficiency',
      )
      .map((item) => {
        const language =
          lookupData.languages.find((e) => e.key === item.language)
            ?.displayName ?? '';
        const proficiency =
          lookupData.languageProficiencies.find(
            (e) => e.key === item.proficiency,
          )?.displayName ?? '';
        const proficiencyDescription = proficiency.split(' - ')[1] ?? '';
        const level = proficiency.split(' ')[1] ?? 0;
        return {
          language: language,
          proficiencyDescription: proficiencyDescription,
          level: level as number,
        };
      });

    filteredLanguages.sort((a, b) => b.level - a.level);

    if (filteredLanguages.some((l) => l.language === 'English')) {
      filteredLanguages.unshift(
        filteredLanguages.splice(
          filteredLanguages.findIndex((e) => e.language == 'English'),
          1,
        )[0],
      );
    }

    const paragraphs = filteredLanguages.map((item) => {
      return LanguageParagraph(item.language, item.proficiencyDescription);
    });
    return this.addSectionTitleIfList(paragraphs, sectionTitle);
  }

  public buildPeriod = ({
    present,
    startDate,
    endTimestamp,
  }: IPeriodItem): string => {
    return startDate
      ? present
        ? `${formatTimestamp(startDate)} - Present`
        : `${formatTimestamp(startDate)} - ${formatTimestamp(endTimestamp)}`
      : '';
  };

  public formatDegree(degree: IEducationDegree): TextRun {
    const degreeText = DegreeText(
      CapitalizeFirstLetter(degree.degreeOrSpecialization),
      this.buildPeriod(degree),
    );
    degreeText?.addChildElement(EmptyTextNewLine);
    return degreeText;
  }

  private getSummarySection(description: string): Paragraph[] {
    return this.addSectionTitleIfList(
      this.parseHtmlToTextRuns(description),
      SummaryTitleParagraph,
    );
  }

  private getWorkExperienceList(
    exps: ICvWorkExperienceItem[],
    customOffice: string,
  ): any[] {
    const paragraphs = exps.map((item: ICvWorkExperienceItem) => {
      const { company, roles } = item;
      let location = company.toLocaleLowerCase().includes('28stone')
        ? customOffice
        : item.location;
      const companyText = `${CapitalizeFirstLetter(company)}, ${location}`;
      const companySections: any[] = [];
      const period = this.buildPeriod(item);

      companySections.push(BlackLineTop);
      companySections.push(CompanyNameParagraph(companyText));
      companySections.push(BuildTableCompanyPeriod(PeriodParagraph(period)));
      companySections.push(EmptyParagraph);
      // Roles management
      companySections.push(...this.getSectionRoles(roles));

      return companySections;
    });

    return this.addSectionTitleIfList(
      paragraphs.flat(),
      WorkExperienceTitleParagraph,
    );
  }

  private getSectionRoles(roles): DocxRoleSection[] {
    const roleSections: any[] = [];
    for (const role of roles) {
      roleSections.push(RoleParagraph(CapitalizeFirstLetter(role.title)));
      roleSections.push(
        BuildTableCompanyPeriod(PeriodParagraph(this.buildPeriod(role), true)),
      );
      roleSections.push(GreyLineTop);
      roleSections.push(...this.parseHtmlToTextRuns(role.description));
      roleSections.push(EmptyParagraph);
    }
    return roleSections;
  }

  private getEducationList(education: IEducationItem[]): Paragraph[] {
    const sectionTitle = OrangeColumnSectionTitleParagraph('Education');
    const paragraphs = education.map((item: IEducationItem) => {
      const { institution, location } = item;

      const institutionText =
        institution !== '' && location
          ? `${CapitalizeFirstLetter(institution)}, ${CapitalizeFirstLetter(
              location,
            )}`
          : institution !== ''
          ? `${CapitalizeFirstLetter(institution)}`
          : '';

      const degreeText = item.degrees.map((it) => this.formatDegree(it));

      this.numEducationLines =
        degreeText.length > 0 && institutionText !== ''
          ? this.numEducationLines + degreeText.length + 1
          : this.numEducationLines;
      if (institutionText !== '') {
        return EducationParagraph(degreeText, institutionText);
      }
    });
    return this.addSectionTitleIfList(paragraphs, sectionTitle);
  }

  private getBulletedCertificationList(
    certifications: ICvCertificationItem[],
  ): Paragraph[] {
    const sectionTitle = OrangeColumnSectionTitleParagraph('Certifications');
    const bulletStrings = certifications.map((item) =>
      CapitalizeFirstLetter(item.certificate),
    );

    const paragraphs = bulletStrings.map((bulletString) =>
      BulletedParagraph(bulletString),
    );

    return this.addSectionTitleIfList(paragraphs, sectionTitle);
  }
}
