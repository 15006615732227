import { Injectable, Inject, Optional } from '@angular/core';
import { BehaviorSubject, Observable, merge, of } from 'rxjs';
import { Router, ChildActivationEnd } from '@angular/router';
import { map, filter, first } from 'rxjs/operators';
import { IErrorService, ERROR_SERVICE_TOKEN } from '@lss/lss-types';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  public loading$: Observable<boolean>;
  private progress$ = new BehaviorSubject<boolean>(false);
  public initialLoad$: Observable<boolean>;
  private loaderCount = 0;

  constructor(
    public router: Router,
    @Optional() @Inject(ERROR_SERVICE_TOKEN) private errorService: IErrorService
  ) {
    this.initialLoad$ = router.events.pipe(
      filter((e) => e instanceof ChildActivationEnd && (!!e.snapshot.data.cv || e.snapshot.data.registerRoute)),
      first(),
      map(() => true)
    );

    this.loading$ = merge(
      this.initialLoad$.pipe(
        filter((e) => e === true),
        map(() => false)
      ),
      this.progress$,
      this.errorService
        ? this.errorService.error$.pipe(
            filter((e) => e !== null),
            map((e) => !e)
          )
        : of(null)
    );
  }

  showLoader(): void {
    if (this.loaderCount < 0) {
      this.loaderCount = 0;
    }

    this.loaderCount++;
    this.progress$.next(true);
  }

  hideLoader(force: boolean = false): void {
    if (force) {
      this.loaderCount = 0;
    } else if (this.loaderCount !== 0) {
      this.loaderCount--;
    }

    if (this.loaderCount === 0) {
      this.progress$.next(false);
    }
  }
}
