import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { timeout, updateValueAndValidityRecursively } from './utils';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  NAV_CURRENT_LOCATION_KEY = 'lss-nav-current-location';
  NAV_LAST_LOCATION_KEY = 'lss-nav-last-location';

  private lastLocation: string;
  private currentLocation: string;

  isTabActive = true;

  constructor() {
    this.lastLocation = sessionStorage.getItem(this.NAV_LAST_LOCATION_KEY);
    this.currentLocation = sessionStorage.getItem(
      this.NAV_CURRENT_LOCATION_KEY,
    );
  }

  checkAndFocusInvalidFields(form: FormGroup | FormArray): void {
    updateValueAndValidityRecursively(form);
    let hasFinished = false;

    if (form.invalid) {
      setInterval(() => {
        if (!hasFinished && this.isTabActive) {
          const inputElements = document.querySelectorAll('input');
          inputElements.forEach(async (it) => {
            try {
              it.focus();
            } catch (e) {}
            await timeout(100);
          });
          try {
            inputElements[0].focus();
          } catch (e) {}
          hasFinished = true;
        }
      }, 1000);
    }
  }

  getLastNavLocation(): string {
    return this.lastLocation;
  }

  updateNavLocation(url: string): void {
    if (!url) {
      return;
    }

    const newLocation = url.split('#')[0]; // Strip fragments
    if (newLocation !== this.currentLocation) {
      this.lastLocation = this.currentLocation;
      this.currentLocation = newLocation;
      sessionStorage.setItem(this.NAV_LAST_LOCATION_KEY, this.lastLocation);
      sessionStorage.setItem(
        this.NAV_CURRENT_LOCATION_KEY,
        this.currentLocation,
      );
    }
  }
}
