import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
  selector: 'lss-icon',
  template: `
    <div *ngIf="!!svgIcon">
      <mat-icon class="{{ class }}" svgIcon="{{ svgIcon }}">
        <ng-content></ng-content>
      </mat-icon>
      <span>{{ label }}</span>
    </div>
    <div *ngIf="!svgIcon">
      <mat-icon class="{{ class }}">
        <ng-content></ng-content>
      </mat-icon>
      <span>{{ label }}</span>
    </div>
  `,
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() svgIcon: string;
  @Input() label: string;
  @Input() class: string;

  constructor() {}

  ngOnInit(): void {}
}
