import {
  OnInit,
  ElementRef,
  Input,
  Directive,
  OnDestroy,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  AbstractControl,
} from '@angular/forms';
import { Subject } from 'rxjs';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseControl implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() label: string;

  destroyed$ = new Subject();
  required: boolean;
  readonly: boolean;
  ngControl: NgControl;
  disabled: boolean;

  constructor(
    protected elementRef: ElementRef,
    protected cd: ChangeDetectorRef,
    protected injector: Injector,
  ) {}

  val: any;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: unknown) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: (value: unknown) => void = () => {};

  onValueChange(newValue?: Object | string) {
    if (newValue === undefined) {
      this.onChange(this.value);
    } else {
      this.onChange(newValue);
      this.value = newValue;
    }
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);

    this.cd.markForCheck();
  }

  get value() {
    return this.val;
  }

  formatValue(): void {
    if (this.value) {
      this.value = this.value?.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    if (typeof this.setDisabledState === 'function') {
      this.setDisabledState(this.ngControl.disabled);
    }

    this.label =
      this.label ?? this.elementRef.nativeElement.getAttribute('label');
    this.required = this.isRequired();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  isRequired(): boolean {
    const validator =
      this.ngControl.control.validator &&
      this.ngControl.control.validator(
        this.ngControl.constructor as unknown as AbstractControl,
      );

    return !!(validator && validator.required);
  }
}
