import { FormArray, AbstractControl } from '@angular/forms';
import {
  Paragraph,
  TextRun,
  IParagraphOptions,
  ParagraphChild,
  Table,
} from 'docx';
import { BehaviorSubject } from 'rxjs';
import { Params } from '@angular/router';

export interface IUpdateModel<T> {
  cv: Partial<T>;
  skills?: ILookupValue[];
}

export interface Role {
  roleId: RoleId;
  displayName: string;
}

export interface IEmployeeCv {
  id: string;
  personalInfo: ICvPersonalInfo;
  contactInfo: ICvContactInfo;
  summary: ICvSummary;
  workExperience: ICvWorkExperienceItem[];
  technicalSkills: ITechnicalSkill[];
  skillsToLearn: ISkillRequestData[];
  languageProficiencies: ILanguageProficiency[];
  jobTitle: string;
  education: IEducationItem[];
  certifications: ICvCertificationItem[];
  courses: ICvCourseItem[];
  additionalInfo: IAdditionalInfo;
  integrityTimestamp: number;
  lastUpdatedDate: number;
  submittedDate: number;
  encryptedEmail: string;
  roleId: RoleId;
  bambooId: string;
  customOffice: string;
}

export interface ILocallyStoredState {
  routeUrl: string;
  cv: IEmployeeCv;
}

export interface ICvPersonalInfo {
  firstName: string;
  lastName: string;
  displayName: string;
}

export interface ICvContactInfo {
  workEmail: string;
}

export interface ICvSummary {
  description: string;
}

export interface IPeriodItem {
  startDate: number;
  endTimestamp: number;
  present: boolean;
}

export interface ICvWorkExperienceItem extends IPeriodItem {
  company: string;
  location?: string;
  roles: ICvWorkExperienceRole[];
  locked: boolean;
}

export interface ICvWorkExperienceRole extends IPeriodItem {
  title: string;
  description: string;
}

export interface IPeriod {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  present: boolean;
}

export interface ITechnicalSkill {
  skill: string;
  lastUsed: number;
  yearsOfExperience: number;
  skillProficiency: string;
  willingToUse?: boolean;
  comments?: string;
}

export interface ISkillRequestData {
  key: string;
  skill: string;
}

export interface ILanguageProficiency {
  language: string;
  proficiency: string;
}

export interface IEducationItem {
  institution: string;
  location: string;
  degrees: IEducationDegree[];
}

export interface IEducationDegree extends IPeriodItem {
  degreeOrSpecialization: string;
  achievements: string;
}

export interface ICvCourseItem {
  course: string;
  institution: string;
  notes: string;
  startDate: number;
  endTimestamp: number;
}

export interface ICvCertificationItem {
  certificate: string;
  institution: string;
  notes: string;
  validFrom: number;
  validTo: number;
}

export interface IAdditionalInfo {
  hobbies: string;
  additionalComments: string;
}

export interface ILoggedInUser {
  email: string;
  userRoles?: string[];
  idToken: string;
  expiry: number;
  refreshToken?: string;
  fullName?: string;
}

export interface ICognitoUser {
  attributes: {
    email: string;
  };
  signInUserSession: {
    idToken: IIdentityToken;
    accessToken: IIdentityToken;
    refreshToken: IIdentityToken;
  };
}

export const CognitoGroupsKey = 'cognito:groups';

export interface IIdentityToken {
  payload: {
    [CognitoGroupsKey]: string[];
    identities?: {
      providerName: string;
      userId: string;
    }[];
    sub?: string;
    email: string;
    exp: number;
    family_name?: string;
    given_name?: string;
  };
  jwtToken: string;
}

export interface IResponse {
  body: string;
  headers: any;
  statusCode: number;
}

export interface ISimpleConfirmData {
  header?: string;
  body?: string | BehaviorSubject<string>;
  confirmButtonText?: string;
  declineButtonText?: string;
  resultFilter: (e: any) => boolean;
}

export interface INavigationConfirmData extends ISimpleConfirmData {
  saveAndConfirmButtonText?: string;
}

export interface ILookupData {
  certifications: ILookupValue[];
  skillProficiencies: ILookupValue[];
  skillGroups?: IGroupableLookupValue[];
  skills: ILookupValue[];
  languageProficiencies: ILookupValue[];
  languages?: ILookupValue[];
  categories: ILookupValue[];
  jobOpenings: ILookupValue[] | null;
  willingToUse: ILookupValue[];
}

export interface ILookupValue<KeyT = string> {
  key: KeyT;
  displayName: string;
  group: string;
}

export interface ILookupSkillRequestData {
  displayName: string;
  categoryKey: string;
}

export interface IGroupableLookupValue {
  groupName: string;
  options: ILookupValue[];
}

export interface IInitialDataQuery<TData> {
  cv: TData;
  lookupData: ILookupData;
}

export interface IApplicationData {
  cv: IEmployeeCv;
  lookupData: ILookupData;
  linkedInEnabled?: string;
}

export enum TextAreaMode {
  Default = 'Default',
  BulletList = 'BulletList',
}

export enum RoleId {
  Employee = 0,
  Candidate = 1,
  ExternalHR = 2,
  PowerUser = 3,
  Admin = 4,
  HR = 5,
  ExEmployee = 6,
}

export interface IErrorPageModel {
  status: string;
  statusText: string;
}

export enum NotificationMode {
  Success = 'Success',
  Info = 'Info',
  Error = 'Error',
  Warning = 'Warning',
}

export interface INotificationConfig {
  title: string;
  body: string;
  mode: NotificationMode;
  close: () => void;
}

export interface ICvSearchResult {
  workEmail: string;
  encryptedWorkEmail: string;
  foundInSections: string[];
  fullName: string;
  id: string;
  jobTitle: string;
  summary: string;
  technicalSkills: string[];
  lastUpdateDate: number;
  cvType: string;
  department?: string;
  division?: string;
  bambooId: string;
  profilePicture$: BehaviorSubject<any>;
  isPictureLoaded$: BehaviorSubject<boolean>;
}

export interface PreviewRoleLabel {
  jobTitle: string;
  department?: string;
  division?: string;
  badgeColor?: 'orange' | 'gray';
}

export interface SearchResults {
  searchResults: ICvSearchResult[];
  countBySection: IObject;
  countByJobTitle: IObject;
  countByStanding: IObject;
}

export interface IVisibleFilters {
  standing: boolean;
  jobTitle: boolean;
  section: boolean;
}

export interface IObject {
  [key: string]: any;
}

export enum NavigationCommand {
  StopNavigation = 'StopNavigation',
  SaveAndNavigate = 'SaveAndNavigate',
  ResetAndNavigate = 'ResetAndNavigate',
}

export interface ModalDataTechnicalSkills {
  skillGroups: IGroupableLookupValue[];
  alreadyAddedSkills: { skill: ILookupValue }[];
}

export interface IUserObject {
  id: string;
  fullName: string;
}

export interface ICandidateCv extends IEmployeeCv {}

export interface IListCrudUtilService {
  insert(form: FormArray, index: number, item: AbstractControl);
  insertAtEnd(form: FormArray, item: AbstractControl);
  insertAtStart(form: FormArray, item: AbstractControl);
  append(form: FormArray, item: AbstractControl);
  moveUp(form: FormArray, element: any, currentIndex: number);
  moveDown(form: FormArray, element: any, currentIndex: number);
  move(form: FormArray, element: any, currentIndex: number, newIndex: number);
  remove(form: FormArray, index: number);
}

export interface IListCrudUtilWrapperService {
  insert(index: number, item: AbstractControl);
  insertAtEnd(item: AbstractControl);
  insertAtStart(item: AbstractControl);
  append(item: AbstractControl);
  moveUp(element: any, currentIndex: number);
  moveDown(element: any, currentIndex: number);
  move(element: any, currentIndex: number, newIndex: number);
  remove(index: number);
}

export interface IListInsertableService {
  insert(index: number, item: AbstractControl);
  insertAtEnd(item: AbstractControl);
  insertAtStart(item: AbstractControl);
  append(item: AbstractControl);
}

export interface IListRemovableService {
  remove(index: number);
}

export interface IListReorderableService {
  moveUp(element: any, currentIndex: number);
  moveDown(element: any, currentIndex: number);
  move(element: any, currentIndex: number, newIndex: number);
}

export interface IValidationResult {
  isValid: boolean;
  route: string | null;
}

export enum ImportStatus {
  default = 'default',
  uploading = 'uploading',
  importSuccessful = 'importSuccessful',
  importFailed = 'importFailed',
  noInfoFound = 'noInfoFound',
  noAccountFound = 'noAccountFound',
}

export enum LinkedInUpdateResponse {
  OK = 'OK',
  OldData = 'OldData',
  NoData = 'NoData',
}

export interface ILinkedInConfigResponse {
  key: string;
  value: string;
}

export interface IScrapingRespose {
  status: string;
  data: IEmployeeCv;
}

export interface IRegisterCandidateResult {
  encryptedEmail: string;
}

export interface IConvertExEmployeeResult {
  status: number;
  data: string | IRegisterCandidateResult;
}

export interface IGetCandidateDatadResult {
  isSuccess?: boolean;
  name:
    | SetPasswordItemResult.SUB
    | SetPasswordItemResult.RESET_URL
    | SetPasswordItemResult.SET_PASSWORD_URL
    | SetPasswordItemResult.GIVEN_NAME
    | SetPasswordItemResult.FAMILY_NAME
    | SetPasswordItemResult.EMAIL
    | SetPasswordItemResult.ROLE;
  value: string;
  errorMessage?: string;
}

export interface ISetPasswordResult {
  isSuccess: boolean;
  email: string;
  roleId: RoleId;
}

export enum SetPasswordItemResult {
  SUB = 'sub',
  RESET_URL = 'custom:reset_url',
  SET_PASSWORD_URL = 'custom:set_password_url',
  GIVEN_NAME = 'given_name',
  FAMILY_NAME = 'family_name',
  EMAIL = 'email',
  ROLE = 'role',
}

export enum RequestStatus {
  default = 'default',
  pending = 'pending',
  successful = 'successful',
  failed = 'failed',
}

export enum BambooHRPictureSize {
  original = 'original', // Uploaded size max 600x600 px
  large = 'large', // 340 x 340
  medium = 'medium', // 170 x 170
  small = 'small', // 150 x 150
  xs = 'xs', // 50 x 50
  tiny = 'tiny', // 20 x 20
}

export interface DocxParagraphObj extends IParagraphOptions {
  children: ParagraphChild[];
}

export type DocxRoleSection = TextRun | Paragraph | Table;

export class HttpErrorResponse {
  PropertyName: string;
  ErrorMessage: string;
  AttemptedValue: string;
  ErrorCode: number;
  StatusCode: number;
  constructor(init?: HttpErrorResponse) {
    this.PropertyName = init?.PropertyName;
    this.ErrorMessage = init?.ErrorMessage;
    this.AttemptedValue = init?.AttemptedValue;
    this.ErrorCode = init?.ErrorCode;
    this.StatusCode = init?.StatusCode;
  }
}

export interface ChangeableRole extends Role {
  roleChangeOptions: Role[];
}

export interface BreadcrumbItem {
  name: string;
  link: string;
  query?: Params;
}
