<div class="item-container">
  <lss-icon editable-item-add>language</lss-icon>
  <lss-autocomplete
    #focusField
    [label]="'Language'"
    [excludeOptions]="alreadySelectedLanguages$ | async"
    [formControl]="formGroup.controls.language"
    [options]="languageOptions"
    [exclude$]="alreadySelectedLanguages$"
    [isLanguage]="true"
  ></lss-autocomplete>

  <lss-select
    [label]="'Proficiency'"
    [formControl]="formGroup.controls.proficiency"
    [options]="languageProficiencyOptions"
    id="language-proficiency"
  ></lss-select>
</div>
