import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ProgressService } from '@lss/lss-ui';
import { DATA_SERVICE_TOKEN, RequestStatus } from '@lss/lss-types';
import { DataService } from '../../base/data.service';

interface IData {
  title: string;
  command: string;
}

@Component({
  selector: 'lss-admin-command-modal',
  templateUrl: './admin-command-modal.component.html',
  styleUrls: ['./admin-command-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCommandModalComponent implements OnInit {
  header: string;
  cancelButtonText = 'Cancel';
  RequestStatus = RequestStatus;
  currentStatus = RequestStatus.default;

  reindexAll = () => {
    this.currentStatus = RequestStatus.pending;
    this.progressService.showLoader();

    this.dataService.reindexAll().subscribe(
      (resp) => {
        this.progressService.hideLoader();
        this.currentStatus = RequestStatus.successful;
        this.cancelButtonText = 'Close';
        this.cd.markForCheck();
      },
      (e) => {
        this.progressService.hideLoader();
        this.currentStatus = RequestStatus.failed;
        this.cd.markForCheck();
      }
    );
  };
  copyDataFromProd = () => {
    this.currentStatus = RequestStatus.pending;
    this.progressService.showLoader();

    this.dataService.copyDataFromProd().subscribe(
      (resp) => {
        this.progressService.hideLoader();
        this.currentStatus = RequestStatus.successful;
        this.cancelButtonText = 'Close';
        this.cd.markForCheck();
      },
      (e) => {
        this.progressService.hideLoader();
        this.currentStatus = RequestStatus.failed;
        this.cd.markForCheck();
      }
    );
  };
  adminCommands: { [commandId: string]: () => void } = {
    reindexAll: this.reindexAll,
    copyDataFromProd: this.copyDataFromProd,
  };

  constructor(
    public dialogRef: MatDialogRef<AdminCommandModalComponent>,
    public progressService: ProgressService,
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: IData,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.header = this.data.title;
  }

  executeCommand(): void {
    if (this.adminCommands[this.data.command]) {
      this.adminCommands[this.data.command]();
    }
  }

  closeDialog(): void {
    this.progressService.hideLoader(true);
    this.dialogRef.close({ action: 'close' });
  }
}
