import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  DATA_SERVICE_TOKEN,
  IEducationDegree,
  IEducationItem,
  IEmployeeCv,
  IUpdateModel,
  NotificationMode,
} from '@lss/lss-types';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../constants';
import { DataService } from '../../base/data.service';
import { Observable } from 'rxjs';
import { AnimationBuilder } from '@angular/animations';
import {
  BaseListCrudComponent,
  ConfirmationService,
  creatingEditableItemDecorator,
  getPeriodFormGroup,
  ListCrudUtilService,
} from '@lss/lss-ui';
import { GlobalService } from '../../global.service';
import { NotificationService } from '../../base/notification/notification.service';
import { AuthService } from '../../base/auth.service';
import { trimRichEditorValue } from '../../utils';

@Component({
  selector: 'lss-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})
export class EducationComponent
  extends BaseListCrudComponent<IEmployeeCv, IEducationItem>
  implements OnInit
{
  newItem = newEducationItemControl;
  form: FormArray;
  pending = false;
  dataInvalid = false;
  candidate = Constants.UserRoles.candidate;
  focusedIndex = undefined;

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    protected activatedRoute: ActivatedRoute,
    protected cd: ChangeDetectorRef,
    protected confirmationService: ConfirmationService,
    protected animationBuilder: AnimationBuilder,
    protected crudUtilService: ListCrudUtilService,
    protected globalService: GlobalService,
    protected notificationService: NotificationService,
    protected authService: AuthService,
  ) {
    super(
      dataService,
      activatedRoute,
      confirmationService,
      cd,
      animationBuilder,
      crudUtilService,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dataService.dataInvalid$.subscribe((value) => {
      this.dataInvalid = value;
      this.showWarning();
    });
    this.showWarning();
  }

  showWarning(): void {
    if (this.dataInvalid) {
      this.notificationService.open({
        title: Constants.WarningNotification.title,
        body: Constants.WarningNotification.body,
        mode: NotificationMode.Warning,
      });
    }
  }

  onDataReady = function (cv: IEmployeeCv) {
    const education = cv?.education || [];
    this.form = new FormArray(education.map((e) => newEducationItemControl(e)));
    if (this.form.controls.length === 0) {
      this.form.push(newEducationItemControl());
    }

    if (
      !this.pending &&
      this.activatedRoute.snapshot.queryParams['highlight-missing-inputs']
    ) {
      this.pending = true;
      window.setTimeout(async () => {
        this.globalService.checkAndFocusInvalidFields(this.form);
        this.pending = false;
      }, 500);
    }
  }.bind(this);

  getUpdateModel(): IUpdateModel<IEmployeeCv> {
    return getEducationUpdateModel(this.form);
  }

  getRemoveConfirmation(index: number): () => Observable<boolean> {
    return () => {
      const formGroup = this.form.at(index) as FormGroup;
      const institution = formGroup.controls.institution.value;

      return this.modalRemoveConfirmationFactory(
        Constants.Education.ConfirmRemoveHeader,
        Constants.Education.ConfirmRemoveBody(institution),
      );
    };
  }

  addInstitutionToStart(): void {
    this.insert(0, newEducationItemControl());
    this.focusedIndex = 0;
  }

  educationSubmit: () => Observable<unknown> = () => {
    this.dataService.dataInvalid$.next(false);
    return this.submit();
  };

  submitToHR(): void {
    this.submit(true).subscribe(() => this.dataService.submitToHR());
  }
}

export const getEducationUpdateModel = (form: FormArray) => {
  const items = form.getRawValue().map((e) => {
    const { institution, location, degrees } = e;
    // tslint:disable-next-line:variable-name
    const _degrees = degrees.map((ee) => {
      const { period, degreeOrSpecialization, achievements } = ee;
      const achievementsTrimmed = achievements
        ? trimRichEditorValue(achievements)
        : null;
      return {
        ...period,
        degreeOrSpecialization,
        achievements: achievementsTrimmed,
      } as IEducationDegree;
    });
    return {
      institution,
      location,
      degrees: _degrees,
    } as IEducationItem;
  });
  return {
    cv: {
      education: items,
    },
  } as IUpdateModel<IEmployeeCv>;
};

export function newEducationItemControl(
  educationItem: IEducationItem = null,
): FormGroup {
  const { institution, location, degrees } = educationItem || {};

  const formGroup = new FormGroup({
    institution: new FormControl(institution, [Validators.required]),
    location: new FormControl(location, [Validators.required]),
    degrees: new FormArray(
      (degrees || ([{}] as IEducationDegree[])).map(newEducationDegreeControl),
    ),
  });

  creatingEditableItemDecorator(formGroup, educationItem);

  return formGroup;
}

export function newEducationDegreeControl(
  degree: IEducationDegree = null,
): FormGroup {
  const {
    startDate,
    endTimestamp,
    present,
    degreeOrSpecialization,
    achievements,
  } = degree || ({} as IEducationDegree);

  const formGroup = new FormGroup({
    period: getPeriodFormGroup(startDate, endTimestamp, present, false, [
      Validators.required,
    ]),
    degreeOrSpecialization: new FormControl(degreeOrSpecialization, [
      Validators.required,
    ]),
    achievements: new FormControl(achievements),
  });

  creatingEditableItemDecorator(formGroup, degree);

  return formGroup;
}
