import { Directive, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ISimpleConfirmData } from '@lss/lss-types';
import { BehaviorSubject } from 'rxjs';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseConfirmComponent {
  confirmButtonText: string;
  declineButtonText: string;
  body: string | BehaviorSubject<string>;
  header: string;

  constructor(
    protected modalRef: MatDialogRef<BaseConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ISimpleConfirmData
  ) {
    for (const [prop, value] of Object.entries(data)) {
      this[prop] = value;
    }
  }

  accept(): void {
    this.modalRef.close(true);
  }

  decline(): void {
    this.modalRef.close(false);
  }
}
