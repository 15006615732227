<ng-container>
  <div class="left">
    <div class="head">
      <mat-form-field class="year">
        <mat-label>{{
          startYear.empty ? formGroup.controls.startYear.value : null
        }}</mat-label>
        <mat-select
          #startYear
          [formControl]="formGroup.controls.startYear"
          (selectionChange)="setStartMonth(null)"
        >
          <mat-option *ngFor="let option of yearOptions" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="yearStepper">
        <button
          mat-icon-button
          class="mat-focus-indicator mat-icon-button mat-button-base"
          [disabled]="
            formGroup.controls.startYear.value <= minYear ||
            formGroup.controls.startYear.value >= maxYear
              ? true
              : null
          "
        >
          <mat-icon
            (click)="setStartYear(formGroup.controls.startYear.value - 1)"
            >keyboard_arrow_left</mat-icon
          >
        </button>
        <button
          mat-icon-button
          class="mat-focus-indicator mat-icon-button mat-button-base"
          [disabled]="
            formGroup.controls.startYear.value >= maxYear ? true : null
          "
        >
          <mat-icon
            (click)="setStartYear(formGroup.controls.startYear.value + 1)"
            >keyboard_arrow_right</mat-icon
          >
        </button>
      </div>
    </div>

    <div class="body">
      <mat-chip-list>
        <mat-chip
          *ngFor="let option of months; let index = index"
          [class.selected]="index === formGroup.controls.startMonth.value"
          (click)="setStartMonth(index)"
          [disabled]="checkMonthDisabled(index, 'startMonth')"
        >
          {{ option }}</mat-chip
        >
      </mat-chip-list>
    </div>
  </div>

  <div class="divider"></div>

  <div class="right">
    <div class="head">
      <mat-form-field class="year">
        <mat-label>{{
          endYear.empty && !formGroup.controls.present.value
            ? formGroup.controls.endYear.value
            : null
        }}</mat-label>
        <mat-select
          #endYear
          [formControl]="formGroup.controls.endYear"
          (selectionChange)="setEndMonth(null)"
        >
          <mat-option *ngFor="let option of endYearOptions" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="yearStepper">
        <button
          mat-icon-button
          class="mat-focus-indicator mat-icon-button mat-button-base"
          [disabled]="
            formGroup.controls.present.value ||
            formGroup.controls.endYear.value <= minEndYear ||
            formGroup.controls.endYear.value >= maxEndYear
              ? true
              : null
          "
          (click)="setEndYear(formGroup.controls.endYear.value - 1)"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button
          mat-icon-button
          class="mat-focus-indicator mat-icon-button mat-button-base"
          [disabled]="
            formGroup.controls.present.value ||
            formGroup.controls.endYear.value >= maxEndYear
              ? true
              : null
          "
          (click)="setEndYear(formGroup.controls.endYear.value + 1)"
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>

      <mat-checkbox
        [color]="'primary'"
        class="present"
        [formControl]="formGroup.controls.present"
        (change)="disableEndSelection()"
        *ngIf="allowPresent"
      >
        Present
      </mat-checkbox>
    </div>

    <div class="body">
      <mat-chip-list [disabled]="formGroup.controls.present.value">
        <mat-chip
          *ngFor="let option of months; let index = index"
          [class.selected]="index === formGroup.controls.endMonth.value"
          [disabled]="checkMonthDisabled(index, 'endMonth')"
          (click)="setEndMonth(index)"
          >{{ option }}</mat-chip
        >
      </mat-chip-list>
    </div>
  </div>
</ng-container>
