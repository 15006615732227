import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { BaseConfirmComponent } from '../base-confirm.component';

@Component({
  selector: 'lss-stay-confirm',
  template: `
    <div class="input-block">
      <h1 class="title">{{ header }}</h1>
      <div class="body">{{ body }}</div>
    </div>
    <div class="modal-buttons">
      <lss-button (action)="accept()" [color]="'secondary'">
        <span>
          {{ declineButtonText }}
        </span>
      </lss-button>
      <lss-button (action)="decline()">
        <span>
          {{ confirmButtonText }}
        </span>
      </lss-button>
    </div>
  `,
  styleUrls: ['./stay-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StayConfirmComponent
  extends BaseConfirmComponent
  implements OnInit
{
  constructor(
    protected modalRef: MatDialogRef<StayConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
  ) {
    super(modalRef, data);
  }

  ngOnInit(): void {
    this.confirmButtonText = this.confirmButtonText ?? 'Confirm';
    this.declineButtonText = this.declineButtonText ?? 'Cancel';
  }
}
