import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { filter, map, pairwise, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../base/auth.service';
import { DataService } from '../base/data.service';
import { CvGenerationService } from '../preview/cv-generation.service';
import { getDataFromSessionStorage } from '@lss/lss-ui';
import { environment } from '../../environments/environment';
import { Constants } from '../constants';
import {
  BambooHRPictureSize,
  DATA_SERVICE_TOKEN,
  ICvPersonalInfo,
  IEmployeeCv,
  ILoggedInUser,
  ILookupData,
  NotificationMode,
  RoleId,
} from '@lss/lss-types';
import { ModalService } from '../base/modal.service';
import { FormArray } from '@angular/forms';
import { newExperienceItemControl } from '../cv/work/work.component';
import { newEducationItemControl } from '../cv/education/education.component';
import { newLanguageItemControl } from '../cv/languages/languages.component';
import { newSkillItemControl } from '@lss/lss-technical-skills';
import { newCertificationItemControl } from '../cv/certification/certification.component';
import { NotificationService } from '../base/notification/notification.service';
import { Location } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { CvFileBuilderService } from '../base/cv-file-builder/cv-file-builder.service';
import { getNewRandom } from '../base/login/login-utils';

@Component({
  selector: 'lss-logged-actions',
  templateUrl: './logged-actions.component.html',
  styleUrls: ['./logged-actions.component.scss'],
})
export class LoggedActionsComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  authenticatedUser$: Observable<ILoggedInUser>;
  nextRoute: any;
  canGenerateCv$: any;
  canImportFromLinkedin$: Observable<boolean>;
  userFullName$: Observable<string>;
  canCopyUrl$: Observable<boolean>;
  canConvertExEmployee$: Observable<boolean>;
  subscriptions = new Subscription();
  canRegisterCandidate = Constants.RoleActions.canRegisterCandidate;
  CandRegisterExternalHr = Constants.RoleActions.canRegisterExternalHr;
  canAccessActions = Constants.RoleActions.canAccessActions;
  canSearch = Constants.RoleActions.canSearch;
  canGenerateCv = Constants.RoleActions.canGenerateCV;
  hr = Constants.UserRoles.hr;
  canShareCandidateCv = Constants.RoleActions.canShareCandidateLink;
  fullName: string;
  showBackButton: boolean;
  userLocation: string;
  isExternalHr: boolean;
  isCandidate: boolean;
  isCandidateCv$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  isExEmployeeCv$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  isPowerUser: boolean;
  isAdmin: boolean;
  isSearchVisible: boolean;
  previousUrl: string;
  loggedUserProfilePicture$ = new BehaviorSubject<any>(null);
  isPictureLoaded$ = new BehaviorSubject<boolean>(false);
  currentUser: ILoggedInUser;

  constructor(
    private router: Router,
    public authService: AuthService,
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    private cvGenerationService: CvGenerationService,
    private modalService: ModalService,
    protected notificationService: NotificationService,
    private location: Location,
    private clipboard: Clipboard,
    private cvFileBuilder: CvFileBuilderService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.authenticatedUser$ = this.authService.authenticatedUser$;
    this.userFullName$ = this.authService.fullName$;
    this.fullName =
      (await this.authService.getLoggedInUser()).fullName ??
      localStorage.getItem(Constants.StorageKeys.fullname);
    this.isPowerUser = await this.authService.userHasRole(
      Constants.UserRoles.powerUser,
    );
    this.isAdmin = await this.authService.userHasRole(
      Constants.UserRoles.admin,
    );
    this.isExternalHr = await this.authService.userHasRole(
      Constants.UserRoles.externalHr,
    );
    this.isCandidate = await this.authService.userHasRole(
      Constants.UserRoles.candidate,
    );

    this.authenticatedUser$.subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });

    this.dataService.cv$
      .pipe(
        filter((cv) => !!cv),
        tap((cv) => {
          this.isCandidateCv$.next(cv.roleId === RoleId.Candidate);
          this.isExEmployeeCv$.next(cv.roleId === RoleId.ExEmployee);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((cvdata) => {
        if (
          cvdata.bambooId !== null &&
          cvdata.roleId === RoleId.Employee &&
          this.currentUser.email === cvdata.contactInfo.workEmail
        ) {
          this.dataService
            .getProfilePicture(cvdata.bambooId, BambooHRPictureSize.small)
            .subscribe((picture) => {
              if (picture) {
                const img = 'data:image/jpg;base64,' + picture;
                this.loggedUserProfilePicture$.next(img);
                this.isPictureLoaded$.next(true);
              }
            });
        }
      });

    this.router.events
      .pipe(
        filter((e: any) => e instanceof NavigationEnd),
        pairwise(),
      )
      .subscribe((e: any) => {
        this.previousUrl = e[0].urlAfterRedirects;
      });

    this.router.events.subscribe(async () => {
      this.userLocation = this.router.routerState.snapshot.url;
      this.showBackButton =
        this.userLocation?.startsWith(
          `/${Constants.Routes.RegisterCandidate}`,
        ) ||
        this.userLocation?.startsWith(
          `/${Constants.Routes.RegisterExternalHr}`,
        ) ||
        this.userLocation?.startsWith(`/${Constants.Routes.Search}`);

      this.isSearchVisible = !this.userLocation?.startsWith(
        `/${Constants.Routes.Home}`,
      );
    });

    const routeAllowsCvGenerate$ = this.router.events.pipe(
      map((e) => <ActivationEnd>e),
      filter((e) => e instanceof ActivationEnd),
      filter((e: ActivationEnd) => {
        const path = e.snapshot.routeConfig.path;
        return (
          path === Constants.Routes.Cv ||
          path === Constants.Routes.Search ||
          path === Constants.Routes.RegisterCandidate ||
          path === Constants.Routes.Preview ||
          path === Constants.Routes.Home ||
          path === Constants.Routes.AdminUserManagement ||
          path === Constants.Routes.AdminSkillManagement ||
          path === Constants.Routes.AdminCategoryManagement ||
          path === Constants.Routes.AdminEventsActions ||
          path === Constants.Routes.AdminHistory
        );
      }),
      map((e: any) => !!e.snapshot.data.allowCvGenerate),
    );

    this.canImportFromLinkedin$ = this.router.events.pipe(
      filter((e) => e instanceof ActivationEnd),
      map(
        (e: ActivationEnd) =>
          e.snapshot.routeConfig.path === Constants.Routes.Cv,
      ),
    );

    const routeAllowsCopyUrl$ = this.router.events.pipe(
      filter((e) => e instanceof ActivationEnd),
      map(
        (e: ActivationEnd) =>
          e.snapshot.routeConfig.path === Constants.Routes.Preview,
      ),
    );

    this.canCopyUrl$ = combineLatest([
      this.authService.userCanPerformAction$(this.canShareCandidateCv),
      routeAllowsCopyUrl$,
    ]).pipe(
      map(([canPerform, routeAllows]) => {
        return canPerform && routeAllows;
      }),
    );

    this.canConvertExEmployee$ = combineLatest([
      this.authService.userCanPerformAction$(this.canRegisterCandidate),
      routeAllowsCopyUrl$,
    ]).pipe(
      map(([canPerform, routeAllows]) => {
        return canPerform && routeAllows;
      }),
    );

    this.canGenerateCv$ = combineLatest([
      this.authService.userCanPerformAction$(this.canGenerateCv),
      this.authenticatedUser$.pipe(map((e) => !!e)),
      routeAllowsCvGenerate$,
    ]).pipe(
      map(([canPerform, isUserAuthenticated, routeAllows]) => {
        return canPerform && isUserAuthenticated && routeAllows;
      }),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.subscriptions.unsubscribe();
    this.isPictureLoaded$.next(false);
  }

  showReportButton(): boolean {
    return !(this.isCandidate || this.isExternalHr);
  }

  showActionsButton(): boolean {
    return (
      !(
        this.userLocation?.startsWith(`/${Constants.Routes.TipsAndTricks}`) ||
        this.userLocation?.startsWith(`/${Constants.Routes.Search}`) ||
        (this.userLocation?.startsWith(`/${Constants.Routes.Home}`) &&
          this.isPowerUser) ||
        this.userLocation?.startsWith(
          `/${Constants.Routes.RegisterCandidate}`,
        ) ||
        this.userLocation?.startsWith(`/${Constants.Routes.RegisterExternalHr}`)
      ) || this.isAdmin
    );
  }

  getCVDocx(): void {
    this.cvFileBuilder.getCVDocx().then();
  }

  logout(): void {
    localStorage.setItem(Constants.StorageKeys.loggedIn, getNewRandom());
    this.authService.refreshToken$.next(null);
    this.authService.isBasicLogout.next(true);
    this.router.navigate([Constants.Routes.Login]).then((go) => {
      if (go) {
        this.authService.signOut();
      }
    });
  }

  reportIssue(): void {
    window.open(Constants.CompanyInfo.IssuesLikn, '_blank');
  }

  goToRegisterCandidate(convert: boolean = false): void {
    const commands: any[] = [Constants.Routes.RegisterCandidate];
    if (convert) {
      commands.push({ convert: true });
    }
    this.router.navigate(commands);
  }

  goToRegisterExternalHr(): void {
    this.router.navigate([Constants.Routes.RegisterExternalHr]);
  }

  ValidateFields(cvData: any) {
    let firstInvalidTabPath: string = null;

    const workExperienceForm = new FormArray(
      (cvData.workExperience ?? []).map((e) => newExperienceItemControl(e)),
    );
    if (!workExperienceForm.valid && !firstInvalidTabPath) {
      firstInvalidTabPath = Constants.Routes.CvWorkExperience;
    }

    const educationForm = new FormArray(
      (cvData.education ?? []).map((e) => newEducationItemControl(e)),
    );
    if (!educationForm.valid && !firstInvalidTabPath) {
      firstInvalidTabPath = Constants.Routes.CvEducation;
    }

    const lookupData = this.dataService.lookupData$.getValue() as ILookupData;
    const technicalSkillsForm = new FormArray(
      (cvData.technicalSkills ?? []).map((e) =>
        newSkillItemControl(e, lookupData),
      ),
    );
    if (!technicalSkillsForm.valid && !firstInvalidTabPath) {
      firstInvalidTabPath = Constants.Routes.CvTechnicalSkills;
    }

    const languageOptions = this.dataService.getLanguageOptions();
    const languageProficienciesForm = new FormArray(
      (cvData.languageProficiencies ?? []).map((e) =>
        newLanguageItemControl(languageOptions, e, lookupData),
      ),
    );
    if (!languageProficienciesForm.valid && !firstInvalidTabPath) {
      firstInvalidTabPath = Constants.Routes.CvLanguages;
    }

    const certificateForm = new FormArray(
      (cvData.certifications ?? []).map((e) => newCertificationItemControl(e)),
    );
    if (!certificateForm.valid && !firstInvalidTabPath) {
      firstInvalidTabPath = Constants.Routes.CvCertification;
    }

    if (firstInvalidTabPath !== null) {
      this.notificationService.open({
        title: Constants.WarningNotification.title,
        body: Constants.WarningNotification.body,
        mode: NotificationMode.Warning,
      });
      this.router.navigate([firstInvalidTabPath], {
        queryParams: { 'highlight-missing-inputs': 'true' },
      });

      return false;
    }
  }

  // TODO: remove this duplicate
  getCV(): void {
    // path is unused, real path specified in back-end config
    const docLink =
      environment.oauthRedirectSignIn + 'assets/docTemplate/cv2.docx';
    const cvData = getDataFromSessionStorage('cv') || ({} as IEmployeeCv);

    // NOTICE hotfix for LSS-1150
    // const isFieldsValid = this.ValidateFields(cvData);
    // if (isFieldsValid === false) return;

    const email = cvData.contactInfo.workEmail;
    this.dataService.getDocx({ email, docLink }).subscribe(
      (data) => this.getDocxFile(data, cvData.personalInfo),
      () => console.log('Error downloading file'),
    );
  }

  getCVPdf(): void {
    const cvData = getDataFromSessionStorage('cv') || ({} as IEmployeeCv);

    // NOTICE hotfix for LSS-1150
    // const isFieldsValid = this.ValidateFields(cvData);
    // if (isFieldsValid === false) return;

    this.cvGenerationService.generatePdf();
  }

  // TODO: remove this duplicate
  getDocxFile(encodedFile: string, personalInfo: ICvPersonalInfo): void {
    const byteCharacters = atob(encodedFile);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download =
      '28Stone_CV_' + personalInfo.firstName + '_' + personalInfo.lastName;
    anchor.href = url;
    anchor.click();
  }

  openLinkedInModal(): void {
    this.modalService.openModal();
  }

  navigateToPreviosPage() {
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl);
    } else {
      this.location.back();
    }
  }

  copyCandidateCvLink(): void {
    const cvModel = getDataFromSessionStorage('cv') as IEmployeeCv;
    const candidateEncryptedUrl =
      this.router['location']._locationStrategy._platformLocation._location
        .origin +
      '/' +
      Constants.Routes.CandidateCv +
      '?url=' +
      cvModel.encryptedEmail;

    this.clipboard.copy(candidateEncryptedUrl);

    this.dataService.notificationService.open(
      Constants.Notifications.CopyLinkToClipboardSuccess,
    );
  }

  handleMenuClick(): void {
    // Hack to force close table pagination dropdown (LSS-1774)
    const overlayBackdrop = document
      .getElementById('lss-custom-overlay')
      .getElementsByClassName('cdk-overlay-backdrop')
      .item(0);
    if (overlayBackdrop) {
      overlayBackdrop.dispatchEvent(new MouseEvent('click'));
    }
  }
}
