import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject, from } from 'rxjs';
import { AuthService } from '../auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Constants } from '../../constants';
import {
  setDataToSessionStorage,
  removeDataFromSessionStorage,
  ConfirmationService,
} from '@lss/lss-ui';
import { enterApp } from './login-utils';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import { DataService } from '../data.service';
import { emailStructureValidator } from '../../utils';

@Component({
  selector: 'lss-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  errorText: string;
  hasUser$: Observable<boolean>;
  form = new FormGroup({
    email: new FormControl(null, [emailStructureValidator()]),
    password: new FormControl(),
  });
  destroyed$ = new Subject();
  canSignIn = false;
  isLoginInProgress = false;
  isChromium = false;

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
  ) {
    this.hasUser$ = authService.authenticatedUser$.pipe(
      tap(() => cd.detectChanges()),
      map((e) => !!e),
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.isChromium = !!(window.navigator as any).userAgentData;
    if (!this.isChromium) {
      this.errorText = Constants.Warnings.browserNotChrome;
    }
    if (
      localStorage.getItem(Constants.StorageKeys.isLoginInProgress) === 'true'
    ) {
      this.isLoginInProgress = true;
    }
    if (sessionStorage.getItem(Constants.StorageKeys.tokenExpired) === 'true') {
      this.errorText = 'Your session has expired. Please sign in again';
    }

    this.canSignIn =
      this.form?.controls.email &&
      this.form?.controls.password &&
      this.form?.controls.email.valid;

    this.form?.valueChanges.subscribe((v) => {
      this.canSignIn = v.email && v.password && this.form?.controls.email.valid;
    });

    from(this.authService.isAuthenticated()).subscribe(async (currentUser) => {
      if (
        currentUser &&
        this.dataService.loggedInNumber ===
          localStorage.getItem(Constants.StorageKeys.loggedIn)
      ) {
        await enterApp(
          this.dataService,
          this.authService,
          this.router,
          this.confirmationService,
        );
      }
      localStorage.setItem(Constants.StorageKeys.isLoginInProgress, 'false');
      this.isLoginInProgress = false;
      this.cd.markForCheck();
    });
  }

  authenticateWithGoogle(): void {
    this.saveReturnUrl();
    this.authService.federatedSignIn().then();
    localStorage.setItem(Constants.StorageKeys.isLoginInProgress, 'true');
  }

  authenticate(email: string, password: string): void {
    this.saveReturnUrl();

    this.authService
      .signIn(email.toLowerCase(), password)
      .then(async () => {
        this.cd.markForCheck();
        await enterApp(
          this.dataService,
          this.authService,
          this.router,
          this.confirmationService,
        );
      })
      .catch((e) => {
        this.errorText =
          e?.code === Constants.Authentication.UserNotFoundException ||
          e?.code === Constants.Authentication.UserNotAuthorizedException
            ? Constants.Authentication.UserNotFoundMessage
            : e.message;

        this.cd.markForCheck();
      });
  }

  private saveReturnUrl(): void {
    removeDataFromSessionStorage(Constants.Login.ReturnUrlSessionKey);
    const returnUrl = this.route.snapshot.queryParamMap.get(
      Constants.Login.ReturnUrlSessionKey,
    );

    if (returnUrl && !returnUrl.includes('login')) {
      setDataToSessionStorage(returnUrl, Constants.Login.ReturnUrlSessionKey);
    }
  }
}
