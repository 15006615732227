<lss-toolbar [urlRoute]="routeToHome">
  <lss-logged-actions></lss-logged-actions>
</lss-toolbar>
<div class="content">
  <h1>Tips and tricks</h1>
  <div class="tips">
    <div class="page-links">
      <a (click)="scrollToElement('summary')">Summary</a> |
      <a (click)="scrollToElement('workExperience')"> Work experience</a> |
      <a (click)="scrollToElement('hobbies')"> Hobbies and interests</a>
    </div>
    <div>
      <h3 id="summary">Summary</h3>
      <div class="tips-content">
        <li>
          Write about your overall experience, what have you done, what are your
          strong suits.
        </li>
        <li>
          Mention your current job title and professional experience. Say how
          you want to help the client achieve their goals. Add info on your key
          achievements to prove you can deliver results.
        </li>
        <li>
          Do not mention any personal information (like marital status etc).
        </li>
        <li>Try to avoid writing "I", but you can write in first person.</li>
      </div>
      <h3 id="workExperience">Work experience</h3>
      <div class="tips-content">
        <li>
          Do not mention client names for 28Stone projects. You can write what
          they do, but not the name. For example - Japanese bank's branch in
          NewYork, not Mizuho.
        </li>
        <li>
          Mention the key responsibilities you have on daily basis, the things
          you did and were responsible for.
        </li>
        <li>
          Write all the technologies the project was using and separate the
          technologies you were specifically working with.
        </li>
        <li>
          Write all your achievements, finished big tasks, managed big releases.
        </li>
        <li>
          If you have a lot of experience/previous positions, you don’t need to
          include roles from more than 10 years ago – unless, of course, they
          are particularly relevant to the job for which you are applying.
        </li>
        <li>Write all of your experiences in detail.</li>
      </div>

      <h3 id="hobbies">Hobbies and interests</h3>
      <div class="tips-content">
        <li>
          Try to keep this section in SkillSet interesting, if you use it.
          Include unusual hobbies and try to avoid really common hobbies and
          interests, such as reading, walking, etc.
        </li>
        <li>
          You can add an Open source contribution or GitHub account if you have
          side projects or hobby projects that you can share.
        </li>
      </div>
    </div>
  </div>
</div>
