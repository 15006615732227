import {
  Component,
  Input,
  forwardRef,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { BaseControl } from '../base.control';

export class FormErrorStateManager implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

// NOTE: minlength & maxlength should be added as attr because in case of
// [maxLength] it converts falsy values to "0". In case of [attr] it doesn't
// apply these attributes if values are falsy (What we need)

@Component({
  selector: 'lss-text',
  template: `
    <mat-form-field appearance="standard">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <input
        #inputField
        matInput
        [errorStateMatcher]="customMatcher ? customMatcher : matcher"
        [formControl]="formControl"
        [required]="required"
        [type]="type"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [attr.maxlength]="maxLength"
        [attr.minlength]="minLength"
        [maxLength]="maxLength"
        [minLength]="minLength"
      />
      <mat-icon
        color="primary"
        *ngIf="showVisibilityIcon"
        matSuffix
        (click)="togglePasswordVisibility()"
        >{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon
      >
      <mat-error *ngIf="!hideError">{{ errorMessage }}</mat-error>
    </mat-form-field>
  `,
  styleUrls: ['./text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent extends BaseControl {
  public matcher = new FormErrorStateManager();
  public showPassword;

  @Input() type: 'text' | 'password' = 'text';
  @Input() placeholder: string;
  @Input() readonly: false;
  @Input() formControl: FormControl;
  @Input() showVisibilityIcon?: boolean;
  @Input() customMatcher: ErrorStateMatcher;
  @Input() maxLength = 160;
  @Input() minLength: number;
  @Input() errorMessage: string = 'Field is mandatory';
  @Input() hideError: boolean;
  @ViewChild('inputField') inputField: ElementRef;

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    this.showPassword ? (this.type = 'text') : (this.type = 'password');
  }

  public focusInput(): void {
    this.inputField.nativeElement.focus();
  }
}
