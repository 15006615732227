import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, from, Observable, throwError } from 'rxjs';
import {
  ICvSearchResult,
  IEmployeeCv,
  IInitialDataQuery,
  ILocallyStoredState,
  ILookupData,
  IDataService,
  IRegisterCandidateResult,
  ICandidateCv,
  IValidationResult,
  ISetPasswordResult,
  IGetCandidateDatadResult,
  IResponse,
  HttpErrorResponse,
  ChangeableRole,
  ILookupSkillRequestData,
  ILookupValue,
  ILinkedInConfigResponse,
  IConvertExEmployeeResult,
} from '@lss/lss-types';
import {
  ConfirmationService,
  getTimestamp,
  ProgressService,
  regroupLookups,
  validateCertifications,
  validateEducation,
  validateLanguages,
  validateTechnicalSkills,
  validateWorkExperience,
} from '@lss/lss-ui';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { API } from 'aws-amplify';
import { Constants } from '../constants';
import { AuthService } from './auth.service';
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { NotificationService } from './notification/notification.service';
import { GenericRetryStrategy } from '../pipes/generic-retry-strategy';
import { SubmitConfirmComponent } from 'src/lss-ui/src/lib/form/confirmation/submit-confirm/submit-confirm.component';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DataService implements IDataService<IEmployeeCv> {
  lookupData$ = new BehaviorSubject<ILookupData>(null);
  cv$ = new BehaviorSubject<IEmployeeCv>(null);
  localCv$ = new BehaviorSubject<ILocallyStoredState>(null);
  dataInvalid$ = new BehaviorSubject<boolean>(false);
  isCandidate: boolean;
  cvSaved$ = new BehaviorSubject<boolean>(false);
  cvState$ = new BehaviorSubject<Partial<IEmployeeCv>>(null);
  loggedInNumber = localStorage.getItem(Constants.StorageKeys.loggedIn);
  formState = new BehaviorSubject<FormGroup<any>>(null);
  previewEmployeePicture$ = new BehaviorSubject<any>(null);
  isProfilePictureLoaded$ = new BehaviorSubject<boolean>(false);
  showDefaultProfilePicture$ = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private authService: AuthService,
    private progressService: ProgressService,
    private confirmationService: ConfirmationService,
    public notificationService: NotificationService,
  ) {
    this.authService.authenticatedUser$
      .pipe(
        filter((e) => !!e),
        first(),
        tap((e) => {
          const email = e.email;
          const localCv = this.getLocalCv(email);
          if (localCv) {
            this.localCv$.next(localCv);
          }
        }),
      )
      .subscribe();
    this.authService
      .userHasRole$(Constants.UserRoles.candidate)
      .subscribe((isCandidate) => (this.isCandidate = isCandidate));
  }

  regroupLookups(): void {
    regroupLookups(this.lookupData$);
  }

  getAuthHeaders$(): Observable<Record<string, string>> {
    if (!this.authService.authenticatedUser$.getValue()) {
      this.authService.getLoggedInUser().then((user) => {
        this.authService.authenticatedUser$.next(user);
      });
    }
    return this.authService.authenticatedUser$.pipe(
      filter((e) => !!e),
      map((e) => ({
        [Constants.HttpHeaders.Authorization]: e.idToken,
      })),
    );
  }

  private getNoAuthHeaders$(): Observable<Record<string, string>> {
    return this.authService.authenticatedUser$.pipe(
      map((e) => ({
        [Constants.HttpHeaders.Authorization]: '',
      })),
    );
  }

  getDataOperationHandler(methodName: string, endpoint: string): void {
    if (
      methodName === Constants.HttpMethods.Post &&
      endpoint === Constants.Endpoints.Cv
    ) {
      this.notificationService.open(
        this.isCandidate
          ? Constants.Notifications.SendToHRReminderSuccess
          : Constants.Notifications.SaveCvSuccess,
      );
    }

    if (
      methodName === Constants.HttpMethods.Post &&
      endpoint === Constants.Endpoints.CandidateCv
    ) {
      this.notificationService.open(
        Constants.Notifications.SendSubbmitionSuccess,
      );
    }
  }

  storeCvLocally(cv: Partial<IEmployeeCv>): void {
    const routeUrl = this.progressService.router.url;
    localStorage.setItem(
      cv.contactInfo.workEmail,
      JSON.stringify({ routeUrl, cv }),
    );
  }

  getLocalCv(email: string): ILocallyStoredState {
    const json = localStorage.getItem(email);
    if (!json) {
      return null;
    }
    return JSON.parse(json) as ILocallyStoredState;
  }

  removeLocalCv(email: string): void {
    localStorage.removeItem(email);
    this.localCv$.next(null);
  }

  getErrorDataOperationHandler(
    methodName: string,
    endpoint: string,
    message?: string,
  ): void {
    if (
      methodName === Constants.HttpMethods.Post &&
      endpoint === Constants.Endpoints.Cv
    ) {
      this.notificationService.open(Constants.Notifications.SaveCvError);
    }

    if (
      methodName === Constants.HttpMethods.Get &&
      endpoint === Constants.Endpoints.EmployeeCv
    ) {
      this.authService
        .userHasRole(Constants.UserRoles.externalHr)
        .then((isExternalHr) => {
          if (isExternalHr) {
            this.notificationService.open(
              Constants.Notifications.GetEmployeeExternalHrError,
            );
            this.router.navigate([Constants.Routes.Home]);
          } else {
            this.router.navigate([Constants.Routes.NotFound]);
          }
        });
    }

    if (
      methodName === Constants.HttpMethods.Get &&
      endpoint === Constants.Endpoints.Docx
    ) {
      this.notificationService.open(
        Constants.Notifications.GenerateCvError(message),
      );
    }
  }

  wrapRequest<T>(
    methodName: string,
    endpoint: string,
    request: { queryStringParameters?: any; body?: any; response?: boolean },
    errorHandler?: (error: Error) => void,
    dontShowSuccessNotification = false,
    auth = true,
  ): Observable<T> {
    const authHeader = auth ? this.getAuthHeaders$() : this.getNoAuthHeaders$();
    const apiName = environment.apiName + (auth ? '' : 'Public');

    return authHeader.pipe(
      tap(() => this.progressService.showLoader()),
      switchMap((e) =>
        defer(() =>
          API[methodName].call(API, apiName, endpoint, {
            headers: e,
            ...request,
          }),
        ).pipe(
          tap(
            () =>
              !dontShowSuccessNotification &&
              this.getDataOperationHandler(methodName, endpoint),
          ),
          retryWhen(GenericRetryStrategy()),
          // @ts-ignore
          catchError((err) => {
            // NOTICE BE returns 504 also when user is also not authorized as well as Gateway has some timeout,
            // so we should check on UI if user is logged in
            if (!auth) {
              return throwError(() => err.response);
            }

            return defer(async () => {
              const isAuthenticated = await this.authService.isAuthenticated();

              const isUnauthorized = err?.response?.status === 401;

              if (!isAuthenticated || isUnauthorized) {
                this.router.navigate([Constants.Routes.Login]).then(() => {
                  this.authService.signOut();
                });
                return null;
              }

              this.getErrorDataOperationHandler(
                methodName,
                endpoint,
                err.response?.statusText,
              );

              if (typeof errorHandler === 'function') {
                errorHandler(err);
              }

              return err;
              // NOTE: If we return new throwError instead of error then
              // we won't catch error but get Observable in Observable which
              // will end up in success. Therefore we need to throw this error separately
            }).pipe(
              switchMap((err) => {
                // If error has response then returning it
                if (err?.response) {
                  let errorResponse;

                  // In case if server responded with string message as body, we convert it
                  if (typeof err.response.data === 'string') {
                    errorResponse = new HttpErrorResponse({
                      ...err,
                      ErrorMessage: err.response.data,
                      StatusCode: err.response.status,
                    });
                  } else {
                    errorResponse = new HttpErrorResponse({
                      ...err.response.data[0],
                      StatusCode: err.response.status,
                    });
                  }

                  return throwError(() => errorResponse);
                } else {
                  // Otherwise returning original error
                  return throwError(() => err);
                }
              }),
            );
          }),
          finalize(() => this.progressService.hideLoader()),
        ),
      ),
      tap(() => {
        if (
          methodName === Constants.HttpMethods.Get &&
          endpoint === Constants.Endpoints.Cv
        ) {
          window['globalRetryOverlay'] = false;
        }
      }),
      map((e) => e as T),
      first(),
    );
  }

  searchCv(queryStringParameters: {
    search: string;
    exact?: boolean;
  }): Observable<ICvSearchResult[]> {
    if (!queryStringParameters.exact) {
      delete queryStringParameters.exact; // Do not send empty param
    }
    return this.wrapRequest<ICvSearchResult[]>(
      Constants.HttpMethods.Get,
      Constants.Endpoints.Search,
      { queryStringParameters },
    );
  }

  getVersion(): Observable<string> {
    return this.wrapRequest<string>(
      Constants.HttpMethods.Get,
      Constants.Endpoints.Version,
      null,
    );
  }

  get(email: string): Observable<IInitialDataQuery<IEmployeeCv>> {
    return this.wrapRequest<IInitialDataQuery<IEmployeeCv>>(
      Constants.HttpMethods.Get,
      Constants.Endpoints.Cv,
      { queryStringParameters: { email } },
    );
  }

  getEmployeeCv(queryStringParameters: {
    email: string;
  }): Observable<IInitialDataQuery<IEmployeeCv>> {
    return this.wrapRequest<IInitialDataQuery<IEmployeeCv>>(
      Constants.HttpMethods.Get,
      Constants.Endpoints.EmployeeCv,
      { queryStringParameters },
    );
  }

  save(
    body: Partial<IEmployeeCv>,
    dontShowSuccessNotification = false,
  ): Observable<IEmployeeCv> {
    body.integrityTimestamp = getTimestamp();
    return this.wrapRequest<IEmployeeCv>(
      Constants.HttpMethods.Post,
      Constants.Endpoints.Cv,
      { body },
      () => this.storeCvLocally(body),
      dontShowSuccessNotification,
    );
  }

  saveLookups(body: {
    Items: Record<string, ILookupSkillRequestData[]>;
    FirstName: string;
    LastName: string;
    Email: string;
  }): Observable<any> {
    return this.getAuthHeaders$().pipe(
      switchMap((e) =>
        from(
          API.post(environment.apiName, Constants.Endpoints.Lookup, {
            body,
            headers: e,
          }),
        ),
      ),
      take(1),
    );
  }

  getSearch(): Observable<string> {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.Search,
      {},
    );
  }

  setCognitoSync(): Observable<string> {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.CognitoSync,
      {},
    );
  }

  getDocx(queryStringParameters: {
    email: string;
    docLink: string;
  }): Observable<string> {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.Docx,
      { queryStringParameters },
    );
  }

  scrapeLinkedIn(body: { Email: any; LinkedInUrl: any }) {
    return this.wrapRequest(
      Constants.HttpMethods.Post,
      Constants.Endpoints.LinkedInRequest,
      { body },
    );
  }

  getScrapingStatus(queryStringParameters: { email: string }) {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.LinkedInUpdate,
      { queryStringParameters },
    );
  }

  getLinkedInConfiguration(): Observable<ILinkedInConfigResponse> {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.ConfigurationRequest,
      {},
    );
  }

  getProfilePicture(
    bambooId: string,
    pictureSize: string,
  ): Observable<IResponse> {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.ProfilePicture(bambooId, pictureSize),
      {},
    );
  }

  setLinkedInConfiguration(linkedInImportEnabled: boolean) {
    const body: { key: any; value: any } = {
      key: 'LinkedIn',
      value: linkedInImportEnabled ? 'Enabled' : 'Disabled',
    };
    return this.wrapRequest(
      Constants.HttpMethods.Post,
      Constants.Endpoints.ConfigurationUpdate,
      { body },
    );
  }

  submitToHR(): void {
    const validationResult = this.validateCv();

    if (!validationResult.isValid) {
      this.notificationService.open(
        Constants.Notifications.MissingMandatoryFieldsWarning,
      );
      this.router.navigate([validationResult.route], {
        queryParams: { 'highlight-missing-inputs': 'true' },
      });
      return;
    }

    const confirmData = ConfirmationService.simpleConfirmationDataFactory(
      Constants.ConfirmSendToHR.title,
      '',
    );
    const confirmed$ = this.confirmationService.confirm$(
      SubmitConfirmComponent,
      confirmData,
      '500px',
    );
    const body = this.cv$.getValue();
    confirmed$
      .pipe(
        filter((x) => x === true),
        switchMap((x) => {
          body.submittedDate = getTimestamp();
          return this.wrapRequest<ICandidateCv>(
            Constants.HttpMethods.Post,
            Constants.Endpoints.CandidateCv,
            { body },
          );
        }),
      )
      .subscribe((x) => {
        return this.cvSaved$.next(false);
      });
  }

  validateCv(): IValidationResult {
    const cv: Partial<IEmployeeCv> = this.cvState$.getValue();
    const validations: IValidationResult[] = [
      {
        isValid: validateTechnicalSkills(cv.technicalSkills),
        route: Constants.Routes.CvTechnicalSkills,
      },
      {
        isValid: validateWorkExperience(cv.workExperience),
        route: Constants.Routes.CvWorkExperience,
      },
      {
        isValid: validateEducation(cv.education),
        route: Constants.Routes.CvEducation,
      },
      {
        isValid: validateLanguages(cv.languageProficiencies),
        route: Constants.Routes.CvLanguages,
      },
      {
        isValid: validateCertifications(cv.certifications),
        route: Constants.Routes.CvCertification,
      },
    ];
    const firstInvalid: string | null =
      validations.find(({ isValid }) => !isValid)?.route || null;
    const result: IValidationResult = {
      isValid: firstInvalid ? false : true,
      route: firstInvalid,
    };

    return result;
  }

  registerCandidate = (
    body: {
      email: string;
      name: string;
      surname: string;
    },
    handleError: () => void,
  ): Observable<IRegisterCandidateResult> => {
    return this.wrapRequest<IRegisterCandidateResult>(
      Constants.HttpMethods.Post,
      Constants.Endpoints.Register,
      { body },
      handleError,
    );
  };

  registerExternalHr = (
    body: {
      email: string;
      name: string;
      surname: string;
    },
    handleError: () => void,
  ): Observable<IRegisterCandidateResult> => {
    return this.wrapRequest<IRegisterCandidateResult>(
      Constants.HttpMethods.Post,
      Constants.Endpoints.RegisterExternalHr,
      { body },
      handleError,
    );
  };

  convertExEmployeeToCandidate = (
    email: string,
    body: {
      email: string;
      firstName: string;
      lastName: string;
    },
  ): Observable<IConvertExEmployeeResult> => {
    return this.wrapRequest<IConvertExEmployeeResult>(
      Constants.HttpMethods.Put,
      Constants.Endpoints.ExEmployee(email),
      { body, response: true },
    );
  };

  mergeCandidates = (
    email: string,
    body: {
      email: string;
      firstName: string;
      lastName: string;
    },
  ): Observable<string> => {
    return this.wrapRequest<string>(
      Constants.HttpMethods.Put,
      Constants.Endpoints.CandidateMerge(email),
      { body },
    );
  };

  setPassword(body: {
    username: string;
    password: string;
    permanent: boolean;
  }): Observable<ISetPasswordResult> {
    return this.wrapRequest<ISetPasswordResult>(
      Constants.HttpMethods.Post,
      Constants.Endpoints.SavePassword,
      { body },
      null,
      false,
      false,
    );
  }

  forgotPassword(body: { username: string }): Observable<IResponse> {
    return this.wrapRequest<IResponse>(
      Constants.HttpMethods.Post,
      Constants.Endpoints.ForgotPassword,
      { body },
      null,
      false,
      false,
    );
  }

  getCandidateCv(queryStringParameters: { email: string }): Observable<any> {
    return this.wrapRequest<any>(
      Constants.HttpMethods.Get,
      Constants.Endpoints.CandidatePreview,
      { queryStringParameters },
      () => this.router.navigate([Constants.Routes.NotFound]),
    );
  }

  getCandidateData(body: {
    url: string;
  }): Observable<IGetCandidateDatadResult> {
    return this.wrapRequest<IGetCandidateDatadResult>(
      Constants.HttpMethods.Post,
      Constants.Endpoints.GetCandidateData + '?url=' + body.url,
      {},
      null,
      false,
      false,
    );
  }

  reindexAll() {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.Administrate,
      {
        queryStringParameters: {
          command: Constants.AdminCommands.ReindexAll.command,
        },
      },
    );
  }

  copyDataFromProd() {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.Administrate,
      {
        queryStringParameters: {
          command: Constants.AdminCommands.CopyDataFromProd.command,
        },
      },
    );
  }

  getRoles(): Observable<ChangeableRole[]> {
    return this.wrapRequest(
      Constants.HttpMethods.Get,
      Constants.Endpoints.Roles,
      {},
      // Since server responds with object that contains roles we need to map it to list
    ).pipe(
      map((response: { roles: ChangeableRole[] }) => {
        // remap role's display name
        // roles in cognito are different from LSS
        const rolesRemaped = response.roles.map((role) => {
          role.displayName =
            Constants.UserRolesDescription[role.roleId] || '--';
          role.roleChangeOptions.every(
            (item) =>
              (item.displayName =
                Constants.UserRolesDescription[item.roleId] || '--'),
          );
          return role;
        });

        return rolesRemaped;
      }),
    );
  }

  getLanguageOptions(): ILookupValue[] {
    const allCodes = (this.lookupData$.getValue().languages || []).filter(
      (lang) => lang.key,
    );
    allCodes.sort(function (a, b) {
      return a.displayName.localeCompare(b.displayName);
    });

    const headCodes = [];
    const topCodes = ['en', 'lv', 'ru'];

    allCodes.forEach((e, codeIndex) => {
      const topCodeIdnex = topCodes.findIndex((ee) => ee === e.key);

      if (topCodeIdnex !== -1) {
        topCodes.splice(topCodeIdnex, 1);
        headCodes.push(e);
        allCodes.splice(codeIndex, 1);

        return;
      }
    });

    return [...headCodes, ...allCodes];
  }
}
