import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Constants } from './constants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from './base/data.service';
import { VersionService } from './base/version.service';
import { ActivityService } from './base/activity.service';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import { filter, map, tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { AuthService } from './base/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { ConfirmationService } from '@lss/lss-ui';
import { AppUpdateService } from './base/app.update.service';

@Component({
  selector: 'lss-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  authenticatedUser$ = this.authService.authenticatedUser$;
  nextRoute: any;
  version$ = this.versionService.version$;
  qualificationFormToolbar: boolean;
  routeToForm = Constants.Routes.Form;
  routeToHome = Constants.Routes.Home;
  window = window;
  showTemplateHeader: boolean;

  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(DATA_SERVICE_TOKEN) private dataService: DataService,
    private versionService: VersionService,
    private globalService: GlobalService,
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private confirmationService: ConfirmationService,
  ) {
    this.qualificationFormToolbar = this.getQualificationFormToolbar();

    // Start user activity monintoring
    new ActivityService(
      this.router,
      this.authService,
      this.confirmationService,
    );

    // Start app update detection
    new AppUpdateService(
      this.authService,
      this.swUpdate,
      this.confirmationService,
    );
  }

  ngOnInit(): void {
    this.registerCustomIcons();

    if (this.qualificationFormToolbar === true) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    this.router.events
      .pipe(
        tap((e) => {
          if (e instanceof NavigationStart && !e.restoredState) {
            sessionStorage.setItem(Constants.StorageKeys.tokenExpired, 'false');
          }
        }),
        filter((event) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) =>
          this.globalService.updateNavLocation(event.url),
        ),
        map((e: NavigationEnd) => {
          this.showTemplateHeader = [
            Constants.Routes.NotFound,
            Constants.Routes.SetPasswordCandidate,
            Constants.Routes.TermsOfService,
            Constants.Routes.TipsAndTricks,
          ]
            .map((s) => '/' + s)
            .includes(e.urlAfterRedirects);
        }),
      )
      .subscribe();

    const globalServiceInstance = this.globalService;
    window.onfocus = function () {
      globalServiceInstance.isTabActive = true;
    };

    window.onblur = function () {
      globalServiceInstance.isTabActive = false;
    };

    window.addEventListener(
      'storage',
      (event) => {
        if (event.key === Constants.StorageKeys.loggedIn) {
          this.authService.signOut();
        }
      },
      false,
    );

    window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
      if (
        this.dataService.formState.getValue() &&
        (this.router.routerState.snapshot.url ===
          `/${Constants.Routes.RegisterCandidate}` ||
          this.router.routerState.snapshot.url ===
            `/${Constants.Routes.RegisterExternalHr}`)
      ) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  }

  applyLocallyStoredCv(): void {
    const localCvState = this.dataService.localCv$.getValue();

    if (!localCvState) {
      console.warn(Constants.Notifications.ApplyLocalCvError.body);
      this.dataService.notificationService.open(
        Constants.Notifications.ApplyLocalCvError,
      );

      return;
    }

    this.dataService.cv$.next(localCvState.cv);
    this.router.navigateByUrl(localCvState.routeUrl);
    this.dataService.removeLocalCv(localCvState.cv?.contactInfo.workEmail);
  }

  registerCustomIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'degree',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/degree-icon.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'user',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/user-icon.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'profile-picture',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/profile-icon.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'global',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/global-icon.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'certificate',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/certificate-icon.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'skill',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/skill-icon.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'coffee-cup',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/coffee-cup-icon.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'go-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/svg/go-up-icon.svg',
      ),
    );
  }

  getQualificationFormToolbar(): boolean {
    const actualPath = window.location.pathname;
    const qualificationFormPathName = '/' + Constants.Routes.Form;

    return actualPath === qualificationFormPathName;
  }
}
