import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ILookupValue } from '@lss/lss-types';
import { AutocompleteModule } from '../../form/autocomplete/autocomplete.module';
import { BehaviorSubject, Subject, tap, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'lss-cell-edit-autocomplete',
  templateUrl: './cell-edit-autocomplete.component.html',
  styleUrls: ['./cell-edit-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ReactiveFormsModule, AutocompleteModule],
})
export class CellEditAutocompleteComponent implements OnInit, OnDestroy {
  @Input() currentValue: unknown;
  @Input() options: ILookupValue[] = [];
  @Output() selected = new EventEmitter<ILookupValue>();
  autocompleteControl: FormControl = new FormControl(null, Validators.required);
  // form control, to handle remote operations
  @Input() control: FormControl;
  @Input() placeholder: string;
  selectedValue$ = new BehaviorSubject<ILookupValue>({
    displayName: '',
    key: '',
    group: '',
  });

  protected readonly destroyed$ = new Subject();

  ngOnInit() {
    // intial value set
    this.populateValue(this.currentValue);

    // monitor form control for value change
    // to populate autocompleteControl's value as Lookup model
    this.control.valueChanges
      .pipe(
        tap((value) => this.populateValue(value)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  selectValue(val: ILookupValue) {
    this.selectedValue$.next(val);
    this.selected.emit(val);
  }

  private populateValue(newValue) {
    const foundValue = this.options?.find((v) => v.key === newValue);
    if (foundValue) {
      const valueForAutocomplete = this.getAutocompleteFormatValue(
        foundValue.key,
        foundValue.displayName,
      );
      // Adding current value to selected values subject so it wouldn't
      // be displayed among other options
      this.selectedValue$.next(valueForAutocomplete);
      this.autocompleteControl.setValue(valueForAutocomplete, {
        emitEvent: false,
      });
    }
  }

  private getAutocompleteFormatValue(
    key: string,
    displayValue?: string,
  ): ILookupValue {
    return { displayName: displayValue ?? key, group: '', key };
  }
}
