import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../base/auth.service';
import { Constants } from '../constants';

@Injectable()
export class RegisterCandidateGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate = async (): Promise<boolean> => {
    try {
      const canRegister = await this.authService.userCanPerformAction(
        Constants.RoleActions.canRegisterCandidate
      );
      if (!canRegister) {
        this.router.navigate([Constants.Routes.Cv]);
      }
      return canRegister;
    } catch (e) {
      return false;
    }
  };
}
