import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { INotificationConfig } from '@lss/lss-types';

@Component({
  selector: 'lss-notification',
  styleUrls: ['./notification.component.scss'],
  template: `
    <div class="icon-container">
      <lss-icon *ngIf="data?.mode === 'Success'">check_circle</lss-icon>
      <lss-icon *ngIf="data?.mode === 'Error'">error</lss-icon>
      <lss-icon *ngIf="data?.mode === 'Warning'">info</lss-icon>
    </div>
    <div class="content-container">
      <h2>
        {{ data?.title }}
      </h2>
      <p>
        {{ data?.body }}
      </p>
    </div>
    <div class="closeNotify-container">
      <button mat-icon-button (click)="closeSnackBar()">
        <mat-icon class="closeNotify">close</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: INotificationConfig) {}

  closeSnackBar() {
    this.data.close();
  }
}
