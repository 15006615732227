<mat-form-field appearance="standard">
  <mat-label>{{ label }}</mat-label>

  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="formControl"
    [required]="required"
    [placeholder]="placeholder"
    [errorStateMatcher]="customMatcher || matcher"
  />

  <mat-error *ngIf="!hideError">{{ getErrorMessage() }}</mat-error>

  <mat-datepicker #picker></mat-datepicker>

  <button
    [disabled]="disabled"
    mat-button
    matSuffix
    mat-icon-button
    class="calendar-icon"
    (click)="picker.open()"
  >
    <img src="assets/svg/calendar-icon.svg" />
  </button>
</mat-form-field>
