<lss-autocomplete
  [formControl]="autocompleteControl"
  [required]="true"
  [options]="options"
  [filterFromAllOptions]="true"
  (selected)="selectValue($event)"
  [placeholder]="placeholder"
  [floatLabel]="'never'"
  [hideError]="true"
  [autoSetOnMatch]="true"
>
</lss-autocomplete>
