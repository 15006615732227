import { ComponentType } from '@angular/cdk/overlay';
import { Inject, Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import { AdminCommandModalComponent } from '../admin/admin-command-modal/admin-command-modal.component';
import { ImportLinkedinModalComponent } from '../cv/import-linkedin-modal/import-linkedin-modal.component';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    @Inject(DATA_SERVICE_TOKEN) public dataService: DataService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ImportLinkedinModalComponent>,
  ) {}

  openModal(): void {
    this.dialog.open(ImportLinkedinModalComponent, {
      data: {},
      width: '520px',
      disableClose: true,
    });
  }

  openAdminCommandModal(title: string, command: string): void {
    this.dialog.open(AdminCommandModalComponent, {
      data: { title, command },
      width: '520px',
      disableClose: true,
    });
  }

  sendData(cv) {
    this.dataService.cv$.next(cv);
  }

  openDialog(
    component: ComponentType<any>,
    config?: MatDialogConfig,
  ): MatDialogRef<any> {
    return this.dialog.open(component, config);
  }
}
