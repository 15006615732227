<div class="item-container">
  <lss-icon
    editable-item-add
    class="material-icons-outlined decorative-icon item-title-icon"
  >
    local_library
  </lss-icon>
  <lss-text
    #focusField
    [label]="'Course Name'"
    [formControl]="formGroup.controls.course"
    placeholder="Ex. Microsoft Azure"
    maxLength="360"
  ></lss-text>
  <lss-text
    [label]="'Institution'"
    [formControl]="formGroup.controls.institution"
    placeholder="Ex. Sunset Learning Institute"
    maxLength="256"
  ></lss-text>
  <lss-range-picker
    [formGroup]="formGroup.controls.period"
    [endDateOptional]="true"
    [allowPresent]="false"
    [futureYears]="20"
    [isCourse]="true"
  ></lss-range-picker>
  <lss-text
    [label]="'Notes'"
    [formControl]="formGroup.controls.notes"
  ></lss-text>
</div>
