import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../auth.service';
import { Constants } from '../../constants';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate = async (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    try {
      const isAuthenticated = await this.authService.isAuthenticated();

      if (!isAuthenticated) {
        return this.redirectToLogin(state);
      }

      return true;
    } catch (reason) {
      console.error(`Authentication error: ${reason}`);
      return this.redirectToLogin(state);
    }
  };

  private redirectToLogin = (state: RouterStateSnapshot) => {
    return this.router.createUrlTree([Constants.Routes.Login], {
      queryParams: { returnUrl: state.url },
    });
  };
}
