import { AnimationBuilder } from '@angular/animations';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IUpdateModel, IDataService } from '@lss/lss-types';
import { BaseComponent } from './base.component';
import { ConfirmationService } from './confirmation/confirmation.service';
import { DATA_SERVICE_TOKEN } from '@lss/lss-types';
import { ListCrudUtilService } from './list-crud.service';

@Component({ template: '' })
export abstract class BaseListCrudComponent<T, TItem> extends BaseComponent<T> {
  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: IDataService<T>,
    protected activatedRoute: ActivatedRoute,
    protected confirmationService: ConfirmationService,
    protected cd: ChangeDetectorRef,
    protected animationBuilder: AnimationBuilder,
    protected crudUtilService: ListCrudUtilService,
  ) {
    super(dataService, activatedRoute, confirmationService);
  }

  abstract form: FormArray | FormGroup;
  abstract newItem: (model?: TItem) => FormGroup;

  abstract onDataReady?(cv: T): void;

  abstract getUpdateModel?(): IUpdateModel<T>;

  insert(index: number, item = this.newItem(), controlName?: string): void {
    this.crudUtilService.insert(
      this.getArray(this.form, controlName) as FormArray,
      index,
      item,
    );
    this.cd.markForCheck();
  }

  insertAtEnd(item = this.newItem(), controlName?: string): void {
    this.crudUtilService.insertAtEnd(
      this.getArray(this.form, controlName),
      item,
    );
    this.cd.markForCheck();
  }

  insertAtStart(item = this.newItem(), controlName?: string): void {
    this.crudUtilService.insertAtStart(
      this.getArray(this.form, controlName),
      item,
    );
    this.cd.markForCheck();
  }

  append(controlName?: string): void {
    this.crudUtilService.insert(
      this.getArray(this.form, controlName),
      this.getArray(this.form, controlName).length,
      this.newItem(),
    );
    this.cd.markForCheck();
  }

  moveUp(element: any, currentIndex: number, controlName?: string): void {
    this.crudUtilService.moveUp(
      this.getArray(this.form, controlName),
      element,
      currentIndex,
    );
  }

  moveDown(element: any, currentIndex: number, controlName?: string): void {
    this.crudUtilService.moveDown(
      this.getArray(this.form, controlName),
      element,
      currentIndex,
    );
  }

  private move(
    element: any,
    currentIndex: number,
    newIndex: number,
    controlName?: string,
  ): void {
    this.crudUtilService.move(
      this.getArray(this.form, controlName),
      element,
      currentIndex,
      newIndex,
    );
    this.cd.markForCheck();
  }

  remove(formItems: FormArray, index: number, controlName?: string): FormGroup {
    const removed = this.crudUtilService.remove(formItems, index);

    this.cd.markForCheck();

    return removed;
  }

  private getArray(
    form: FormArray | FormGroup,
    controlName?: string,
  ): FormArray {
    if (form.controls.constructor === Array) {
      return this.form as FormArray;
    } else {
      return this.form.controls[controlName] as FormArray;
    }
  }
}
