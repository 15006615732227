import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ILookupValue, TableColumn } from '@lss/lss-types';

@Component({
  selector: 'lss-cell-filter',
  templateUrl: './cell-filter.component.html',
  styleUrls: ['./cell-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellFilterComponent {
  @Input() column: TableColumn<any>;
  @Input() control = new FormControl();

  updateSelected(val: ILookupValue) {
    this.control.setValue(val?.key);
  }
}
