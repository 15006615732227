import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import {
  LSS_OVERLAY_ID,
  LssOverlayContainer,
} from '../../../../lss/src/app/base/lss-overlay-container';
import { ButtonModule } from '../form/button/button.module';
import { IconModule } from '../form/icon/icon.module';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatToolbarModule,
    RouterModule,
    ButtonModule,
    MatMenuModule,
    IconModule,
    MatButtonModule,
  ],
  exports: [ToolbarComponent],
  providers: [
    { provide: LSS_OVERLAY_ID, useValue: 'lss-toolbar-custom-overlay' },
    { provide: OverlayContainer, useClass: LssOverlayContainer },
  ],
})
export class ToolbarModule {}
