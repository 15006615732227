import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, takeUntil, delay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UiConstants } from 'src/lss-ui/src/lib/ui-constants';
import { Constants } from '../../constants';
import { ProgressService } from '@lss/lss-ui';
import { AuthService } from '../auth.service';

@Component({
  selector: 'lss-page-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();
  buttonColor: any;
  qualification: boolean;
  routeToForm = Constants.Routes.Form;
  routeToCV = Constants.Routes.Cv;
  whichRoute = '';
  canSearch = false;

  xShift = 0;
  LAYER1_PARALLAX_SPEED = 5;
  LAYER2_PARALLAX_SPEED = 20;
  LAYER3_PARALLAX_SPEED = 10;
  width = 0;

  loading: boolean;

  get xShiftValue() {
    return this.xShift;
  }

  set xShiftValue(xShift) {
    this.xShift = xShift;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private progressService: ProgressService,
    private authService: AuthService
  ) {
    this.qualification = this.getQualification();
  }

  ngAfterViewInit(): void {
    this.progressService.loading$
      .pipe(
        delay(0),
        tap((e) => {
          this.loading = e;
          this.cd.markForCheck();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    const handleWindowResize = () => {
      this.xShift = 0;
    };

    const handleMouseMove = (ev: MouseEvent) => {
      this.xShiftValue = 0.5 - ev.pageX / this.width;
      this.cd.detectChanges();
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleWindowResize);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public goHome(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    if (this.canSearch) {
      this.router.navigate([Constants.Routes.Home]);
      return;
    }

    this.whichRoute = this.qualification ? this.routeToForm : this.routeToCV;
    this.router.navigate([this.whichRoute]);
  }

  ngOnInit(): void {
    this.buttonColor = UiConstants.Button.Color.BasicPrimary;
    this.width = window.innerWidth;
    this.authService.userCanPerformAction(Constants.RoleActions.canSearch).then((canSearch) => {
      this.canSearch = canSearch;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = window.innerWidth;
  }

  getQualification(): boolean {
    const actualPath = window.location.pathname;
    const qualificationPathName = '/' + Constants.Routes.Form;

    return actualPath === qualificationPathName;
  }
}
