<div class="content">
  <div class="logo">
    <img src="assets/svg/28stone-logo.svg" alt="28Stone" />
  </div>
  <mat-card *ngIf="form">
    <mat-card-title>{{ title }}</mat-card-title>
    <div *ngIf="exEmployeeConversion">
      To convert Ex-employee
      <a [routerLink]="['/preview/', exEmployeeEmail]" target="_blank">{{
        exEmployeeName
      }}</a>
      to a Candidate,<br />
      please enter the Email Address below
    </div>
    <br />

    <lss-form-card [form]="form" id="registerForm" autocomplete="off">
      <div>
        <div *ngIf="!!errorText" class="error-text">
          <mat-error>{{ errorText }}</mat-error>
        </div>

        <lss-text
          [formControl]="form.controls.name"
          label="First Name"
          hideError="true"
        ></lss-text>
        <lss-text
          [formControl]="form.controls.surname"
          label="Last Name"
          hideError="true"
        ></lss-text>

        <mat-error
          class="error-text"
          *ngIf="form.controls.email.hasError('email')"
        >
          Not a valid email
        </mat-error>
        <lss-text
          id="email"
          [formControl]="form.controls.email"
          label="Email Address"
          hideError="true"
        ></lss-text>

        <mat-error class="error-text bad-email" *ngIf="!!emailErrorText">
          {{ emailErrorText }}
        </mat-error>

        <div class="actions">
          <lss-button
            class="generate-link-button"
            [disabled]="isDisabled()"
            (action)="registerUser()"
          >
            {{
              exEmployeeConversion
                ? 'Convert and Generate Link'
                : 'Generate Link'
            }}
          </lss-button>
        </div>
      </div>
      <div>
        <lss-text
          [readonly]="true"
          [type]="'name'"
          [formControl]="form.controls.link"
          placeholder="Link"
          (click)="onUrlClick()"
          (contextmenu)="onRightClick()"
        ></lss-text>
        <div class="actions">
          <lss-button
            class="clear"
            [disabled]="!canClearForm"
            (action)="clearForm()"
            [color]="'secondary'"
          >
            Clear Form
          </lss-button>
          <lss-button
            [disabled]="!form.controls.link.value"
            (action)="copyInvitationLink()"
            value="click to copy"
            >Copy Link</lss-button
          >
        </div>
      </div>
    </lss-form-card>
  </mat-card>
</div>
