import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../base/auth.service';
import { Constants } from '../constants';

@Component({
  selector: 'lss-search-guard',
  template: ` <p>search-guard works!</p> `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchGuardComponent implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    try {
      const canSearch = await this.authService.userCanPerformAction(
        Constants.RoleActions.canSearch
      );
      if (!canSearch) {
        this.router.navigate([Constants.Routes.Login]);
      }
      return canSearch;
    } catch (error) {
      console.warn(`Search error: ${error}`);
      return false;
    }
  }
}
