import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormCardComponent } from './form-card.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from "../icon/icon.module";

@NgModule({
    declarations: [FormCardComponent],
    exports: [FormCardComponent, MatCardModule],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        ReactiveFormsModule,
        ButtonModule,
        MatIconModule,
        IconModule
    ]
})
export class FormCardModule {}
