import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  ChangeDetectionStrategy,
  forwardRef,
  OnInit,
  SimpleChanges,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
  Injector,
  AfterViewInit,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from '../base.control';
import { ILookupValue } from '@lss/lss-types';
import {
  Subject,
  distinctUntilChanged,
  takeUntil,
  fromEvent,
  debounceTime,
  tap,
} from 'rxjs';
import { getNewRandom } from 'src/lss/src/app/base/login/login-utils';
import { MatSelect } from '@angular/material/select';

interface IFiltrableLookup extends ILookupValue {
  hide: boolean;
}

@Component({
  selector: 'lss-select-autocomplete',
  templateUrl: './select.autocomplete.component.html',
  styleUrls: ['./select.autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAutocompleteComponent),
      multi: true,
    },
  ],
})
export class SelectAutocompleteComponent
  extends BaseControl
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  @Input() selectPlaceholder: string = 'Search...';
  @Input() placeholder: string;
  @Input() options: ILookupValue[];
  @Input() formControl: FormControl = new FormControl();
  @Input() multiple = true;
  @Input() hideError: boolean;

  @ViewChild('selectInput') selectInput: MatSelect;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  id: string = `select-autocomplete-${getNewRandom().replace('.', '')}`;
  placeHolderClass = 'values-size-detector';

  selectedValues: ILookupValue[] = [];
  truncatedValue = '';

  searchValue: string = '';
  filtrableOptions: IFiltrableLookup[] = [];
  destroyed$ = new Subject();

  constructor(
    protected elementRef: ElementRef,
    protected cd: ChangeDetectorRef,
    protected injector: Injector,
  ) {
    super(elementRef, cd, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        tap((values) => {
          this.selectedValues = values;
        }),
      )
      .subscribe(() => this.truncateValues());
  }

  ngAfterViewInit(): void {
    this.selectInput._handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'space') {
        return;
      }
    };

    // detect resizing to redefine values fitment
    fromEvent(window, 'resize')
      .pipe(debounceTime(100), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.truncateValues();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.filtrableOptions = (this.options || []).map((item) => ({
        ...item,
        hide: false,
      }));
    }

    if (this.disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onOpenedChange(opened) {
    if (opened) {
      this.searchInput.nativeElement.focus();
    } else {
      this.searchValue = '';
      this.hideOptions();
    }
  }

  hideOptions() {
    // we have to hide options not filter them out, otherwise mat-select-trigger won't display values properly
    this.filtrableOptions.forEach(
      (option) =>
        (option.hide =
          option.displayName
            .toLowerCase()
            .indexOf(this.searchValue.toLowerCase()) < 0),
    );
  }

  compareFn(value1: ILookupValue, value2: ILookupValue): boolean {
    return value1 && value2 ? value1.key === value2.key : value1 === value2;
  }

  originalOrder = () => {
    return 0;
  };

  trackByFn(_index, item) {
    return item.value;
  }

  truncateValues() {
    // check fitment for selection
    // using invisible spans
    // logic described in .html
    // skipping first one -> at least one value should be displayed anyway

    let itemsToDisplay = '';
    let counter = 0;

    setTimeout(() => {
      if (this.selectedValues?.length) {
        const ref = document.querySelector(
          `#${this.id} .mat-mdc-select-value`,
        ) as HTMLElement;
        const detectors = document.querySelectorAll(
          `#${this.id} .${this.placeHolderClass}`,
        );
        const maxWidth = ref.offsetWidth;

        detectors.forEach((detector, index) => {
          const currentWidth = detector.clientWidth;
          // skipping first one
          if (index === 0 || currentWidth < maxWidth - 20) {
            itemsToDisplay = detector.textContent;
          } else {
            counter++;
          }
        });

        this.truncatedValue =
          itemsToDisplay + (counter ? ' +' + counter.toString() : '');
        this.cd.detectChanges();
      }
    }, 0);
  }
}
