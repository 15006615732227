import { Pipe, PipeTransform } from '@angular/core';
import { ILookupValue } from '@lss/lss-types';

@Pipe({ name: 'itemsPortionPipe' })
export class ItemsPortionPipe implements PipeTransform {
  transform(count: number, selectedValues: ILookupValue[]): string {
    const parts = selectedValues.slice(0, ++count);
    return parts.map((item) => item.displayName).join(', ');
  }
}
