import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { NavigationCommand } from '@lss/lss-types';
import { BaseConfirmComponent } from '../base-confirm.component';

@Component({
  selector: 'lss-confirm-navigation',
  template: `
    <div class="input-block">
      <h1 class="title">{{ header }}</h1>
      <div class="body">{{ body }}</div>
    </div>
    <div class="modal-buttons">
      <lss-button (action)="decline()" [color]="'secondary'">
        <span>
          {{ declineButtonText }}
        </span>
      </lss-button>
      <lss-button (action)="accept()" [color]="'secondary'">
        <span>
          {{ confirmButtonText }}
        </span>
      </lss-button>
      <lss-button (action)="saveAndAccept()">
        <span>
          {{ saveAndConfirmButtonText }}
        </span>
      </lss-button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationConfirmComponent extends BaseConfirmComponent {
  saveAndConfirmButtonText: string;

  constructor(
    protected modalRef: MatDialogRef<BaseConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
  ) {
    super(modalRef, data);
  }

  saveAndAccept(): void {
    this.modalRef.close(NavigationCommand.SaveAndNavigate);
  }

  decline(): void {
    this.modalRef.close(NavigationCommand.StopNavigation);
  }

  accept(): void {
    this.modalRef.close(NavigationCommand.ResetAndNavigate);
  }
}
