import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

type GenericRetryStrategyProps = {
  maxRetryAttempts?: number;
  scalingDuration?: number;
};

export const GenericRetryStrategy =
  ({
    maxRetryAttempts = 120,
    scalingDuration = 3000,
  }: GenericRetryStrategyProps = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((a, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error

        if (a?.response?.status === 503) {
          window['globalRetryOverlay'] = true;
        }

        // If request failed due network error or invalid URL then returning original error
        if (!a?.response?.status) {
          return throwError(() => a);
        }

        if (
          retryAttempt > maxRetryAttempts ||
          (a.response.status !== 503 && !window['globalRetryOverlay'])
        ) {
          return throwError(a);
        }

        scalingDuration =
          a?.response?.headers['retry-after'] ?? scalingDuration;
        console.log(
          `Attempt ${retryAttempt}: retrying in ${
            retryAttempt * scalingDuration
          }ms`,
        );
        return timer(retryAttempt * scalingDuration);
      }),
    );
  };
