import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MoverComponent } from './mover.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [MoverComponent],
  imports: [CommonModule, MatIconModule, IconModule],
  exports: [MoverComponent],
})
export class MoverModule {}
