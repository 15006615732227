import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsyncRenderDirective } from './async-render.directive';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { HtmlSafePipe } from './html-safe.pipe';
import { AlphabeticalSortPipe } from './alphabetical-sort.pipe';

@NgModule({
  declarations: [
    AsyncRenderDirective,
    HtmlSafePipe,
    ScrollSpyDirective,
    AlphabeticalSortPipe,
  ],
  imports: [CommonModule],
  exports: [
    AsyncRenderDirective,
    HtmlSafePipe,
    ScrollSpyDirective,
    AlphabeticalSortPipe,
  ],
})
export class RenderingModule {}
