import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lss-chips',
  template: `
    <div *ngIf="multiple === true">
      <mat-chip-listbox>
        <mat-chip-option *ngFor="let item of itemList"></mat-chip-option>
      </mat-chip-listbox>
    </div>
    <!--// TODO: multiple option should be implemented in the future when there will be the need. -->
    <div>
      <mat-chip-option
        [selected]="selected"
        class="color-{{ color }} {{ class }}"
      >
        <span #content>
          <ng-content></ng-content>
        </span>
        <span *ngIf="type === 'badge'">
          <span *ngIf="label && additionalText">
            <b style="font-weight: 500;">{{ label }}:</b> {{ additionalText }}
          </span>
          <span *ngIf="label && !additionalText">
            <b style="font-weight: 500;">{{ label }}</b>
          </span>
          <span *ngIf="!label && !additionalText"> N/A </span>
        </span>
      </mat-chip-option>
    </div>
  `,
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent implements OnInit {
  @Input() isVisible?: boolean = true;
  @Input() label: string;
  @Input() additionalText? = '';
  @Input() icon?: string;
  @Input() removable?: boolean;
  @Input() selected?: boolean;
  @Input() color: 'gray' | 'orange' | 'white';
  @Input() type?: 'chips' | 'badge';
  @Input() class?: string;
  @Input() multiple = false;
  @Input() itemList: any[];

  constructor() {}

  ngOnInit(): void {}
}
