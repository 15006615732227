import { Environment } from './type';

// NOTICE those names like lss-api-$Stage are replaced with
// actual values in script/bb-step-build-and-deploy.sh
export const environment: Environment = {
  production: true,
  hmr: false,
  google: {
    clientId: '  266144887594-ggu02n8udgrpsuk6qpk33raodo4u5gso.apps.googleusercontent.com',
  },
  apiName: 'lss-api-$Stage',
  endpoint: 'https://acg1msxvb3.execute-api.eu-west-1.amazonaws.com/qa',
  publicEndpoint: 'https://4pd5g308n7.execute-api.eu-west-1.amazonaws.com/qa',
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_4MgHcc68U',
  userPoolClientId: '3kih4bankufg22u3m2dt22ultj',
  oauthDomain: 'lss-cg-user-pool-domain-qa.auth.eu-west-1.amazoncognito.com',
  oauthRedirectSignIn: 'https://lss-qa.28stone.cloud',
  oauthRedirectSignOut: 'https://lss-qa.28stone.cloud',
  features: {
    ADMIN_PANEL: true,
  },
};
