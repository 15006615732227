<div class="technical-skills">
  <div class="technical-skills-modal-window-header">
    <h2>Select Skills</h2>
    <div class="search">
      <input
        type="text"
        placeholder="Search skills"
        class="searchBox"
        (input)="handleInput($event)"
      />
      <lss-icon class="searchIcon">search</lss-icon>
    </div>
  </div>

  <div class="technical-skills-modal-window-container">
    <div
      class="empty-skill-search"
      *ngIf="(filteredSkillGroups$ | async).length === 0"
    >
      <lss-icon class="searchIcon">search</lss-icon>
      <h2>No Results Found</h2>
      <div class="add-new-skill">
        <p>
          ADD NEW SKILL
          <lss-icon
            class="add-skill-icon"
            (click)="handleOpenNewFieldClick('other')"
            *ngIf="!skillGroupsForShow['other']"
          >
            add
          </lss-icon>
          <lss-icon
            class="add-skill-icon"
            (click)="handleOpenNewFieldClick('other')"
            *ngIf="skillGroupsForShow['other']"
          >
            remove
          </lss-icon>
        </p>
        <ng-container *ngIf="skillGroupsForShow['other']">
          <div class="add-new-skill-field">
            <input
              placeholder="Add New Skill"
              class="input-new-skill-field"
              (input)="handleInputSkillName($event)"
              [class]="{'skill-exists': isErrorIconVisible()}"
              (keyup.enter)="handleAddNewSkillClick('other')"
            />
            <lss-icon
              [tooltip]="getValidationMessage()"
              *ngIf="isErrorIconVisible()"
            >
              priority_high
            </lss-icon>
            <lss-button
              class="add-skill-button"
              (click)="handleAddNewSkillClick('other')"
              [disabled]="isNewSkillNameInvalid()"
            >
              Add
            </lss-button>
          </div>
        </ng-container>
      </div>
    </div>

    <div
      class="skill-groups"
      *ngFor="let group of filteredSkillGroups$ | async, let i = index"
    >
      <div class="skill-group-title">
        <h4>{{group.groupName}}</h4>
        <lss-icon
          class="add-skill-icon"
          (click)="handleOpenNewFieldClick(group.groupName)"
          *ngIf="!skillGroupsForShow[group.groupName]"
        >
          add
        </lss-icon>
        <lss-icon
          class="add-skill-icon"
          (click)="handleOpenNewFieldClick(group.groupName)"
          *ngIf="skillGroupsForShow[group.groupName]"
        >
          remove
        </lss-icon>
        <ng-container *ngIf="skillGroupsForShow[group.groupName]">
          <div class="add-new-skill-field">
            <input
              placeholder="Add New Skill"
              class="input-new-skill-field"
              (input)="handleInputSkillName($event)"
              [class]="{'skill-exists': doesSkillAlreadyExists()}"
              (keyup.enter)="handleAddNewSkillClick(group.groupName)"
            />
            <lss-icon
              [tooltip]="getValidationMessage()"
              *ngIf="isErrorIconVisible()"
            >
              error
            </lss-icon>
            <lss-button
              class="add-skill-button"
              (click)="handleAddNewSkillClick(group.groupName)"
              [disabled]="isNewSkillNameInvalid()"
            >
              Add
            </lss-button>
          </div>
        </ng-container>
      </div>
      <div class="skill-group-skill" id="skillGroupChips">
        <div
          class="chips"
          *ngFor="let skill of group.options | alphabeticalSort"
        >
          <lss-chips
            [color]="'gray'"
            (click)="handleToggle($event, skill)"
            [selected]="isChecked(skill)"
          >
            {{skill.displayName}}
          </lss-chips>
        </div>
      </div>
    </div>
  </div>

  <div class="technical-skills-modal-window-footer">
    <div class="technical-skills-modal-window-footer-buttons">
      <lss-button
        id="closeBtn"
        class="close-button"
        (action)="handleDialogCloseClick()"
        [color]="'secondary'"
      >
        Cancel
      </lss-button>
      <lss-button
        id="submitBtn"
        class="submit-button"
        (action)="handleDialogDoneClick()"
        [disabled]="isValidForm()"
      >
        Done
      </lss-button>
    </div>
  </div>
</div>
