import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  DATA_SERVICE_TOKEN,
  IAdditionalInfo,
  ICvCertificationItem,
  ICvCourseItem,
  IEmployeeCv,
  IUpdateModel,
  TextAreaMode,
} from '@lss/lss-types';
import { DataService } from '../../base/data.service';
import { AnimationBuilder } from '@angular/animations';
import {
  BaseListCrudComponent,
  ConfirmationService,
  creatingEditableItemDecorator,
  getPeriodFormGroupWithAdditionalValidator,
  getTimestamp,
  ListCrudUtilService,
} from '@lss/lss-ui';
import { Constants } from '../../constants';
import { GlobalService } from '../../global.service';
import { AuthService } from '../../base/auth.service';
import { trimRichEditorValue } from '../../utils';

@Component({
  selector: 'lss-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificationComponent
  extends BaseListCrudComponent<IEmployeeCv, ICvCertificationItem>
  implements OnInit
{
  candidate = Constants.UserRoles.candidate;
  newItem;
  focusedIndex = undefined;

  constructor(
    @Inject(DATA_SERVICE_TOKEN) protected dataService: DataService,
    protected activatedRoute: ActivatedRoute,
    protected confirmationService: ConfirmationService,
    protected cd: ChangeDetectorRef,
    protected animationBuilder: AnimationBuilder,
    protected crudUtilService: ListCrudUtilService,
    protected globalService: GlobalService,
    protected authService: AuthService,
  ) {
    super(
      dataService,
      activatedRoute,
      confirmationService,
      cd,
      animationBuilder,
      crudUtilService,
    );
  }

  TextAreaMode = TextAreaMode;
  form: FormGroup;
  additionalInfo: FormGroup;
  initAdditionalInfoValue: IAdditionalInfo;
  certificates = Constants.Words.certificates;
  courses = Constants.Words.courses;

  pending = false;

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
  }

  onDataReady = function (cv: IEmployeeCv): any {
    const certification = cv?.certifications || [];
    const course = cv?.courses || [];
    this.form = new FormGroup({
      certificates: new FormArray(
        certification.map((e) => newCertificationItemControl(e)),
      ),
      courses: new FormArray(course.map((e) => newCourseItemControl(e))),
    });
    this.initAdditionalInfo(cv);

    this.additionalInfo?.valueChanges.subscribe((value) => {
      const isSameValue =
        this.initAdditionalInfoValue?.hobbies == value.hobbies &&
        this.initAdditionalInfoValue?.additionalComments ==
          value.additionalComments;

      isSameValue ? this.form.markAsPristine() : this.form.markAsDirty();
      this.cd.markForCheck();
    });

    if (
      !this.pending &&
      this.activatedRoute.snapshot.queryParams['highlight-missing-inputs']
    ) {
      this.pending = true;
      window.setTimeout(async () => {
        this.globalService.checkAndFocusInvalidFields(this.form);
        this.pending = false;
      }, 500);
    }
  }.bind(this);

  initAdditionalInfo(cv: IEmployeeCv): void {
    this.initAdditionalInfoValue = cv?.additionalInfo;
    this.additionalInfo = new FormGroup({
      hobbies: new FormControl(this.initAdditionalInfoValue?.hobbies),
      additionalComments: new FormControl(
        this.initAdditionalInfoValue?.additionalComments,
      ),
    });
  }

  getUpdateModel(): IUpdateModel<IEmployeeCv> {
    return getCertificationUpdateModel(
      this.form.controls[this.certificates] as FormArray,
      this.additionalInfo,
      this.form.controls[this.courses] as FormArray,
    );
  }

  submitToHR(): void {
    this.submit(true).subscribe(() => this.dataService.submitToHR());
  }

  addItem(itemType: string): void {
    this.newItem =
      itemType === this.certificates
        ? newCertificationItemControl.bind(this)
        : newCourseItemControl.bind(this);
    this.insertAtStart(this.newItem(), itemType);
    this.focusedIndex = 0;
  }
}

export const getCertificationUpdateModel = (
  certificates: FormArray,
  additionalInfo: FormGroup,
  courses?: FormArray,
): IUpdateModel<IEmployeeCv> => {
  let courseItems = [];
  //This function is used in LinkedIn import and there we don't pass 'courses' value, so we need to check if 'courses' has any value
  if (courses) {
    const courseControls = courses.controls;
    courseItems = courseControls.map((e) => {
      const { course, institution, period, notes } = e.value;
      return {
        course,
        institution,
        startDate: period?.startDate,
        endTimestamp: period?.endTimestamp,
        notes,
      } as ICvCourseItem;
    });
  }
  const certificateControls = certificates.controls;
  const certificateItems = certificateControls.map((e) => {
    const { certificate, institution, period, notes } = e.value;
    return {
      certificate,
      institution,
      validFrom: period?.startDate,
      validTo: period?.endTimestamp,
      notes,
    } as ICvCertificationItem;
  });

  let additionalItemsTrimmed = null;
  try {
    const additionalItems = additionalInfo?.getRawValue() ?? null;
    additionalItemsTrimmed = {
      additionalComments: trimRichEditorValue(
        additionalItems?.additionalComments ?? '',
      ),
      hobbies: trimRichEditorValue(additionalItems?.hobbies ?? ''),
    };
  } catch (e) {}
  return {
    cv: {
      certifications: certificateItems,
      additionalInfo: additionalItemsTrimmed,
      courses: courseItems,
    },
  } as IUpdateModel<IEmployeeCv>;
};

export const newCertificationItemControl = (
  certificationItem: ICvCertificationItem = null,
): FormGroup => {
  const { certificate, institution, validFrom, validTo, notes } =
    certificationItem || {};
  const todayYear = new Date().getFullYear();
  const todayMonth = new Date().getMonth();
  const maxStarDate = getTimestamp(new Date(todayYear, todayMonth, 1));
  const startDateValidators = [
    Validators.required,
    Validators.max(maxStarDate),
  ];

  const formGroup = new FormGroup({
    certificate: new FormControl(certificate, [Validators.required]),
    institution: new FormControl(institution ?? ''),
    period: getPeriodFormGroupWithAdditionalValidator(
      validFrom,
      validTo,
      false,
      false,
      startDateValidators,
      [],
      [Validators.required],
    ),
    notes: new FormControl(notes ?? ''),
  });

  creatingEditableItemDecorator(formGroup, certificationItem);
  return formGroup;
};

export const newCourseItemControl = (
  courseItem: ICvCourseItem = null,
): FormGroup => {
  const { course, institution, startDate, endTimestamp, notes } =
    courseItem || {};
  const todayYear = new Date().getFullYear();
  const todayMonth = new Date().getMonth();
  const maxStarDate = getTimestamp(new Date(todayYear, todayMonth, 1));
  const startDateValidators = [
    Validators.required,
    Validators.max(maxStarDate),
  ];

  const formGroup = new FormGroup({
    course: new FormControl(course, [Validators.required]),
    institution: new FormControl(institution ?? ''),
    period: getPeriodFormGroupWithAdditionalValidator(
      startDate,
      endTimestamp,
      false,
      false,
      startDateValidators,
      [],
      [Validators.required],
    ),
    notes: new FormControl(notes ?? ''),
  });

  creatingEditableItemDecorator(formGroup, courseItem);
  return formGroup;
};
