import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleConfirmComponent } from './simple-confirm/simple-confirm.component';
import { NavigationConfirmComponent } from './navigation-confirm/navigation-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../button/button.module';
import { ConfirmationService } from './confirmation.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SubmitConfirmComponent } from './submit-confirm/submit-confirm.component';
import { StayConfirmComponent } from './stay-confirm/stay-confirm.component';

@NgModule({
  declarations: [
    SimpleConfirmComponent,
    NavigationConfirmComponent,
    SubmitConfirmComponent,
    StayConfirmComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    OverlayModule,
    MatDialogModule,
  ],
  providers: [ConfirmationService],
  exports: [SimpleConfirmComponent, NavigationConfirmComponent],
})
export class ConfirmationModule {}
