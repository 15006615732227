import { BehaviorSubject, Observable } from 'rxjs';
import {
  IInitialDataQuery,
  ILookupData,
  ILookupSkillRequestData,
} from '@lss/lss-types';
import { InjectionToken } from '@angular/core';

export const DATA_SERVICE_TOKEN = new InjectionToken<string>('DataService');

export interface IDataService<T> {
  lookupData$: BehaviorSubject<ILookupData>;
  cv$: BehaviorSubject<T>;

  save(body: Partial<T>): Observable<T>;

  saveLookups(body: {
    Items: { [key: string]: ILookupSkillRequestData[] };
    FirstName: string;
    LastName: string;
    Email: string;
  }): Observable<any>;

  get(email: string): Observable<IInitialDataQuery<T>>;
}
