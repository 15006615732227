import { Injectable, Injector } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ErrorService } from './error.service';

@Injectable()
export class ErrorGuard implements CanActivate {
  constructor(private injector: Injector) {}

  canActivate(): boolean | Promise<boolean> {
    const ee = this.injector.get(ErrorService);

    return !!ee?.error$.getValue();
  }
}
