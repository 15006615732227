<div *ngIf="currentStatus === RequestStatus.default">
  <div class="input-block">
    <h1>{{ header }}</h1>
  </div>
</div>

<div
  *ngIf="currentStatus === RequestStatus.successful"
  class="success-container"
>
  <lss-icon mat-icon class="success">done</lss-icon>
  <div class="input-block">
    <h1>Execution successful!</h1>
  </div>
</div>

<div *ngIf="currentStatus === RequestStatus.failed">
  <div class="input-block">
    <h1>Execution failed</h1>
  </div>
</div>

<div *ngIf="currentStatus === RequestStatus.pending" class="spinner-container">
  <mat-spinner></mat-spinner>
</div>

<div class="modal-buttons">
  <lss-button [color]="'secondary'" (action)="closeDialog()">
    <span>{{ cancelButtonText }}</span>
  </lss-button>
  <lss-button
    *ngIf="currentStatus === RequestStatus.default"
    (action)="executeCommand()"
  >
    <span>Confirm</span>
  </lss-button>
</div>
