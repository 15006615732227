import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'lss-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
})
export class BackToTopComponent implements OnInit {
  SHOW_SCROLL_TO_TOP_OFFSET = 20;
  showScrollToTop = false;

  backToTop(): void {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  @HostListener('document:scroll')
  onScroll(): void {
    this.showScrollToTop = window.scrollY > this.SHOW_SCROLL_TO_TOP_OFFSET;
  }

  ngOnInit(): void {
    this.showScrollToTop = window.scrollY > this.SHOW_SCROLL_TO_TOP_OFFSET;
  }
}
