import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ISimpleConfirmData } from '@lss/lss-types';
import { BaseConfirmComponent } from '../base-confirm.component';

@Component({
  selector: 'lss-submit-confirm',
  templateUrl: './submit-confirm.component.html',
  styleUrls: ['./submit-confirm.component.scss'],
})
export class SubmitConfirmComponent
  extends BaseConfirmComponent
  implements OnInit {
  constructor(
    protected modalRef: MatDialogRef<SubmitConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ISimpleConfirmData
  ) {
    super(modalRef, data);
  }

  ngOnInit(): void {
    this.confirmButtonText = this.confirmButtonText ?? 'Confirm';
    this.declineButtonText = this.declineButtonText ?? 'Cancel';
  }
}
