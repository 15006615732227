import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  imports: [OverlayModule, MatTooltipModule],
  exports: [TooltipComponent, TooltipDirective],
})
export class TooltipModule {}
