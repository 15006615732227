<div class="input-block">
  <h1>{{ header }}</h1>
  <h3 [innerHTML]="(body | async) || body"></h3>
</div>
<div class="modal-buttons">
  <lss-button [color]="'secondary'" (action)="decline()">
    <span>{{ declineButtonText }}</span>
  </lss-button>
  <lss-button (action)="accept()">
    <span>{{ confirmButtonText }}</span>
  </lss-button>
</div>
